import React, {useEffect} from 'react';
import {localStorageService} from '../../../services/localStorageService';
import {OrganizationAccountsCacheData, WebViewCallBacks} from '../../../types/sta';
import {ORGANIZATION_ACCOUNTS_DATA} from '../../../constants/storageKeys';
import {callNative} from '../../../nativeBridge';

const fetchAndNotifyMobileClients = () => {
  const cachedData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  if (!cachedData) {
    console.error('No cached data found.');
    return;
  }

  if (cachedData.savedOrganizations?.length === 1) {
    cachedData.selectedAccountUserId = cachedData.savedOrganizations[0].user.id;
  }

  callNative(WebViewCallBacks.ACCOUNTS_FETCHED, cachedData);
};

export const MobileRedirect: React.FC = () => {
  useEffect(() => {
    fetchAndNotifyMobileClients();
  }, []);

  return null;
};
