import React from 'react';
import ReactDOM from 'react-dom';
import {asyncWithLDProvider} from 'launchdarkly-react-client-sdk';
import App from './App';

const launchDarklyClientId = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SECRET;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    options: {
      logger: {
        debug: (msg) => {},
        info: (msg) => {},
        warn: (msg) => {},
        error: () => {},
      },
    },
    clientSideID: launchDarklyClientId!,
    user: {
      key: '123-456-789-101112',
      firstName: 'Anonymous',
      lastName: 'User',
    },
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();
