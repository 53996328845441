import {useLDClient, withLDProvider, useFlags} from 'launchdarkly-react-client-sdk';
import getParsedAuthInfo, {getParsedCurrentOrganization} from 'src/utils/localStorageHandler';
import {getParsedAuthRegion} from 'src/utils/localStorageHandler';
import {LocalStorageKey, localStorageService} from '../services/localStorageService';
import {FeatureFlagResult} from './FeatureFlags';

/**
 * Function to register logged-in user to Launch Darkly
 * it replaces anonymous user with logged-in user
 */
export const IdentifyUser = () => {
  const client = useLDClient();
  const authInfo = getParsedAuthInfo();
  const orgInfo = getParsedCurrentOrganization();
  const user = {
    key: authInfo?.user?.id,
    firstName: authInfo?.user?.firstname as string,
    lastName: authInfo?.user?.lastname as string,
    custom: {
      organizationId: orgInfo?.organization?.organizationId!,
      region: getParsedAuthRegion(),
    },
  };
  client?.identify(user);
};

/**
 * Fetching Feature flag
 * @param flagName
 */
export function IsFeatureFlagEnabled(flagName) {
  const flags = useFlags();
  if (getLocalFeatureFlagOverride(flagName)) {
    return true;
  }

  if (Object.keys(flags).length === 0) {
    return undefined;
  }
  return flags[flagName] === true;
}

const getLocalFeatureFlagOverride = (flagName: LocalStorageKey) => {
  return localStorageService.getItem<string>(flagName);
};
