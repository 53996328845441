import React from 'react';
import styled from '@emotion/styled';
import AddressAutoComplete from 'src/components/AddressAutoComplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useQuery} from '@apollo/react-hooks';

import InviteCodeCopySection from 'src/components/InviteCodeCopySection';
import InviteColleagueModalContent from './views/InviteColleagueContent';
import {QueryResult} from 'react-apollo';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import GetInviteCodeQuery from 'src/gql/query/GetInviteCodeQuery';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import AddressAutoCompleteSTA from '../AddressAutoCompleteSTA';
import {IInviteResponse} from 'src/gql/v2/mutation/InviteUsersMutation';
import {UserAddress} from 'src/types';
import {isSTAFeatureFlagEnabled} from '../../utils/sta/staUtils';

const StyledTabs = styled(Tabs)`
  button {
    display: inline-block;
    letter-spacing: unset;
    font-size: 16px;
    text-transform: none;
    border-bottom: 1px solid lightgrey !important;
  }
  .Mui-selected {
    font-weight: bold;
  }
`;

interface Props {
  closeModal?: () => void;
  handleOnComplete?: () => void;
  setInviteResponse?: React.Dispatch<React.SetStateAction<IInviteResponse[]>>;
  setAddressesInput?: React.Dispatch<React.SetStateAction<UserAddress[]>>;
}

const InvitationTabView = ({closeModal, handleOnComplete, setInviteResponse, setAddressesInput}: Props) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const coSigningInvitesFF = IsFeatureFlagEnabled('coSigningInvites');
  const STAFlag = isSTAFeatureFlagEnabled();

  const {data}: QueryResult = useQuery(GetInviteCodeQuery);

  const hasInviteCode = Boolean(data?.me?.inviteCode);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <React.Fragment>
      {!coSigningInvitesFF && (
        <StyledTabs
          variant="fullWidth"
          value={currentTab}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="invite modal tabs"
        >
          <Tab disableRipple label="Invite by email or phone number" />
          {hasInviteCode && <Tab disableRipple label="Invite by organization code" />}
        </StyledTabs>
      )}

      <InviteColleagueModalContent currentTab={currentTab} />

      {STAFlag && currentTab === 0 ? (
        <AddressAutoCompleteSTA
          closeModal={closeModal}
          onComplete={handleOnComplete}
          setInviteResponse={setInviteResponse}
          setAddressesInput={setAddressesInput}
        />
      ) : (
        currentTab === 0 && <AddressAutoComplete closeModal={closeModal} onComplete={handleOnComplete} />
      )}
      {currentTab === 1 && hasInviteCode && <InviteCodeCopySection />}
    </React.Fragment>
  );
};

export default InvitationTabView;
