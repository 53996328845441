import React from 'react';
import GlobalEscalationBanner from './GlobalEscalationBanner';
import OrganizationInviteBanner from './OrganizationInviteBanner';
import {AddressBannerCoordinator} from './AddressBanner/AddressBannerCoordinator';
import {IsFeatureFlagEnabled} from '../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../utils/FeatureFlags';
import AnnouncementBanner from 'src/microfrontend/components/AnnouncementBanner';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {isSTAFeatureFlagEnabled} from '../../utils/sta/staUtils';

const PageBanner = () => {
  const missingAddressBannerFlag = IsFeatureFlagEnabled(FeatureFlagResult.missingAddressBanner);
  const {announcementBanner} = useFlags();

  const STAFlag = isSTAFeatureFlagEnabled();
  return (
    <React.Fragment>
      {Boolean(Object.entries(announcementBanner || {}).length) && <AnnouncementBanner />}
      {missingAddressBannerFlag && <AddressBannerCoordinator />}
      {STAFlag && <OrganizationInviteBanner />}
      <GlobalEscalationBanner />
    </React.Fragment>
  );
};

export default PageBanner;
