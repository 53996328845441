import {HCFilterSearchable} from 'src/@hc-ui/components/filters/variants/HCFilterSearchable';
import Popup from 'reactjs-popup';
import React, {useState} from 'react';
import ReceipientIcon from 'src/svgs/ReceipientIcon';
import {NumberBadge} from 'src/pages/AuditLogs/table/filters/TableFilters';
import ChevronUpIcon from 'src/svgs/ChevronUpIcon';
import ChevronDownIcon from 'src/svgs/ChevronDownIcon';
import styled from '@emotion/styled';
import {HCFilterSimple} from 'src/@hc-ui/components/filters/variants/HCFilterSimple';
import {CLEAR_ALL, FILTER_BY_SITES, SITES} from 'src/constants/strings';
import Button from '@mui/material/Button';
import {MuiSpan} from 'src/pages/AuditLogs/table/EmptyTable';
import {SiteScope} from 'src/types';
import {HCFilterOption} from 'src/@hc-ui/components/filters/variants/HCFilter';
import {MinimalSiteData} from 'src/gql/query/FetchSitesForOrganization';

export const TableFilterContainer = styled.button<{hasSelection?: boolean; isOpen?: boolean}>`
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  background: ${({hasSelection, isOpen}) => (hasSelection && !isOpen ? '#E3F2F4' : isOpen ? '#CCE7EB' : '')};
  border: ${({isOpen, hasSelection}) =>
    isOpen && !hasSelection ? '1px solid #00859A' : hasSelection ? '' : '1px solid #d8d8d8'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Nunito Sans', serif;

  :hover {
    border: ${({isOpen, hasSelection}) => !isOpen && !hasSelection && '1px solid black'};
    background: ${({hasSelection}) => hasSelection && '#CCE7EB'};
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Nunito Sans';
  flex-wrap: wrap;
  row-gap: 10px;
`;

const Container = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const FilterActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const StyledTextButton = styled(Button)`
  color: #00859a;
`;

export type FilterOption = {
  id: number;
  label: string;
  value: MinimalSiteData;
};

type SitesFilterProps = {
  selectedSitesIds: number[];
  setSelectedSitesIds: (selectedSitesIds) => void;
  siteOptions: FilterOption[];
  setLocalSearchText?: (string) => void;
  setIsDoneRows?: (boolean) => void;
};

export const SitesFilter = ({
  selectedSitesIds,
  setSelectedSitesIds,
  siteOptions,
  setLocalSearchText,
  setIsDoneRows,
}: SitesFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const SitesFilterInternal = React.forwardRef(({...props}, ref) => (
    <Container>
      <FiltersContainer>
        <TableFilterContainer ref={ref as any} {...props} hasSelection={selectedSitesIds.length > 0} isOpen={isOpen}>
          {SITES} {selectedSitesIds.length > 0 && <NumberBadge>{selectedSitesIds.length}</NumberBadge>}
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </TableFilterContainer>
      </FiltersContainer>
      <FilterActionsContainer>
        <StyledTextButton
          color="inherit"
          disabled={!selectedSitesIds.length}
          variant="text"
          onClick={() => {
            setSelectedSitesIds([]);
            setLocalSearchText && setLocalSearchText('');
            setIsDoneRows && setIsDoneRows(false);
          }}
          style={{
            textTransform: 'none',
            color: selectedSitesIds.length > 0 ? '#00859A' : undefined,
          }}
          disableTouchRipple
          disableRipple
        >
          <MuiSpan>
            {CLEAR_ALL}({selectedSitesIds.length})
          </MuiSpan>
        </StyledTextButton>
      </FilterActionsContainer>
    </Container>
  ));

  return (
    <Popup
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      trigger={() => <SitesFilterInternal />}
      position="bottom left"
      closeOnDocumentClick
    >
      {(close) => (
        <HCFilterSimple
          title={FILTER_BY_SITES}
          onClose={close}
          options={[
            {
              title: FILTER_BY_SITES,
              options: siteOptions.map((o) => ({...o, id: String(o.id)})),
            },
          ]}
          onToggle={(option: HCFilterOption) => {
            setIsDoneRows && setIsDoneRows(false);
            const found = selectedSitesIds.find((selectedId) => selectedId === Number(option.id));
            if (found) {
              setSelectedSitesIds([...selectedSitesIds.filter((selectedId) => selectedId !== Number(option.id))]);
            } else {
              setSelectedSitesIds([...selectedSitesIds, Number(option.id)]);
            }
          }}
          optionRender={(option) => {
            return (
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{paddingBottom: 4}}>{option.label}</div>
              </div>
            );
          }}
          onClear={() => {
            setIsDoneRows && setIsDoneRows(false);
            setSelectedSitesIds([]);
            close();
          }}
          selectedIds={selectedSitesIds.map((id) => String(id))}
        />
      )}
    </Popup>
  );
};
