import gql from 'graphql-tag';
import {FullOrganizationMember} from '../../../types';

export const PublicUserFragment = gql`
  fragment PublicUserFragment on PublicUser {
    id
    firstName
    lastName
    username
    memberStatus
  }
`;
export const AddressFragment = gql`
  fragment AddressFragment on Address {
    address
    type
    access
    label @include(if: $isProfileAddressLabelFlagEnabled)
    id
  }
`;

const UserProfileResultFragment = gql`
  fragment UserProfileResultFragment on UserProfileResult {
    ... on UserNotFoundError {
      __typename
      message
    }
    ... on UserNotMemberOfOrganization {
      __typename
      message
    }
    ... on GeneralUser {
      firstName
      lastName
      username
      __typename
    }

    ... on Node {
      id
    }

    ... on OrganizationMember {
      firstName
      lastName
      username
      role
      id
      workStatus {
        value
        alternateContact {
          memberStatus
          id
          firstName
          lastName
          username
        }
        message
      }
      memberStatus
      invite @include(if: $isSTAFlagEnabled) {
        status
      }
      avatar {
        url
      }
      role
      statusDescription
      statusExpiryDate
      workStatusProxyId {
        ...PublicUserFragment
      }
      addresses {
        ...AddressFragment
      }
      __typename
    }

    ... on FullOrganizationMember {
      workStatus {
        value
        alternateContact {
          id
          memberStatus
          firstName
          lastName
          username
        }
        message
      }
      memberStatus
      invite @include(if: $isSTAFlagEnabled) {
        status
      }
      avatar {
        url
      }
      role
      statusDescription
      statusExpiryDate
      workStatusProxyId {
        ...PublicUserFragment
      }
      addresses {
        ...AddressFragment
      }
      __typename
    }
  }
  ${PublicUserFragment}
  ${AddressFragment}
`;

export type FetchUserProfileQueryResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      member: FullOrganizationMember;
    };
  };
};

export type FetchUserAddressQueryResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      me: Pick<FullOrganizationMember, 'addresses'>;
    };
  };
};

export const FETCH_USER_PROFILE_QUERY = gql`
  query FetchUserProfile(
    $organizationalUnit: OrganizationalUnitInput!
    $userId: ID!
    $isProfileAddressLabelFlagEnabled: Boolean!
    $isSTAFlagEnabled: Boolean!
  ) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on Error {
          __typename
          message
        }
        ... on Organization {
          member(id: $userId) {
            ...UserProfileResultFragment
            __typename
          }
        }
      }
    }
  }
  ${UserProfileResultFragment}
`;

export const FETCH_USER_ADDRESSES_QUERY = gql`
  query FetchUserAddresses($organizationalUnit: OrganizationalUnitInput!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          me {
            addresses {
              ...AddressFragment
            }
          }
        }
      }
    }
  }

  fragment AddressFragment on Address {
    id
    address
    verified
    access
    type
  }
`;
