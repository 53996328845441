import {
  PreferenceCellWrapper,
  PreferencesCellIconWrapper,
  StyledOrgUrlInput,
  StyledOrgUrlInputOverlay,
  StyledOrgUrlInputWrapper,
  StyledSubmitAddressButton,
} from '../../styled/login.styled';
import {HCLabelTwo} from '../../../../components/HypercareComponents';
import {LOGIN_PAGE_ENTER_ORG_URL_INPUT_LABEL, LOGIN_PAGE_ORG_URL_TOOLTIP_TEXT} from '../../../../constants/strings';
import {StyledTooltipBlack} from '../../../../components/StyledTooltip';
import {BlackInfoIcon} from '../../../../svgs/BlackInfoIcon';
import {NEXT} from '../../../../constants/login';
import React from 'react';
import {muiTheme} from 'src/styles/theme';
import styled from '@emotion/styled';
import {ErrorMessage} from 'src/styles/styled-components/component';

export interface OrgUrlViewProps {
  orgUrl: string;
  setOrgUrl: (url: string) => void;
  handleNextButtonPressed: () => void;
  orgURLError: string;
}

export const OrganizationEnterURLView = ({
  orgUrl,
  setOrgUrl,
  handleNextButtonPressed,
  orgURLError,
}: OrgUrlViewProps) => {
  return (
    <>
      <PreferenceCellWrapper>
        <HCLabelTwo color={muiTheme.colors.text} lineHeight="20px">
          {LOGIN_PAGE_ENTER_ORG_URL_INPUT_LABEL}
        </HCLabelTwo>
        <StyledTooltipBlack
          enterTouchDelay={0}
          leaveTouchDelay={10000}
          arrow
          title={LOGIN_PAGE_ORG_URL_TOOLTIP_TEXT}
          className="styledPopper"
          placement="top"
        >
          <PreferencesCellIconWrapper>
            <BlackInfoIcon />
          </PreferencesCellIconWrapper>
        </StyledTooltipBlack>
      </PreferenceCellWrapper>

      <StyledOrgUrlInputWrapper>
        <StyledOrgUrlInput
          type="text"
          value={orgUrl}
          onChange={(e) => setOrgUrl(e.target.value)}
          placeholder="your-org.hypercare.com"
          data-typed={orgUrl}
        />

        <StyledOrgUrlInputOverlay hasValue={orgUrl.length > 0}>
          <span>{orgUrl}</span>
        </StyledOrgUrlInputOverlay>
      </StyledOrgUrlInputWrapper>
      <ErrorMessage>{orgURLError}</ErrorMessage>
      <StyledSubmitAddressButton disabled={orgUrl.length === 0} onClick={handleNextButtonPressed}>
        {NEXT}
      </StyledSubmitAddressButton>
    </>
  );
};
