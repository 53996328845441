import gql from 'graphql-tag';
import {
  AddressFragment,
  WorkStatusDirSyncFragment,
  AddressDirSyncFragment,
} from 'src/gql/v2/fragment/OrganizationUserResultDirSyncFragment';
import {FullOrganizationMember} from 'src/types';

export type FetchSelfOrgMemberResult = {
  organizationalUnitQuery: {
    organizationalUnit: {
      me: FullOrganizationMember;
    };
  };
};

export const FETCH_SELF_ORG_MEMBER = gql`
  query GetSelfOrgMember($organizationalUnit: OrganizationalUnitInput!, $isProfileAddressLabelFlagEnabled: Boolean!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          me {
            id
            firstName
            lastName
            username
            avatar {
              url
            }
            role
            workStatus {
              ...WorkStatusDirSyncFragment
            }
            addresses {
              ...AddressFragment
            }
          }
        }
      }
    }
  }
  ${WorkStatusDirSyncFragment}
  ${AddressFragment}
`;

export const FETCH_SELF_ORG_DIR_SYNC_MEMBER = gql`
  query GetSelfOrgMember($organizationalUnit: OrganizationalUnitInput!, $isProfileAddressLabelFlagEnabled: Boolean!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          id
          name
          me {
            id
            firstName
            lastName
            avatar {
              url
            }
            username
            role
            workStatus {
              ...WorkStatusDirSyncFragment
            }
            isDirectorySynced
            isSSOEnabled
            addresses {
              ...AddressDirSyncFragment
            }
          }
        }
      }
    }
  }
  ${WorkStatusDirSyncFragment}
  ${AddressDirSyncFragment}
`;

export const FETCH_SELF_ORG_DIR_SYNC_MEMBER_STA = gql`
  query GetSelfOrgMember($organizationalUnit: OrganizationalUnitInput!, $isProfileAddressLabelFlagEnabled: Boolean!) {
    organizationalUnitQuery {
      organizationalUnit(organizationalUnit: $organizationalUnit) {
        __typename
        ... on OrganizationalUnitNotFoundError {
          __typename
          message
        }
        ... on Organization {
          __typename
          id
          name
          me {
            id
            firstName
            lastName
            avatar {
              url
              __typename
            }
            username
            role
            workStatus {
              ...WorkStatusDirSyncFragment
              __typename
            }
            isDirectorySynced
            isSSOEnabled
            addresses {
              ...AddressDirSyncFragment
              __typename
            }
            __typename
          }
        }
      }
      __typename
    }
  }

  fragment WorkStatusDirSyncFragment on WorkStatus {
    value
    expiryDate
    message
    alternateContact {
      ...PublicUserFragment
      __typename
    }
    resetFields
    __typename
  }

  fragment PublicUserFragment on PublicUser {
    ... on Node {
      ...NodeFragment
      __typename
    }
    ... on GeneralUser {
      ...GeneralUserFragment
      __typename
    }
    __typename
  }

  fragment NodeFragment on Node {
    id
    __typename
  }

  fragment GeneralUserFragment on GeneralUser {
    username
    firstName
    lastName
    memberStatus
    __typename
  }

  fragment AddressDirSyncFragment on Address {
    id
    address
    type
    label @include(if: $isProfileAddressLabelFlagEnabled)
    verified
    access
    syncStatus
    __typename
  }
`;
