import ApiHelper from '../../../../api';
import {AuthPayloadDTO, AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {
  AccountStatus,
  DomainOrganization,
  OrganizationAccountsCacheData,
  OrgLoginMethods,
  OrgViews,
  WebViewCallBacks,
} from '../../../../types/sta';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {
  CURRENT_SELECTED_ORGANIZATION,
  EMAIL,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {
  TOO_MANY_CHALLENGES,
  TOO_MANY_CHALLENGES_MSG,
  VALIDATION_CODE_FAILED_SEND_MSG,
  VALIDATION_REQUEST_FAIL,
} from '../../../../constants/networkError';
import {localStorageService} from '../../../../services/localStorageService';
import {callNative} from '../../../../nativeBridge';
import {
  ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR,
  ACCOUNT_SELECTION_NO_SUPPORTED_LOGIN_METHODS_ERROR,
  ACCOUNT_SELECTION_USER_NOT_FOUND_ERROR,
} from '../../../../constants/strings';
import {SSOProfile} from '../../../../gql/v2/query/ListSSOProfilesQuery';
import {MEMBER_BLOCKED, NO_SUPPORTED_LOGIN_METHODS, USER_BLOCKED, USER_NOT_FOUND} from '../../../../constants/login';

export const AccountSelectionViewModel = () => {
  const getSavedAccountCacheData = () => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
    if (!currentCacheData?.savedOrganizations) {
      return null;
    }
    return currentCacheData;
  };

  const suggestedOrganizationClick = async (org: DomainOrganization) => {
    const currentCacheData = getSavedAccountCacheData();

    if (!currentCacheData?.savedOrganizations) return;

    const foundAccount = currentCacheData.savedOrganizations.find((acc) =>
      org.domains.includes(acc.user.email?.split('@')[1] || ''),
    );

    if (foundAccount) {
      return await handleSuggestedOrganizationFlow(foundAccount.user.email || '', org);
    }
  };

  const handleSuggestedOrganizationFlow = async (email: string, org: DomainOrganization) => {
    const emailDomain = email?.split('@')[1];

    const basicUserRequest = await ApiHelper.PublicEndpoints.fetchBasicUser(email, org.url);

    const basicUserResult = basicUserRequest.data?.user;

    if (basicUserResult) {
      if (
        basicUserResult.__typename === USER_BLOCKED ||
        basicUserResult.memberStatus === 'archived' ||
        basicUserResult.__typename === MEMBER_BLOCKED
      ) {
        return {error: USER_BLOCKED};
      }

      if (basicUserResult.memberStatus === 'active' || basicUserResult.memberStatus === 'inactive') {
        return {screen: OrgViews.FIND_MY_ORGANIZATION, email};
      } else {
        const ssoProfile = await findSSOProfileForOrg(emailDomain);

        if (ssoProfile) {
          return await handleSSOLogin(ssoProfile);
        } else {
          const challengeIdres = await requestAddressOTP({address: email, type: 'email'});
          if (typeof challengeIdres === 'string') {
            const challengeId: string = challengeIdres;
            window.routerHistory.push(
              `/signup?email=${email?.replaceAll('+', '%2b')}&challengeId=${challengeId}&orgUrl=${
                org.url
              }&step=2&orgId=${org.id}&orgName=${org.name}`,
            );
            callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.SIGNUP});
          } else {
            if (challengeIdres.error) {
              return {
                error: TOO_MANY_CHALLENGES,
              };
            } else {
              return {
                error: VALIDATION_REQUEST_FAIL,
              };
            }
          }
        }
      }
    }
  };

  const findSSOProfileForOrg = async (domain: string) => {
    const {data} = await ApiHelper.PublicEndpoints.listSSOProfiles();
    return data?.listSSOProfiles.find((profile) => profile.domain === domain);
  };

  const handleSSOLogin = async (ssoProfile: SSOProfile) => {
    if (ssoProfile.auth0Id && ssoProfile.ssoVendor) {
      localStorage.setItem(SSO_VENDOR, ssoProfile.ssoVendor);

      if (ssoProfile.ssoVendor === AuthSSOVendors.WORKOS) {
        await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(ssoProfile.auth0Id, ssoProfile.ssoVendor);
        callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.SSO});
        return;
      } else {
        return {auth0Id: ssoProfile.auth0Id, address: ''};
      }
    }
  };

  const checkForSuggestedOrganizationsInList = (
    currentCacheData: OrganizationAccountsCacheData,
    organizationsForDomains: DomainOrganization[],
  ) => {
    const savedOrganizationIds = new Set(currentCacheData.savedOrganizations.map((org) => org.organization.id));

    return organizationsForDomains.filter((org) => !savedOrganizationIds.has(org.id));
  };

  const getOrganizationByDomains = async (domains: string[]) => {
    const res = await ApiHelper.PublicEndpoints.fetchOrganizationsByDomains(domains);

    if ('errors' in res || 'error' in res) {
      return {error: 'Error occurred when fetching organizations by domains'};
    }

    if ('data' in res) {
      return res.data;
    }

    return [];
  };

  const retrieveSuggestedOrganizations = async () => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
    if (!currentCacheData?.savedOrganizations) {
      return [];
    }

    const extractedDomains = currentCacheData.savedOrganizations.map((org) => org.user.email?.split('@')[1] || '');

    if (extractedDomains && extractedDomains.length > 0) {
      const res = await getOrganizationByDomains(extractedDomains);

      if (res && 'errors' in res) {
        return [];
      }

      if ('organizationsForDomains' in res) {
        const suggestedOrganizations = checkForSuggestedOrganizationsInList(
          currentCacheData,
          res.organizationsForDomains,
        );
        if (suggestedOrganizations) {
          return suggestedOrganizations;
        }
      }
    }
  };
  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === USER_NOT_FOUND) {
      return USER_NOT_FOUND;
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const handleLoginToClickedAccount = async (accountInformation: AuthPayloadDTO) => {
    const {organization, user} = accountInformation;
    const orgUrl = organization.url;
    const orgId = organization.id;
    const currentSavedAccountData =
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const userAddress = user.email || user.username || '';

    window.localStorage.setItem(ORG_URL, orgUrl);
    window.localStorage.setItem(EMAIL, userAddress || '');
    localStorage.setItem(CURRENT_SELECTED_ORGANIZATION, JSON.stringify(organization));

    window.localStorage.removeItem('flowStep');

    if (
      currentSavedAccountData?.savedOrganizations.some(
        (org) => org.user.id === accountInformation.user.id && org.accessToken,
      )
    ) {
      return {
        screen: 'Login',
      };
    }

    const basicUser = await getBasicUser(userAddress, orgUrl);

    if (!basicUser || basicUser === USER_NOT_FOUND) {
      return {error: USER_NOT_FOUND};
    }

    if (
      basicUser?.__typename === USER_BLOCKED ||
      basicUser?.__typename === MEMBER_BLOCKED ||
      basicUser.memberStatus === AccountStatus.BLOCKED ||
      basicUser.memberStatus === AccountStatus.ARCHIVED
    ) {
      return {error: USER_BLOCKED};
    }

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    const enteredEmailDomain = (accountInformation.user.email || accountInformation.user.username || '').split('@')[1];

    const checkAccountForSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkAccountForOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkAccountForPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({
      domain: enteredEmailDomain,
    });

    if ('error' in getOrgByDomain) {
      return {error: ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR};
    }

    const listSSOProfiles = await ApiHelper.PublicEndpoints.listSSOProfiles();

    let checkIfSSOEmailOfSelectedOrg;

    if ('data' in listSSOProfiles) {
      checkIfSSOEmailOfSelectedOrg = listSSOProfiles?.data?.listSSOProfiles.find(
        (profile) =>
          profile.domain === enteredEmailDomain && accountInformation.organization.id === profile.organization.id,
      );
    }

    if (checkIfSSOEmailOfSelectedOrg) {
      if (checkAccountForSSOScope) {
        if (checkIfSSOEmailOfSelectedOrg?.auth0Id && checkIfSSOEmailOfSelectedOrg?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, checkIfSSOEmailOfSelectedOrg?.ssoVendor);
          if (checkIfSSOEmailOfSelectedOrg?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              checkIfSSOEmailOfSelectedOrg?.auth0Id,
              checkIfSSOEmailOfSelectedOrg?.ssoVendor,
            );
            return {success: true};
          } else {
            return {auth0Id: checkIfSSOEmailOfSelectedOrg?.auth0Id, address: ''};
          }
        }
      }
    }

    if (checkAccountForOTPScope || checkAccountForPasswordScope) {
      if (basicUser.memberStatus === AccountStatus.INACTIVE) {
        const challengeId = await requestAddressOTP({address: userAddress, type: 'email'});
        if (typeof challengeId === 'string') {
          window.routerHistory.push(
            `/signup?email=${userAddress?.replaceAll(
              '+',
              '%2b',
            )}&challengeId=${challengeId}&orgUrl=${orgUrl}&step=2&orgId=${orgId}&activateShellAccountFlow=true&orgName=${
              accountInformation.organization.name
            }&firstName=${basicUser?.firstName}&lastName=${basicUser?.lastName}&role=${basicUser?.role}`,
          );
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
        } else {
          return challengeId;
        }
      } else {
        if (checkAccountForOTPScope && checkAccountForPasswordScope) {
          return {screen: OrgLoginMethods.PASSWORD, isMethodSwitchable: true};
        }

        if (checkAccountForPasswordScope) {
          return {screen: OrgLoginMethods.PASSWORD};
        }

        if (checkAccountForOTPScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: userAddress, type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
          } else {
            return startRequestOTPFlow;
          }
        }
      }
    } else {
      const ssoProfile = await fetchSSOProfileForUser(userAddress, orgUrl);

      if (!ssoProfile) return {error: 'Error occurred when fetching sso profile for user'};

      if (ssoProfile.__typename === USER_NOT_FOUND) {
        return {error: 'User not found'};
      }

      if (ssoProfile.__typename === 'SSOProfileNotFound') {
        return {error: NO_SUPPORTED_LOGIN_METHODS};
      }

      if (!ssoProfile.profile) return {error: 'Error occurred when fetching sso profile for user'};

      const extractedInformation = extractSSOProfileData(ssoProfile.profile);

      if (checkAccountForSSOScope && extractedInformation.ssoId) {
        if (extractedInformation?.auth0Id && extractedInformation?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, extractedInformation?.ssoVendor);
          if (extractedInformation?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              extractedInformation?.auth0Id,
              extractedInformation?.ssoVendor,
            );
            return;
          } else {
            return {auth0Id: extractedInformation?.auth0Id, address: ''};
          }
        }
      } else {
        return {
          error: NO_SUPPORTED_LOGIN_METHODS,
        };
      }
    }
  };

  const extractSSOProfileData = (profile: any) => {
    if (!profile) return {error: 'No sso profile information found'};
    const {auth0Id, ssoId, ssoVendor, domain} = profile;
    return {auth0Id, ssoId, ssoVendor, domain};
  };

  const fetchSSOProfileForUser = async (email: string, url: string) => {
    try {
      const response = await ApiHelper.PublicEndpoints.getSSOProfileForUser({email, url});
      if ('error' in response) {
        console.error('Error occurred when fetching SSO profile for user');
        return null;
      }
      return response.ssoProfileForUser;
    } catch (error) {
      console.error('Exception occurred while fetching SSO profile:', error);
      return null;
    }
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: TOO_MANY_CHALLENGES,
        };
      } else {
        return {
          error: VALIDATION_REQUEST_FAIL,
        };
      }
    }
  };

  return {
    handleLoginToClickedAccount,
    retrieveSuggestedOrganizations,
    suggestedOrganizationClick,
    handleSuggestedOrganizationFlow,
  };
};
