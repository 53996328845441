import React, {useState} from 'react';
import {
  AuthRegionMap,
  CHANGE_REGION_MODAL_DESCRIPTION_TEXT,
  LOGIN_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON,
  CHANGE_REGION_MODAL_TITLE_TEXT,
  SIGN_UP_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON,
  SIGNUP,
  CHANGE_REGION_CONFIRMATION_DIALOG_TITLE,
  CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_ONE,
  CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_TWO,
  ACCOUNT_SWITCH,
} from 'src/constants/strings';
import styled from '@emotion/styled';
import {AuthPayloadDTO, AuthRegion, IAuthRegionData} from 'src/types';

import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {ChangeRegionViewModel} from './ChangeRegionViewModel';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import {toast} from 'react-toastify';
import {HCCustomSpan, HCLabelOne, HCTextContext} from '../HypercareComponents';
import {LOGIN} from '../../constants/strings';
import {muiTheme} from 'src/styles/theme';
import {callNative} from '../../nativeBridge';
import {OrganizationAccountsCacheData, OrgViews, WebViewCallBacks} from '../../types/sta';

import {FCM_SECRETS, ORGANIZATION_ACCOUNTS_DATA} from '../../constants/storageKeys';
import {SecondaryButton} from '../../styles/styled-components/StyledMaterialComponents';
import {Grid} from '@mui/material';
import {localStorageService} from '../../services/localStorageService';
import {logoutAllSavedAccounts} from '../../utils/sta/staUtils';
import {LogoutViewModel} from '../../pages/LoginPage/sta/view-models/LogoutViewModel';

const ChangeRegionModalBodyTextContainer = styled.div`
  margin: 0 0 24px 0;
`;

const ChangeRegionModalAuthRegionItems = styled.ul`
  margin: 0 0 12px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  cursor: pointer;
`;

const RegionSelectorItem = styled.li<{$isSelected: boolean}>`
  background: ${(props) => (props.$isSelected ? '#F6F6F9' : 'transparent')};
  box-shadow: ${(props) => (props.$isSelected ? '0px 1px 4px rgba(0, 0, 0, 0.1)' : 'unset')};

  padding: 16px;
  gap: 10px;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  border-radius: 4px;
`;

interface IChangeRegionModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentScreen: string;
  handleNext?: () => void;
  setSavedAccountsData?: React.Dispatch<React.SetStateAction<AuthPayloadDTO[]>>;
}

const ChangeRegionModal = ({
  isOpen,
  setIsOpen,
  currentScreen,
  handleNext,
  setSavedAccountsData,
}: IChangeRegionModal) => {
  const {currentAuthRegion, AuthRegionData, submitRegionChange} = ChangeRegionViewModel();
  const {logoutFromAllAccounts} = LogoutViewModel();

  const [selectedRegion, setSelectedRegion] = useState<AuthRegion>(currentAuthRegion);
  const [confirmationModal, setShowConfirmationModal] = useState(false);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const closeChangeRegionModal = () => {
    setIsOpen(false);
  };

  const handleRegionChange = (option: IAuthRegionData) => {
    setSelectedRegion(option.region);
  };

  const handleSubmitRegionChange = () => {
    if (currentAuthRegion !== selectedRegion && currentCacheData && currentCacheData?.savedOrganizations?.length > 0) {
      setShowConfirmationModal(true);
    } else if (currentAuthRegion === selectedRegion) {
      setIsOpen(false);
    } else {
      handleConfirmationChange();
    }
  };

  const handleConfirmationChange = async () => {
    const result = await submitRegionChange(selectedRegion);
    if (result.success) {
      callNative(WebViewCallBacks.SWITCH_REGION, {regionCode: selectedRegion});
      localStorage.removeItem(ORGANIZATION_ACCOUNTS_DATA);
      localStorageService.removeItem(FCM_SECRETS);
      closeChangeRegionModal();
      setSavedAccountsData && setSavedAccountsData([]);

      if (currentScreen === SIGNUP) {
        handleNext?.();
      }

      if (currentScreen === ACCOUNT_SWITCH) {
        window.routerHistory.push('/login');
      }

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.switchRegionPressed,
        params: {
          region_name: selectedRegion,
        },
      });
    } else {
      toast.error(result.error, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }
  };

  return (
    <>
      <AlertModal
        width="sm"
        titleColor={muiTheme.colors.watermelon}
        disableBackDropClick={true}
        title={CHANGE_REGION_MODAL_TITLE_TEXT}
        titleFontSize="21px"
        isAlertModalVisible={isOpen}
        showCloseButton={true}
        closeAlertModal={() => setIsOpen(false)}
        alertModalButtons={[
          {
            type: 'secondary',
            buttonLabel: 'Cancel',
            onClickHandler: () => setIsOpen(false),
            id: 'logout-btn',
          },
          {
            type: 'primary',
            disabled: !selectedRegion,
            buttonLabel:
              currentScreen === LOGIN
                ? LOGIN_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON(selectedRegion, AuthRegionMap)
                : SIGN_UP_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON(selectedRegion, AuthRegionMap),
            onClickHandler: () => handleSubmitRegionChange(),
            id: 'continue-session-btn',
          },
        ]}
        modalContent={
          <>
            <ChangeRegionModalBodyTextContainer>
              <HCLabelOne color="#4A4A4A">{CHANGE_REGION_MODAL_DESCRIPTION_TEXT}</HCLabelOne>
            </ChangeRegionModalBodyTextContainer>

            <ChangeRegionModalAuthRegionItems>
              {AuthRegionData.map((opt, index) => (
                <RegionSelectorItem
                  key={`${index}-${opt.regionName}`}
                  $isSelected={selectedRegion === opt.region}
                  onClick={() => handleRegionChange(opt)}
                >
                  <img width="24px" height="24px" src={opt?.flagIcon} alt={opt?.regionName} />
                  <HCTextContext>{opt.regionName}</HCTextContext>
                </RegionSelectorItem>
              ))}
            </ChangeRegionModalAuthRegionItems>
          </>
        }
      />
      <AlertModal
        width="sm"
        titleColor={muiTheme.colors.watermelon}
        title={CHANGE_REGION_CONFIRMATION_DIALOG_TITLE}
        titleFontSize="21px"
        isAlertModalVisible={confirmationModal}
        disableBackDropClick={true}
        showCloseButton={true}
        closeAlertModal={() => setShowConfirmationModal(false)}
        alertModalButtons={[
          {
            type: 'secondary',
            buttonLabel: 'Cancel',
            onClickHandler: () => setShowConfirmationModal(false),
            id: 'logout-btn',
          },
          {
            type: 'primary',
            buttonLabel: 'Continue',
            onClickHandler: () => handleConfirmationChange(),
            id: 'continue-session-btn',
          },
        ]}
        modalContent={
          <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item>
              <HCCustomSpan>{CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_ONE}</HCCustomSpan>
            </Grid>
            <Grid item>
              <HCCustomSpan>{CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_TWO}</HCCustomSpan>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default ChangeRegionModal;
