import gql from 'graphql-tag';

export const UNREGISTER_PUSH_TOKEN_MUTATION = gql`
  mutation UnregisterPushToken($userId: String!, $token: String!) {
    unregisterPushToken(userId: $userId, pushToken: $token) {
      __typename
      ... on UnregisterPushTokenResult {
        ... on UnregisterPushTokenSuccess {
          success
        }
        ... on Error {
          message
        }
      }
    }
  }
`;
