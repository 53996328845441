import {localStorageService} from '../../../../services/localStorageService';
import {OrganizationAccountsCacheData} from '../../../../types/sta';
import {FCM_SECRETS, FCM_TOKEN, ORGANIZATION_ACCOUNTS_DATA} from '../../../../constants/storageKeys';
import {AuthPayloadDTO} from '../../../../types';
import getParsedAuthInfo from '../../../../utils/localStorageHandler';
import {getAccountFromCache, sortSavedAccounts} from '../../../../utils/sta/staUtils';
import authHelper from '../../../../auth/authHelper';
import ApiHelper from 'src/api';
import {UserRepository} from '../../../../data/repository/UserRepository';

export const LogoutViewModel = (logout?: any) => {
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const getAuthInfo = getParsedAuthInfo();
  const {removeAllUserSessions} = UserRepository();

  const logoutOfAccount = (currentSelectedAcc: AuthPayloadDTO | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        const account = getAccountFromCache(currentSelectedAcc.user.id);

        if (account) {
          const res = revokeAccessTokenForSingleAccount(account.accessToken || '');
        }

        newCachedData.savedOrganizations = sortSavedAccounts(
          currentCacheData?.savedOrganizations?.map((org) =>
            org.user.id === currentSelectedAcc?.user.id
              ? {
                  ...org,
                  accessToken: null,
                  refreshToken: null,
                  accessTokenExpiresAt: null,
                }
              : org,
          ),
        );

        if (currentSelectedAcc.user.id === getAuthInfo?.user.id) {
          newCachedData.selectedAccountUserId = '';
          unregisterPushToken(account?.user.id || '').then(() => {
            logout && logout();
          });
        } else {
          unregisterPushToken(account?.user.id || '');
        }

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        removeFcmToken(account?.user.id || '');
        return {
          success: true,
          data: newCachedData,
        };
      }
    } catch (err) {
      console.log(err, 'err');
      return {
        success: false,
      };
    }
  };

  const removeFcmToken = (userId: string) => {
    const secretCache = localStorageService.getItem(FCM_SECRETS) ?? {};
    delete secretCache[userId];
    localStorageService.setItem(FCM_SECRETS, secretCache);
  };

  const removeAccount = (currentSelectedAcc: AuthPayloadDTO | null) => {
    if (!currentSelectedAcc) {
      return {
        success: false,
      };
    }

    const account = getAccountFromCache(currentSelectedAcc.user.id);

    if (account) {
      revokeAccessTokenForSingleAccount(account.accessToken || '');
    }

    try {
      if (currentCacheData) {
        let newCachedData = currentCacheData;

        newCachedData.savedOrganizations = sortSavedAccounts(
          currentCacheData?.savedOrganizations?.filter((org) => org.user.id !== currentSelectedAcc?.user.id),
        );

        newCachedData.selectedAccountUserId = '';

        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, newCachedData);

        if (newCachedData.savedOrganizations.length === 0 || !window.location.href.includes('/login')) {
          logout && logout();
        }

        removeFcmToken(account?.user.id || '');

        return {
          success: true,
          data: newCachedData,
        };
      }

      return {
        success: false,
      };
    } catch (err) {
      console.log(err, 'err');
      return {
        success: false,
      };
    }
  };

  const logoutFromAllAccounts = async () => {
    if (!currentCacheData?.savedOrganizations) {
      return;
    }

    const tokens = currentCacheData.savedOrganizations.filter((acc) => acc.accessToken);

    const results = tokens.map((acc) => revokeAccessTokenForSingleAccount(acc.accessToken || ''));

    const res = await Promise.all(results);

    return {success: true};
  };

  const revokeAccessTokenForSingleAccount = async (accessToken: string) => {
    await removeAllUserSessions(accessToken);
  };

  const unregisterPushToken = async (userId: string) => {
    const token = localStorage.getItem(FCM_TOKEN);
    return await ApiHelper.PublicEndpoints.unRegisterPushNotificationSTA({userId, token});
  };

  return {logoutOfAccount, removeAccount, logoutFromAllAccounts};
};
