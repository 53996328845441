import React, {useState, useCallback} from 'react';
import styled from '@emotion/styled';
import LoadingDiv from 'src/components/LoadingDiv';
import {TextOnlyButton} from 'src/styles/styled-components/Button';
import {UserSelectOptionType} from 'src/types';
import AddMembersMutation from 'src/gql/mutation/AddMembersMutation';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {Chat} from 'src/types';
import client from 'src/apollo';
import ChatInformationUserDropDownV2 from './ChatInformationUserDropdownV2';
import {ValueType} from 'react-select/src/types';
import {Button, Divider} from '@mui/material';
import {muiTheme} from 'src/styles/theme';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import ChatInformationUserDropDownSTA from './ChatInformationUserDropdownSTA';
import {isSTAFeatureFlagEnabled} from '../../../../utils/sta/staUtils';

const Header = styled.div`
  font-family: 'Nunito Sans';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  color: ${muiTheme.colors.watermelon};
`;

const Title = styled.h2`
  font-weight: 500;
`;

const AddMemberModalLayout = styled.div`
  height: 620px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const OverflowWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const AddButton = styled(Button)`
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: ${muiTheme.colors.watermelon};
  color: ${muiTheme.colors.messageWhite};
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const CancelButton = styled.button`
  display: flex;
  height: 36px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${muiTheme.colors.borderBottomLight};
  background: ${muiTheme.colors.messageWhite};
  color: ${muiTheme.colors.primary};
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  border-radius: 0px 0px 0px 6px;
  background: ${muiTheme.colors.messageWhite};
  padding-top: 16px;
`;

interface Props {
  closeModal: () => void;
  chat: Chat;
  shouldNotCloseModal: () => void;
  shouldCloseModal: () => void;
}

const ChatInformationAddUserModalV2: React.FC<Props> = ({closeModal, chat, shouldNotCloseModal, shouldCloseModal}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [selectedColleagues, setSelectedColleagues] = useState<Set<UserSelectOptionType>>(new Set());
  const [searchText, setSearchText] = useState('');
  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([]);
  const STAFlag = isSTAFeatureFlagEnabled();

  const tryCloseModal = () => {
    if (!isAdding) closeModal();
  };

  const onDone = useCallback(async () => {
    if (selectedColleagues.size === 0 || isAdding) return;
    setIsAdding(true);

    try {
      await shouldNotCloseModal();
      const userIds = Array.from(selectedColleagues).map((colleague) => {
        return colleague.value;
      });

      await client.mutate({
        mutation: AddMembersMutation,
        variables: {
          chatId: chat.id,
          userIds,
        },
      });

      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.addMembers,
        params: {
          count: selectedColleagues.size,
          chat_id: chat.id,
        },
      });
    } catch (e) {
      console.error(e);
    }
    setIsAdding(false);
    shouldCloseModal();
    closeModal();
  }, [isAdding, selectedColleagues, shouldNotCloseModal, shouldCloseModal, closeModal, client, chat]);

  const onClickColleague = (colleagues: any) => {
    setSelectedColleagues(new Set(colleagues));
  };

  return (
    <>
      <AddMemberModalLayout>
        <Header>
          <Title>Add members</Title>
        </Header>
        <OverflowWrapper>
          {isAdding ? (
            <LoadingDiv />
          ) : STAFlag ? (
            <ChatInformationUserDropDownSTA
              chat={chat}
              onClickColleague={onClickColleague}
              selectedColleague={selectedColleague}
              setSelectedColleague={setSelectedColleague}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          ) : (
            <ChatInformationUserDropDownV2
              chat={chat}
              onClickColleague={onClickColleague}
              selectedColleague={selectedColleague}
              setSelectedColleague={setSelectedColleague}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          )}
        </OverflowWrapper>
      </AddMemberModalLayout>
      <Divider />
      <StyledModalFooter>
        <CancelButton onClick={tryCloseModal}>Cancel</CancelButton>
        <AddButton
          onClick={onDone}
          color="inherit"
          variant="contained"
          disabled={!selectedColleague.length}
          disableTouchRipple
          disableRipple
          style={{
            boxShadow: 'none',
            textTransform: 'none',
            color: '#fff',
            backgroundColor: selectedColleague.length > 0 ? '#ff3e55' : '#BCBCBC',
          }}
        >
          {selectedColleague.length > 0 ? `Add (${selectedColleague.length})` : `Add`}
        </AddButton>
      </StyledModalFooter>
    </>
  );
};

export default ChatInformationAddUserModalV2;
