import React from 'react';
import {toast} from 'react-toastify';
import styled from '@emotion/styled';
import {typedUseSelector} from 'src/redux';
import {CONTACTS} from 'src/constants/routerPathName';
import InviteColleaguesModal from 'src/components/InviteColleaguesModal';
import FAQSection from './views/FAQSection';
import TopPanelBanner from './views/TopPanelBanner';
import HypercareSelfProfileViewModel from 'src/pages/LocatingPage/viewModels/HypercareSelfProfileViewModel';
import ContactListWrapperV2 from '../ContactListSection/ContactListWrapperV2';
import InvitationUserListWrapperV2 from 'src/pages/ContactsPage/InvitedUserSection/InvitationUserListWrapperV2';
import {usePaginatedSearchState} from '../ContactListSection/hooks/usePaginatedSearchState';
import debounce from 'lodash/debounce';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import ContactsSearchBar from 'src/components/ContactsSearchBar';
import {USERS_PAGE_SEARCH_FIELD_PLACEHOLDER} from 'src/constants/strings';
import {usePaginatedDataState} from '../ContactListSection/hooks/usePaginatedDataState';
import {SitesFilter} from 'src/pages/ContactsPage/filters/SitesFilter';
import {SiteScope, UserAddress} from 'src/types';
import {SiteScopes} from 'src/data/repository/UserRepository';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import HypercareSchedulingViewModel from '../../LocatingPage/viewModels/HypercareSchedulingViewModel';
import {MinimalSiteData} from '../../../gql/query/FetchSitesForOrganization';
import {InviteUserResponseModal} from 'src/components/InvitationTabView/views/InviteUserResponseModal';
import {IInviteResponse} from 'src/gql/v2/mutation/InviteUsersMutation';
import InvitationUserListWrapperSTA from '../InvitedUserSection/InvitationUserListWrapperSTA';
import {isSTAFeatureFlagEnabled} from '../../../utils/sta/staUtils';

const LayoutWrapper = styled.div`
  height: calc(100vh - 60px - 60px);
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const InvitationSearchLayoutV2 = () => {
  const [showInviteColleagueModal, setInviteColleagueModal] = React.useState(false);
  const [selectedSitesIds, setSelectedSitesIds] = React.useState<number[]>([]);
  const [siteFullScope, setSiteFullScope] = React.useState<MinimalSiteData[]>([]);
  const pcSiteFiltersFlagEnabled = IsFeatureFlagEnabled(FeatureFlagResult.pcSiteFilters);
  const organizationId = typedUseSelector((store) => store.organization.organizationId);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [inviteResponse, setInviteResponse] = React.useState<IInviteResponse[]>([]);
  const [addressesInput, setAddressesInput] = React.useState<UserAddress[]>([]);
  const STAFlag = isSTAFeatureFlagEnabled();
  const handleOpenInviteColleagueModal = () => {
    setShowResponseModal(false);
    setInviteColleagueModal(true);
  };

  const handleOpenResponseModal = () => {
    setInviteColleagueModal(false);
  };

  const handleOnCloseResponseModal = () => {
    setShowResponseModal(false);
  };

  const {useGetOrganizationAllowInviteStatus} = HypercareSelfProfileViewModel();

  const {getSitesForOrganization} = HypercareSchedulingViewModel();

  React.useEffect(() => {
    const getSitesForOrg = async () => {
      const result = await getSitesForOrganization(organizationId || 0, false);

      if (result?.siteFullScope) {
        setSiteFullScope(result.siteFullScope);
      } else {
        setSiteFullScope([]);
      }
    };

    getSitesForOrg();
  }, []);

  const siteOptions = React.useMemo(() => {
    return siteFullScope.map((site) => ({
      id: String(site.id),
      label: site.name,
      value: site,
    }));
  }, [siteFullScope]);

  const sitesScope = React.useMemo(() => {
    return selectedSitesIds.map((siteId) => ({
      id: Number(siteId),
      type: 'site',
    }));
  }, [selectedSitesIds]);

  const urlSearch = window.location.search;

  React.useEffect(() => {
    if (organizationId) {
      if (urlSearch.indexOf('?') > -1 && urlSearch.includes('open')) {
        setInviteColleagueModal(true);
        window.history.replaceState({}, document.title, `${window.location.origin}/${CONTACTS}`);
      }
    } else {
      setInviteColleagueModal(false);
      toast.warn(`Please select an organization first in order to invite`);
    }
  }, [organizationId, urlSearch]);

  function handleClickInviteColleagues() {
    if (organizationId) {
      setInviteColleagueModal(true);
    } else {
      toast.warn(`Please select an organization first in order to invite`);
    }
  }

  const handleOnComplete = React.useCallback(() => {
    STAFlag && setShowResponseModal(true);
  }, []);

  const {data: organizationAllowInviteStatus} = useGetOrganizationAllowInviteStatus();

  const userViewModel = UserViewModel();

  const {searchUserData, setSearchUserData, searchLoading, setSearchLoading, setLocalSearchText, localSearchText} =
    usePaginatedSearchState();

  const {isDoneRows, setIsDoneRows, setSeenContinuationIds, seenContinuationIds} = usePaginatedDataState();

  React.useEffect(() => {
    if (sitesScope && localSearchText.length > 0) {
      setSearchLoading(true);
      handleUserSearch(localSearchText, sitesScope);
    }
  }, [sitesScope]);

  const fetchUserSearchData = async (searchValue: string, scopes: SiteScopes[]) => {
    const searchResult = await userViewModel.getSearchUsers({
      text: searchValue,
      continuationId: null,
      limit: 30,
      scopes: scopes,
    });

    const ls = await userViewModel.getSearchPaginatedInvites({
      pageInfo: {
        cursor: null,
        pageSize: 30,
      },
      filters: {
        inviteStatus: 'pending_admin_approval',
        searchQuery: searchValue,
      },
    });

    if (searchResult?.error !== undefined) {
      setSearchUserData(null);
      return;
    }

    if (searchResult?.users) {
      setSearchUserData(searchResult);
    }

    setSearchLoading(false);
  };

  const handleUserSearch = React.useCallback(
    debounce(async (searchValue: string, scopes: SiteScopes[]) => {
      await fetchUserSearchData(searchValue, scopes);
    }, 250),
    [],
  );

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSearchText = e.target.value;
    setSeenContinuationIds([]);
    setIsDoneRows(false);
    setLocalSearchText(updatedSearchText);

    if (updatedSearchText === '') {
      setSearchUserData(null);
      return;
    }

    if (updatedSearchText.length <= 1) {
      return;
    }

    setSearchLoading(true);

    handleUserSearch(updatedSearchText, sitesScope);
  };

  const clearSearchText = () => {
    setLocalSearchText('');
    setSearchUserData(null);
    setSeenContinuationIds([]);
    setIsDoneRows(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <React.Fragment>
      <TopPanelBanner
        allowInvites={organizationAllowInviteStatus}
        handleClickInviteColleagues={handleClickInviteColleagues}
      />

      <ContactsSearchBar
        clearSearchText={clearSearchText}
        handleKeyDown={handleKeyDown}
        handleChange={handleSearch}
        localSearchText={localSearchText}
        placeHolder={USERS_PAGE_SEARCH_FIELD_PLACEHOLDER}
        inputWidth={'360px'}
      />

      {pcSiteFiltersFlagEnabled && (
        <div style={{marginLeft: '15px'}}>
          <SitesFilter
            selectedSitesIds={selectedSitesIds}
            setSelectedSitesIds={setSelectedSitesIds}
            siteOptions={siteOptions.map((o) => ({...o, id: parseInt(o.id)}))}
            setLocalSearchText={setLocalSearchText}
          />
        </div>
      )}

      <LayoutWrapper>
        <FAQSection
          allowInvites={organizationAllowInviteStatus}
          handleClickInviteColleagues={handleClickInviteColleagues}
        />

        <InvitationUserListWrapperV2 />

        <ContactListWrapperV2
          localSearchText={localSearchText}
          searchLoading={searchLoading}
          searchUserData={searchUserData}
          setSearchUserData={setSearchUserData}
          isDoneRows={isDoneRows}
          setIsDoneRows={setIsDoneRows}
          seenContinuationIds={seenContinuationIds}
          setSeenContinuationIds={setSeenContinuationIds}
          scopes={sitesScope}
        />

        {showInviteColleagueModal && (
          <InviteColleaguesModal
            isOpen={showInviteColleagueModal}
            closeModal={handleOpenResponseModal}
            handleOnComplete={handleOnComplete}
            setInviteResponse={setInviteResponse}
            setAddressesInput={setAddressesInput}
          />
        )}

        {STAFlag && showResponseModal && (
          <InviteUserResponseModal
            isOpen={showResponseModal}
            handleOnCloseResponseModal={handleOnCloseResponseModal}
            handleOnClick={handleOpenInviteColleagueModal}
            inviteResponse={inviteResponse}
            addressesInput={addressesInput}
          />
        )}
      </LayoutWrapper>
    </React.Fragment>
  );
};

export default InvitationSearchLayoutV2;
