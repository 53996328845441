import React from 'react';
import styled from '@emotion/styled';
import {HCHeadingThree, HCLabelOne} from '../../../components/HypercareComponents';
import {USER_NOT_FOUND_BODY_ONE, USER_NOT_FOUND_BODY_TWO, USER_NOT_FOUND_TITLE} from '../../../constants/strings';
import UserNotFoundWarningIcon from '../../../svgs/UserNotFoundWarningIcon';

const UserNotFoundMessageContainer = styled.div`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  justify-content: center;
`;

const UserNotFoundMessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  text-align: center;
`;

export const UserNotFoundMessage = () => {
  return (
    <UserNotFoundMessageContainer>
      <UserNotFoundWarningIcon />
      <HCHeadingThree color={'black'}>{USER_NOT_FOUND_TITLE}</HCHeadingThree>
      <UserNotFoundMessageTextContainer>
        <HCLabelOne>{USER_NOT_FOUND_BODY_ONE}</HCLabelOne>
        <HCLabelOne>{USER_NOT_FOUND_BODY_TWO}</HCLabelOne>
      </UserNotFoundMessageTextContainer>
    </UserNotFoundMessageContainer>
  );
};
