import React, {useEffect} from 'react';
import {FeatureFlagResult} from '../utils/FeatureFlags';

export const useFeatureFlagQueryParamOverride = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const staFeatureFlagOverrideValue = params.get(FeatureFlagResult.singleTenantAccountFlagCa) || '';
    if (staFeatureFlagOverrideValue) {
      localStorage.setItem(FeatureFlagResult.singleTenantAccountFlagCa, staFeatureFlagOverrideValue);
    } else {
      localStorage.removeItem(FeatureFlagResult.singleTenantAccountFlagCa);
    }
  }, []);
  return null;
};
