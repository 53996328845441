import React, {useContext, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from 'src/auth/AuthProvider';
import styled from '@emotion/styled';
import {Box, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import BriefcaseIcon from 'src/svgs/BriefCaseIcon';
import LockIcon from 'src/svgs/LockIcon';
import UserIcon from 'src/svgs/UserIcon';
import NextStepButton from 'src/components/NextStepButton';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';
import {INTRO_DONE} from 'src/constants/storageKeys';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import HypercareAuthRegionContext from 'src/contexts/HypercareLoginCoordinatorContext';
import ChangeRegionModal from 'src/components/ChangeRegion/ChangeRegionModal';
import {SIGNUP} from 'src/constants/strings';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import SignupCoordinator from '../newSignupFlow/STA/SignupCoordinator';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {HCLabelOne} from 'src/components/HypercareComponents';
import {formatMessage} from 'src/components/MaintenancePage/DynamicMaintenancePageUtils';
import {EXISTING_ACCOUNTS_PARAM} from '../../constants/queryParams';
import {isSTAFeatureFlagEnabled} from '../../utils/sta/staUtils';

const PREFIX = 'auth-intro';

const classes = {
  root: `${PREFIX}-root`,
  leftSection: `${PREFIX}-leftSection`,
  rightSection: `${PREFIX}-rightSection`,
  title: `${PREFIX}-title`,
  loginLabel: `${PREFIX}-loginLabel`,
  subLoginLabel: `${PREFIX}-subLoginLabel`,
  link: `${PREFIX}-link`,
  loginLink: `${PREFIX}-loginLink`,
  termsLabel: `${PREFIX}-termsLabel`,
  helpCenter: `${PREFIX}-helpCenter`,
};

const AuthIntroPageContainer = styled('div')(({theme}) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
  },

  [`& .${classes.leftSection}`]: {
    flex: 1,
    color: '#222222',
    backgroundColor: 'rgba(0, 133, 154, 0.2)',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 40,
    fontWeight: 400,
    lineHeight: 1.6,
    fontFamily: 'OpenSans',
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      maxWidth: '40%',
      fontSize: '32px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.rightSection}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(8),
    boxSizing: 'border-box',
    MozBoxSizing: 'border-box',
    WebkitBoxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.title}`]: {
    lineHeight: 1.5,
    fontSize: 40,
    fontWeight: 'bold',
    fontFamily: "'Nunito Sans', sans-serif",
    [theme.breakpoints.down('md')]: {
      fontSize: 32,
    },
  },

  [`& .${classes.loginLabel}`]: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.subLoginLabel}`]: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "'Nunito Sans', sans-serif",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.link}`]: {
    color: theme.colors.primary,
    textDecoration: 'none',
    fontFamily: "'Nunito Sans', sans-serif",
  },

  [`& .${classes.loginLink}`]: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontFamily: "'Nunito Sans', sans-serif",
  },

  [`& .${classes.termsLabel}`]: {
    marginTop: theme.spacing(2),
    fontFamily: "'Nunito Sans', sans-serif",
  },

  [`& .${classes.helpCenter}`]: {
    fontSize: 18,
    marginBottom: 16,
    color: theme.palette.common.black,
    textDecoration: 'none',
    textAlign: 'right',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
}));

const LogoWrapper = styled.div`
  display: relative;
  max-width: 264px;
  margin-bottom: 24px;
  image {
    width: 100%;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  margin-bottom: 0.5em !important;
  color: ${(props) => props.theme.colors.blackFontColor} !important;
`;

const LoginButton = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  text-transform: none;
  min-width: 64px;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.02857rem;
  line-height: 1.75;
  padding: 8px 11px;
  font-size: 0.9375rem;
  &:hover {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

interface IStepInfo {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

const StepInfo: React.FC<IStepInfo> = ({icon, title, subtitle}) => {
  return (
    <Box display="flex" mb={3}>
      {icon}
      <Box ml={3}>
        <StyledTypography>{title}</StyledTypography>
        <Typography variant="body2" color="textSecondary" style={{fontSize: 16}}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

const AuthIntroPage: React.FC<{isLoggedIn: boolean}> = ({isLoggedIn}) => {
  const [showSignup, setShowSignup] = React.useState(false);
  const [showVersionModal, setShowVersionModal] = React.useState(false);
  const [showDisableSignupModal, setShowDisableSignupModal] = React.useState<boolean>(false);
  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const STAFlag = isSTAFeatureFlagEnabled();
  const {dynamicMaintenanceLoginSignupBanner} = useFlags();

  const markIntroDone = () => {
    localStorage.setItem(INTRO_DONE, '1');
  };

  const handleLogin = () => {
    markIntroDone();
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.introLoginBtnPressed,
    });
  };

  const handleCreateAccountButton = () => {
    if (dynamicMaintenanceLoginSignupBanner?.signup?.messages?.[0]?.title) {
      setShowDisableSignupModal(true);
    } else {
      setChangeRegionModalOpenStatus(true);
    }
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.introSignupBtnPressed,
    });
  };

  const handleRegionItemSelected = () => {
    setShowSignup(true);
  };

  const handleTermAndService = (event) => {
    AnalyticsManager.applyAnalytics({
      eventName: event,
    });
  };

  if (isLoggedIn) return <Redirect to="/messenger/home" />;

  return (
    <AuthIntroPageContainer>
      <Box className={classes.root}>
        <Box className={classes.leftSection}>
          <LogoWrapper>
            <img alt="logo" src="/assets/horizontal-logo-red.svg" onClick={() => setShowVersionModal(true)} />
          </LogoWrapper>
          <Typography variant="h3" className={classes.title}>
            Optimize your workflow and improve patient care using Hypercare
          </Typography>
        </Box>
        <Box className={classes.rightSection}>
          <a
            href="https://www.hypercare.com/contact"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.helpCenter}
          >
            Help Center
          </a>
          <Box flex={1} display="flex" justifyContent="center" flexDirection="column">
            <Typography variant="h6" className={classes.loginLabel}>
              Already Used Hypercare?{' '}
            </Typography>
            <Typography variant={'h6'} className={classes.subLoginLabel}>
              New to Hypercare? Create a new account now! We'll ask for the following information to verify your
              identity to ensure that your account is secure:
            </Typography>
            <StepInfo icon={<UserIcon />} title="Your personal information" subtitle="Your name and phone number" />
            <StepInfo
              icon={<BriefcaseIcon />}
              title="Your employment information"
              subtitle="Your professional role, work email or organization login credentials"
            />
            <StepInfo icon={<LockIcon />} title="Login information" subtitle="Username and password" />
            <Link to="/login" className={classes.loginLink} onClick={handleLogin}>
              <NextStepButton label={'Get started'} />
            </Link>
            <Typography variant="body2" className={classes.termsLabel}>
              By clicking ‘Get started’, you acknowledge that you have read and agree to Hypercare’s{' '}
              <a
                href="https://www.hypercare.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                onClick={() => handleTermAndService(EVENTS.termOfServiceButtonPressed)}
              >
                Terms of Use
              </a>{' '}
              and{' '}
              <a
                href="https://www.hypercare.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                onClick={() => handleTermAndService(EVENTS.privacyPolicyButtonPressed)}
              >
                Privacy Policy.
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>

      {showDisableSignupModal && (
        <AlertModal
          title={dynamicMaintenanceLoginSignupBanner?.signup?.messages?.[0]?.title}
          modalContent={
            <HCLabelOne>
              {formatMessage(
                dynamicMaintenanceLoginSignupBanner?.signup?.messages?.[0]?.body,
                dynamicMaintenanceLoginSignupBanner?.signup?.messages?.[0]?.parameters,
              )}
            </HCLabelOne>
          }
          closeAlertModal={() => setShowDisableSignupModal(false)}
          isAlertModalVisible={showDisableSignupModal}
          alertModalButtons={[
            {
              type: 'primary',
              buttonLabel: 'OK',
              onClickHandler: () => setShowDisableSignupModal(false),
              id: 'send-btn',
            },
          ]}
          showCloseButton={true}
          width="sm"
        />
      )}

      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          handleNext={handleRegionItemSelected}
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={SIGNUP}
        />
      )}

      {showVersionModal && (
        <AlertModal
          title={`About us`}
          subtitle={`Hypercare version is: ${process.env.REACT_APP_VERSION}`}
          titleFontSize={'21px'}
          closeAlertModal={() => setShowVersionModal(false)}
          isAlertModalVisible={showVersionModal}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowVersionModal(false),
            },
          ]}
        />
      )}
    </AuthIntroPageContainer>
  );
};

export default () => (
  <AuthContext.Consumer>{({isLoggedIn}) => <AuthIntroPage isLoggedIn={isLoggedIn} />}</AuthContext.Consumer>
);
