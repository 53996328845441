import React, {useContext, useEffect, useState} from 'react';
import HypercareAuthRegionContext from '../../../../contexts/HypercareLoginCoordinatorContext';
import {AccountSelectionEditModeTitle, AccountSelectionTitle} from '../../../../microfrontend/login/SavedAccountsTitle';
import {LoginContainer, RowItemContainer, RowItemsRightSide} from '../../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../../microfrontend/login/SavedAccountOrganizationRowItems';
import {AddButtonV2} from '../../../../microfrontend/svgs/AddButtonV2';
import {HCLabelOne} from '../../../../components/HypercareComponents';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../../constants/strings';
import {CloseButtonV2} from '../../../../microfrontend/svgs/CloseButtonV2';
import {GearIcon} from '../../../../svgs/GearIcon';
import {getParsedAuthRegion} from '../../../../utils/localStorageHandler';
import {
  AUTH_INFO,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  FCM_SECRETS,
  FLOW_ENTRY,
  INTRO_DONE,
  ORGANIZATION_ACCOUNTS_DATA,
  REDIRECT_URI,
} from '../../../../constants/storageKeys';
import {
  DomainOrganization,
  ErrorModalButtons,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrgLoginMethods,
  OrgViews,
  WebViewCallBacks,
} from '../../../../types/sta';
import {toast} from 'react-toastify';
import {localStorageService} from '../../../../services/localStorageService';
import {AuthPayloadDTO, STALogin} from '../../../../types';
import {AccountSelectionViewModel} from '../view-models/AccountSelectionViewModel';
import {checkIfUserIsSignedIn} from '../../../../utils/userHelper/userUtils';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {LogoutViewModel} from '../view-models/LogoutViewModel';
import {LogoutAllAccountsModal} from '../components/LogoutAllAccountsModal';
import {AccountLogoutModal} from '../components/AccountLogoutModal';
import {callNative} from '../../../../nativeBridge';
import {SuggestedOrganizationsList} from './SuggestedOrganizationList';
import {muiTheme} from 'src/styles/theme';
import {LogoutOrContinueModal} from '../components/LogoutOrContinueModal';
import {getAdminWebAppUrl, getCurrentLoggedInAccount, logoutAllSavedAccounts} from '../../../../utils/sta/staUtils';
import HypercareErrorModalContext, {IErrorModalDetails} from '../../../../contexts/ErrorModalContext';
import {
  CONTACT_ADMIN_FOR_HELP,
  NO_SUPPORTED_LOGIN_METHODS,
  NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION,
  NO_SUPPORTED_LOGIN_METHODS_TITLE,
  UNKNOWN_LOGIN_ACCOUNT_ERROR,
  USER_BLOCKED,
  USER_BLOCKED_DESCRIPTION,
  USER_BLOCKED_TITLE,
  USER_NOT_FOUND,
  USER_NOT_FOUND_DESCRIPTION,
  USER_NOT_FOUND_TITLE,
} from '../../../../constants/login';
import {ErrorModal} from '../components/ErrorModal';
import {TOO_MANY_CHALLENGES} from '../../../../constants/networkError';
import {useUnreadChatCount} from '../../../../hooks/useUnreadChatCount';
import {ProgressContainer, UnreadChatCountBubble} from '../../styled/login.styled';
import LoginDisabledBanner from 'src/components/shared/LoginDisabledBanner';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {formatMessage} from 'src/components/MaintenancePage/DynamicMaintenancePageUtils';
import {LinearProgress} from '@mui/material';

interface IAccountSelectionViewProps {
  handleNextStep: () => void;
  STALogin: STALogin;
  showOtpView: (isMethodSwitchable?: boolean) => void;
  showPasswordView: (isMethodSwitchable?: boolean) => void;
  loginWithAuth0: (auth0Id: string) => void;
  setCurrentChallengeId: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSelectedOrg: React.Dispatch<React.SetStateAction<CurrentSelectedOrganization>>;
  savedAccountsData: AuthPayloadDTO[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<AuthPayloadDTO[]>>;
  handleShowEmailView: () => void;
  showFindMyOrgView: () => void;
  setErrorModalButtons: React.Dispatch<React.SetStateAction<ErrorModalButtons[] | null>>;
  setErrorModalOpenStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorModalDetails: React.Dispatch<React.SetStateAction<IErrorModalDetails>>;
  setCurrentFlow: React.Dispatch<React.SetStateAction<OrgViews>>;
  handleSubmitEmailForSuggestedOrg: (value: string, org: DomainOrganization) => Promise<void>;
}

export const AccountSelectionView = ({
  handleNextStep,
  STALogin,
  showOtpView,
  showPasswordView,
  loginWithAuth0,
  setCurrentChallengeId,
  setEmail,
  setCurrentSelectedOrg,
  savedAccountsData,
  setSavedAccountsData,
  handleShowEmailView,
  showFindMyOrgView,
  setErrorModalDetails,
  setErrorModalButtons,
  setErrorModalOpenStatus,
  setCurrentFlow,
  handleSubmitEmailForSuggestedOrg,
}: IAccountSelectionViewProps) => {
  const decodedURI = decodeURIComponent(window.location.search);
  const params = new URLSearchParams(decodedURI);
  const {dynamicMaintenanceLoginSignupBanner} = useFlags();
  const currentAuthRegion = getParsedAuthRegion();

  const flowEntry = params.get(FLOW_ENTRY);

  const [isEditMode, setIsEditMode] = useState(!!flowEntry);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
  const {setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentRegion = getParsedAuthRegion();
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<AuthPayloadDTO | null>(null);
  const [loginToAccountLoader, setLoginToAccountLoader] = useState(false);
  const {
    handleLoginToClickedAccount,
    retrieveSuggestedOrganizations,
    suggestedOrganizationClick,
    handleSuggestedOrganizationFlow,
  } = AccountSelectionViewModel();
  const {logoutOfAccount, removeAccount, logoutFromAllAccounts} = LogoutViewModel();
  const {unreadCount, fetchUnreadCount} = useUnreadChatCount();

  const [suggestedOrganizations, setSuggestedOrganizations] = useState<DomainOrganization[]>([]);
  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const redirectUri = localStorage.getItem(REDIRECT_URI);
  const {errorModalOpenStatus, errorModalDetails} = useContext(HypercareErrorModalContext);

  const [showLogoutOrContinueModal, setShowLogoutOrContinueModal] = useState(false);

  const handleSwitchRegion = () => {
    setChangeRegionModalOpenStatus(true);
  };

  const handleOpenLogoutAllAccountsModal = () => {
    setShowLogoutAllAccountsModal(true);
  };

  const handleOpenRemoveAccountModal = (organization: AuthPayloadDTO) => {
    let isUserSignedIn = checkIfUserIsSignedIn(organization);

    setCurrentSelectedAcc(organization);
    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success && removeAccountResponse?.data?.savedOrganizations) {
      setSavedAccountsData(removeAccountResponse?.data.savedOrganizations);
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setShowLogoutAccountModal(false);
    setShowRemoveAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success && logoutResponse?.data?.savedOrganizations) {
      setSavedAccountsData(logoutResponse?.data.savedOrganizations);
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    const res = logoutFromAllAccounts();
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(INTRO_DONE);
    localStorageService.removeItem(AUTH_INFO);
    localStorageService.removeItem(FCM_SECRETS);
    setSavedAccountsData([]);
    toast.info(`All accounts have been removed from the browser`);

    if (redirectUri) {
      window.location.href = getAdminWebAppUrl();
    }
  };

  useEffect(() => {
    if (encodedDataFromMobile) {
      const selectedAcc = encodedDataFromMobile.savedOrganizations?.find((acc) => {
        return acc.user.id === encodedDataFromMobile.selectedAccountUserId;
      });

      if (!selectedAcc) {
        return;
      }
      handleLoginToAccount(selectedAcc);

      const newData = encodedDataFromMobile;
      newData.selectedAccountUserId = '';

      window.localStorage.setItem('encodedDataFromMobileClients', JSON.stringify(newData));
    }
  }, []);

  useEffect(() => {
    const handleOpenDoorOrganization = async () => {
      if (encodedDataFromMobile && encodedDataFromMobile.selectedOpenDoorOrganization) {
        const {email, domainOrganization} = encodedDataFromMobile.selectedOpenDoorOrganization;
        const res = handleSuggestedOrganizationFlow(email, domainOrganization);

        if (res && 'screen' in res) {
          await handleSubmitEmailForSuggestedOrg(email || '', domainOrganization);
        } else if (res && 'error' in res) {
          let errorTitle = '';
          let errorDescription = '';
          let buttons: ErrorModalButtons[] | null = null;

          switch (res.error) {
            case TOO_MANY_CHALLENGES:
              errorTitle = 'Too many verification codes sent';
              errorDescription = 'Please try again later.';
              buttons = [
                {
                  type: 'secondary',
                  buttonLabel: 'Contact Hypercare Support',
                  onClickHandler: () => window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer'),
                  id: 'close-btn',
                },
              ];
              break;
          }

          setErrorModalOpenStatus(true);
          if (buttons && buttons.length > 0) {
            setErrorModalButtons(buttons);
          }
          setErrorModalDetails({title: errorTitle, description: errorDescription});
        }
      }
    };

    handleOpenDoorOrganization();
  }, []);

  useEffect(() => {
    const fetchSuggestedOrganizations = async () => {
      const organizations = await retrieveSuggestedOrganizations();

      if (organizations && organizations.length > 0) {
        setSuggestedOrganizations(organizations);
      }
    };

    fetchSuggestedOrganizations();
  }, []);

  useEffect(() => {
    const getCount = async () => {
      fetchUnreadCount();
    };

    getCount();
  }, []);

  const handleLoginToAccount = async (savedOrg: AuthPayloadDTO) => {
    setLoginToAccountLoader(true);
    setCurrentFlow(OrgViews.ACCOUNT_SELECTION);
    setCurrentSelectedOrg(savedOrg.organization);
    let isUserSignedIn = checkIfUserIsSignedIn(savedOrg);

    if (!isUserSignedIn) {
      const res = await handleLoginToClickedAccount(savedOrg);

      console.log(res);

      if (res && 'error' in res) {
        callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
        let errorTitle = '';
        let errorDescription = '';
        let buttons: ErrorModalButtons[] | null = null;
        switch (res.error) {
          case USER_NOT_FOUND:
            errorTitle = USER_NOT_FOUND_TITLE;
            errorDescription = USER_NOT_FOUND_DESCRIPTION(savedOrg.user.email || savedOrg.user.username || '');
            break;

          case USER_BLOCKED:
            errorTitle = USER_BLOCKED_TITLE;
            errorDescription = USER_BLOCKED_DESCRIPTION(savedOrg.organization.name);
            break;

          case NO_SUPPORTED_LOGIN_METHODS:
            errorTitle = NO_SUPPORTED_LOGIN_METHODS_TITLE;
            errorDescription = NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION;
            break;

          case TOO_MANY_CHALLENGES:
            errorTitle = 'Too many verification codes sent';
            errorDescription = 'Please try again later.';
            buttons = [
              {
                type: 'secondary',
                buttonLabel: 'Contact Hypercare Support',
                onClickHandler: () => {
                  window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                  setErrorModalOpenStatus(false);
                },
                id: 'close-btn',
              },
            ];
            break;

          default:
            errorTitle = UNKNOWN_LOGIN_ACCOUNT_ERROR;
            errorDescription = CONTACT_ADMIN_FOR_HELP;
            break;
        }

        setErrorModalDetails({
          title: errorTitle,
          description: errorDescription,
        });

        if (buttons && buttons?.length >= 1) {
          setErrorModalButtons(buttons);
        }

        setErrorModalOpenStatus(true);
      }
      if (res && 'auth0Id' in res) {
        loginWithAuth0(res.auth0Id);
      }

      switch (res && 'screen' in res && res?.screen) {
        case OrgLoginMethods.OTP:
          res && 'challengeId' in res && res?.challengeId && setCurrentChallengeId(res?.challengeId);
          setCurrentFlow(OrgViews.ACCOUNT_SELECTION);
          showOtpView(res && 'isMethodSwitchable' in res && res?.isMethodSwitchable);
          setEmail(savedOrg.user.email || savedOrg.user.username || '');
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
          return;
        case OrgLoginMethods.PASSWORD:
          setEmail(savedOrg.user.email || savedOrg.user.username || '');
          showPasswordView(res && 'isMethodSwitchable' in res && res?.isMethodSwitchable);
          callNative(WebViewCallBacks.ON_WEB_VIEW_READY, {screen: OrgViews.ACCOUNT_SELECTION});
          return;
        default:
          break;
      }
    } else {
      const res = await STALogin(savedOrg.organization, savedOrg, savedOrg.user.email || savedOrg.user.username || '');

      setSavedAccountsData(currentCacheData?.savedOrganizations || []);
    }
    setLoginToAccountLoader(false);
  };

  const handleSuggestedOrganizationClick = async (suggestedOrganization: DomainOrganization) => {
    setLoginToAccountLoader(true);
    const res = await suggestedOrganizationClick(suggestedOrganization);

    if (res && 'screen' in res) {
      await handleSubmitEmailForSuggestedOrg(res?.email || '', suggestedOrganization);
    }

    if (res && 'error' in res) {
      let errorTitle = '';
      let errorDescription = '';

      switch (res.error) {
        case USER_BLOCKED:
          errorTitle = USER_NOT_FOUND_TITLE;
          errorDescription = USER_BLOCKED_DESCRIPTION(suggestedOrganization.name);
          break;

        default:
          errorTitle = 'Error has occurred when trying to join suggested org';
          errorDescription = CONTACT_ADMIN_FOR_HELP;
      }
      setErrorModalOpenStatus(true);
      setErrorModalDetails({title: errorTitle, description: errorDescription});
    }

    setLoginToAccountLoader(false);
  };

  const handleLogoutAndRemove = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(AUTH_INFO);
    localStorageService.removeItem(FCM_SECRETS);
    setSavedAccountsData([]);
    window.location.assign(`/`);
    setShowLogoutOrContinueModal(false);
    toast.info(`Successfully logged out from all accounts`);
  };

  const getCountFromUnreadList = (userId: string): string | null => {
    if (!unreadCount) return null;
    const target = unreadCount[userId];
    return target ? (target > 99 ? '99+' : `${target}`) : null;
  };

  return (
    <>
      <LoginContainer loading={loginToAccountLoader ? 'true' : undefined} width={'550px'}>
        {loginToAccountLoader && (
          <ProgressContainer>
            <LinearProgress color="secondary" />
          </ProgressContainer>
        )}
        {isEditMode ? (
          <AccountSelectionEditModeTitle title={'Manage accounts'} onClick={() => setIsEditMode(false)} />
        ) : (
          <AccountSelectionTitle handleSwitchRegion={handleSwitchRegion} currentAuthRegion={currentRegion} />
        )}
        {dynamicMaintenanceLoginSignupBanner?.currentMaintenanceRegions?.includes(currentAuthRegion) ? (
          <LoginDisabledBanner
            title={dynamicMaintenanceLoginSignupBanner?.login?.messages?.[0]?.title}
            description={formatMessage(
              dynamicMaintenanceLoginSignupBanner?.login?.messages?.[0]?.body,
              dynamicMaintenanceLoginSignupBanner?.login?.messages?.[0]?.parameters,
              dynamicMaintenanceLoginSignupBanner?.login?.styles?.textColor,
            )}
          />
        ) : (
          <>
            <RowItemContainer>
              {savedAccountsData?.map((savedOrg) => (
                <SavedAccountOrganizationRowItems
                  key={savedOrg.user.id}
                  user={savedOrg.user}
                  organization={savedOrg.organization}
                  isSignedIn={checkIfUserIsSignedIn(savedOrg)}
                  isEditMode={isEditMode}
                  handleRowClick={() => handleLoginToAccount(savedOrg)}
                  isCurrentActiveAccount={() => {
                    const loggedInAccount = getCurrentLoggedInAccount();
                    return loggedInAccount?.user.id === savedOrg.user.id || false;
                  }}
                  rightSideContent={
                    isEditMode ? (
                      <RowItemsRightSide onClick={() => handleOpenRemoveAccountModal(savedOrg)}>
                        <CloseButtonV2 />
                      </RowItemsRightSide>
                    ) : unreadCount && unreadCount[savedOrg.user.id] ? (
                      <RowItemsRightSide>
                        <UnreadChatCountBubble>{getCountFromUnreadList(savedOrg.user.id)}</UnreadChatCountBubble>
                      </RowItemsRightSide>
                    ) : (
                      <></>
                    )
                  }
                />
              ))}
            </RowItemContainer>
            <SavedAccountActionButtons
              onIconClick={() => setShowLogoutOrContinueModal(true)}
              icon={<AddButtonV2 />}
              description={
                <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                  {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
                </HCLabelOne>
              }
            />
            {isEditMode ? (
              <SavedAccountActionButtons
                icon={<CloseButtonV2 />}
                onIconClick={handleOpenLogoutAllAccountsModal}
                description={
                  <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                    {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
                  </HCLabelOne>
                }
              />
            ) : (
              <SavedAccountActionButtons
                icon={<GearIcon />}
                onIconClick={() => setIsEditMode(true)}
                description={
                  <HCLabelOne color={muiTheme.colors.text} lineHeight={'24px'}>
                    {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
                  </HCLabelOne>
                }
              />
            )}
          </>
        )}
        {showLogoutAllAccountsModal && (
          <LogoutAllAccountsModal
            isOpen={showLogoutAllAccountsModal}
            setIsOpen={setShowLogoutAllAccountsModal}
            handleOnClick={handleRemoveAllAccountsOnDevice}
          />
        )}
        {showLogoutAccountModal && (
          <AccountLogoutModal
            title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname || ''} (${
              currentSelectedAcc?.organization.name
            })`}
            subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
            isOpen={showLogoutAccountModal}
            setIsOpen={setShowLogoutAccountModal}
            modalButtons={[
              {
                type: 'primary',
                buttonLabel: 'Logout & Remove',
                onClickHandler: handleRemoveAccount,
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Logout',
                onClickHandler: handleLogoutOfAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
        {showRemoveAccountModal && (
          <AccountLogoutModal
            title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name}) from browser?`}
            subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
            isOpen={showRemoveAccountModal}
            setIsOpen={setShowRemoveAccountModal}
            modalButtons={[
              {
                type: 'secondary',
                buttonLabel: 'Cancel',
                onClickHandler: () => setShowRemoveAccountModal(false),
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Remove',
                onClickHandler: handleRemoveAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
      </LoginContainer>
      {suggestedOrganizations.length > 0 && (
        <SuggestedOrganizationsList
          organizations={suggestedOrganizations}
          handleSuggestedOrganizationClick={handleSuggestedOrganizationClick}
        />
      )}
      {showLogoutOrContinueModal && (
        <LogoutOrContinueModal
          open={showLogoutOrContinueModal}
          onRequestClose={() => setShowLogoutOrContinueModal(false)}
          onHandleContinue={() => handleNextStep()}
          onHandleLogoutAndRemove={() => handleLogoutAndRemove()}
        />
      )}
    </>
  );
};
