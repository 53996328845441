import styled from '@emotion/styled';

export const StyledAnchor = styled.a<{isEditMode: boolean}>`
  cursor: pointer;
  text-decoration: none;
  pointer-events: ${(props) => (props.isEditMode ? 'none' : '')};
  &:hover {
    text-decoration: ${(props) => (props.isEditMode ? 'none' : 'underline')};
  }
`;

export const ErrorMessage = styled.p`
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Nunito Sans', serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: ${(props) => props.theme.colors.errorRed};
`;
