import {PageBody, PageContainer} from 'src/styles/styled-components/CommonPage';
import Helmet from 'react-helmet';
import {AuthContext} from 'src/auth/AuthProvider';
import Header from 'src/pages/MessengerPage/messenger/PageHeader';
import styled from '@emotion/styled';
import InvitationSearchLayout from 'src/pages/ContactsPage/InvitationSearchLayout';
import PageBanner from 'src/components/PageBanner';
import {useParams} from 'react-router-dom';
import {ProfileLayoutContainer} from '../../LocatingPage/locating/ProfileLayoutContainer';
import InvitationSearchLayoutV2 from '../InvitationSearchLayout/InvitationSearchLayoutV2';
import {IsFeatureFlagEnabled} from 'src/utils/FeatureFlagManager';
import {FeatureFlagResult} from 'src/utils/FeatureFlags';
import InvitationSearchLayoutSTA from '../InvitationSearchLayout/sta/InvitationSearchLayoutSTA';
import {isSTAFeatureFlagEnabled} from '../../../utils/sta/staUtils';

const SearchPanelWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  width: 33vw;
  min-width: 440px;
  max-height: calc(100vh - 60px);
  overflow: hidden;
  border-right: 1px solid ${(props) => props.theme.colors.borderColor};
  .MuiList-padding {
    padding: 0;
  }
`;

interface ContactRoutingParams {
  userId?: string;
}
const InvitationPage = () => {
  let params = useParams() as ContactRoutingParams;
  const paginatedContactsFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.paginatedContacts);
  const STAFlag = isSTAFeatureFlagEnabled();

  if (paginatedContactsFeatureFlag === undefined) {
    return null;
  }

  let InvitationSearchLayoutComponent;

  if (STAFlag) {
    InvitationSearchLayoutComponent = InvitationSearchLayoutSTA;
  } else if (paginatedContactsFeatureFlag) {
    InvitationSearchLayoutComponent = InvitationSearchLayoutV2;
  } else {
    InvitationSearchLayoutComponent = InvitationSearchLayout;
  }

  return (
    <PageBody>
      <SearchPanelWrapper>
        <InvitationSearchLayoutComponent />
      </SearchPanelWrapper>
      {params.userId && <ProfileLayoutContainer />}
    </PageBody>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({authInfo, logout, authRegion}) => (
      <PageContainer>
        <Helmet>
          <title>Contacts - Hypercare</title>
        </Helmet>
        <Header user={authInfo.user} logout={logout} authRegion={authRegion} />
        <PageBanner />
        <InvitationPage />
      </PageContainer>
    )}
  </AuthContext.Consumer>
);
