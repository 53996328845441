import {useEffect} from 'react';
import {LDClient} from 'launchdarkly-js-client-sdk';
import {isSTAFeatureFlagEnabled} from '../utils/sta/staUtils';

interface FeatureFlagSettings {
  [key: string]: {
    current: boolean;
    previous: boolean;
  };
}

const SINGLE_TENANT_FLAGS = ['single-tenant-account-flag', 'single-tenant-account-flag-us'];

export const useFeatureFlagReload = (ldClient: LDClient | undefined) => {
  const isSTAFlagEnabled = isSTAFeatureFlagEnabled();

  window.localStorage.setItem('staFlag', JSON.stringify(isSTAFlagEnabled));

  useEffect(() => {
    if (!ldClient) return;

    const handleFlagChange = (settings: FeatureFlagSettings) => {
      const staFlag = SINGLE_TENANT_FLAGS.map((flagKey) => settings[flagKey]).find((flag) => flag);

      if (staFlag) {
        try {
          window.localStorage.setItem('staFlag', JSON.stringify(staFlag.current));
          window.localStorage.removeItem('authInfo');
          window.localStorage.removeItem('organizationAccountsData');
          window.location.reload();
        } catch (error) {
          console.error('Error handling feature flag reload:', error);
        }
      }
    };

    ldClient.on('change', handleFlagChange);

    return () => {
      ldClient.off('change', handleFlagChange);
    };
  }, [ldClient]);
};
