import gql from 'graphql-tag';

export default gql`
  query GetSSODomainForUsername($email: String!) {
    ssoProfileForUser(email: $email) {
      organization {
        id
        name
      }
      auth0Id
      domain
      ssoId
      ssoVendor
    }
  }
`;
