import React from 'react';
import * as Yup from 'yup';
import {CurrentSelectedOrganization} from '../types/login.types';
import {IVerifyEmailStepOutputData} from '../../pages/newSignupFlow/HypercareSignupVerifyEmailAddress/HypercareSignupVerifyAddressView';
import NewVerificationCodeInput from '../../pages/newSignupFlow/components/NewVerificationCodeInput';
import {CircularProgress, LinearProgress} from '@mui/material';
import {Formik} from 'formik';
import {StyledInner, StyledStepContainer} from '../styled/signup.styled';
import {LoginHeader} from './LoginHeader';
import {HelperBox, InputsWrapper, StyledSubmitAddressButton} from '../styled/login.styled';
import {HCTextSpanContextThree} from '../../components/HypercareComponents';
import {LOGIN_WITH_PASSWORD} from '../../constants/strings';
import {ProgressContainer} from '../../pages/LoginPage/styled/login.styled';

interface ILoginOTPViewProps {
  shouldHideBackButton: boolean;
  handleGoBack: () => void;
  handleNext: (code: string) => void;
  organization?: CurrentSelectedOrganization;
  resend: any;
  isResending: boolean;
  error: string;
  currentSelectedEmail: string;
  isLoading: boolean;
  isLoginMethodSwitchable?: boolean;
  handleIsLoginMethodSwitchableClick?: (type: string) => Promise<void>;
}

const validationSchema = Yup.object().shape<IVerifyEmailStepOutputData>({
  code: Yup.string()
    .required('Verification code is required')
    .test('empty', 'Verification code is required', (value: string) => !!value && value.replace(/ /g, '').length > 0)
    .test(
      'len',
      'Verification code should be 6 digit',
      (value: string) => !!value && value.replace(/ /g, '').length === 6,
    ),
});

export const LoginOTPView = ({
  handleGoBack,
  handleNext,
  organization,
  resend,
  isResending,
  error,
  currentSelectedEmail,
  isLoading,
  isLoginMethodSwitchable,
  handleIsLoginMethodSwitchableClick,
  shouldHideBackButton,
}: ILoginOTPViewProps) => {
  return (
    <Formik
      initialValues={{code: ''}}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        handleNext(values.code);
      }}
    >
      {({handleSubmit, values, isSubmitting, setFieldValue, touched, errors}) => (
        <React.Fragment>
          <form onSubmit={handleSubmit} style={{width: '100%', maxWidth: '600px'}}>
            <StyledStepContainer loading={isLoading ? 'true' : undefined}>
              {isLoading && (
                <ProgressContainer>
                  <LinearProgress color="secondary" />
                </ProgressContainer>
              )}
              <StyledInner>
                <LoginHeader
                  title={'Check your email for a code'}
                  description={`Enter the code sent to ${currentSelectedEmail}`}
                  onBackButtonClick={handleGoBack}
                  headerTitle={
                    <>
                      {organization?.imageURL ? (
                        <img width={50} height={50} src={organization?.imageURL} alt={organization?.name + 'logo'} />
                      ) : (
                        <>{organization?.name}</>
                      )}
                    </>
                  }
                  hideBackButton={shouldHideBackButton}
                />
                <InputsWrapper>
                  <NewVerificationCodeInput
                    length={6}
                    size="sm"
                    value={values.code}
                    disabled={isSubmitting || isResending}
                    onChange={(value) => setFieldValue('code', value)}
                    handleResend={() => resend(currentSelectedEmail)}
                    renderError={!!error && <div style={{color: 'red'}}>{error}</div>}
                  />
                </InputsWrapper>
                <div>
                  <HelperBox>
                    {isLoginMethodSwitchable && (
                      <HCTextSpanContextThree
                        style={{color: '#00859A', cursor: 'pointer'}}
                        onClick={() => handleIsLoginMethodSwitchableClick && handleIsLoginMethodSwitchableClick('otp')}
                        padding={'4px'}
                      >
                        {LOGIN_WITH_PASSWORD}
                      </HCTextSpanContextThree>
                    )}
                  </HelperBox>
                </div>
                <StyledSubmitAddressButton
                  data-testid="submitButton"
                  type="submit"
                  disabled={isLoading || !values['code']}
                >
                  {isLoading && (
                    <>
                      <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> {'Submitting OTP'}
                    </>
                  )}
                  {!isLoading && 'Log in'}
                </StyledSubmitAddressButton>
              </StyledInner>
            </StyledStepContainer>
          </form>
        </React.Fragment>
      )}
    </Formik>
  );
};
