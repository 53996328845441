import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as R from 'ramda';
import client from 'src/apollo';
import styled from '@emotion/styled';
import throttle from 'lodash.throttle';
import {connect} from 'react-redux';
import {SmallLoader} from 'src/components/LoadingDiv';
import ChatItem from 'src/pages/MessengerPage/messenger/chat-list-layout/ChatItem';
import GetChatsQuery from 'src/gql/query/GetChatsQuery';
import GetArchiveChatsQuery from 'src/gql/query/GetArchiveChatsQuery';
import GetSearchChatTitleQuery from 'src/gql/query/GetSearchChatTitleQuery';
import GetSearchChatMember from 'src/gql/query/GetSearchChatMember';
import GetSearchChatUsersQuery from 'src/gql/query/GetSearchChatUsersQuery';
import GetSearchMessageQuery from 'src/gql/query/GetSearchMessageQuery';
import sortChatsByDate from 'src/utils/messengerHelper/sortChatsByDate';
import {AuthContext} from 'src/auth';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {Chat, AuthPayload, RootState, User} from 'src/types';
import Helmet from 'react-helmet';
import CustomHelmet from 'src/components/Helmet';
import cleanUnreadPriorityMessages from 'src/utils/messengerHelper/cleanUnreadPriorityMessages';
import {ARCHIVED} from 'src/constants/routerPathName';
import {DEBOUNCE_TIME, LOAD_MORE_LIMIT, StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import {StyledFormControl, StyledInputBase} from 'src/components/StepFormPartials';
import SearchIcon from 'src/svgs/SearchIcon';
import debounce from 'lodash/debounce';
import SearchTabItem from 'src/pages/MessengerPage/messenger/search-chat-list-layout/SearchTabItem';
import {toast} from 'react-toastify';
import {USERS, GROUPS, MESSAGES} from 'src/constants/user';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {MESSENGER} from 'src/constants/routerPathName';
import SearchUsersList from 'src/pages/MessengerPage/messenger/search-chat-list-layout/SearchUsersList';
import SearchListContainer from 'src/pages/MessengerPage/messenger/SearchListConatiner';
import {EMPTY_SEARCH_QUERY} from 'src/constants/networkError';
import CloseIcon from 'src/svgs/CloseIcon';
import {formatSearchPayload, handleSearchMixpanel} from 'src/utils/messengerHelper/searchChat';
import {ActionEvents, openChatListSearchFieldKeys} from 'src/constants/hotkeyEvents';
import ChatViewModel from './repository/ChatViewModel';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

const FetchMoreLoadingWrapper = styled.div`
  height: 40px;
  padding: 5px;
`;

const ChatListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const PrioritiesText = styled.div`
  color: ${(props) => props.theme.colors.greyishBrown};
  padding: 16px 0 8px 16px;
  font-size: 14px;
  font-weight: 400;
`;

const EmptyArchiveMessageContainer = styled.div`
  padding: 20px;
  & > h2 {
    margin-bottom: 10px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 1em;
  height: 1em;
`;

const StyledInput = styled(StyledInputBase)`
  .MuiInputBase-input {
    background: #f6f6f9;
    border: 0.5px solid #dbdbdb;
    border-radius: 4px;
    padding-left: 40px;
    :placeholder-shown {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-right: 12px;
      color: #bcbcbc;
    }
  }
`;

const StyledFormContainer = styled(StyledFormControl)`
  margin-top: 12px 16px 12px 16px !important;
`;

const SearchBardEndAdornment = styled.div`
  display: inherit;
  position: absolute;
  right: 1em;
  border-radius: 8px;
  opacity: 1;
  transition: 0.2s;
`;

const CloseIconButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  fetchMore: any;
  continuationId: string;
  chats: Chat[];
  authInfo: AuthPayload;
  isFetchMoreLoading: boolean;
  priorityMessagesRead: {
    [key: string]: number[];
  };
  isSearch: boolean;
  handleIsSearch: (isVisible: boolean) => void;
  searchThroughChat: boolean;
  handleJumpToMessage: (messageId: string | null) => void;
  isJumpToMessageEnable: boolean;
  flags: {[key: string]: boolean};
}

interface State {
  hasMore: boolean;
  currentTab: string;
  searchText: string;
  tabValue: number;
  viewAllType: string;
  activeChat: any;
  searchUserData: any;
  searchAllUserData: any;
  searchGroupData: any;
  searchAllGroupData: any;
  searchMessageData: any;
  searchSubMessageData: any;
  isLoadSearching: boolean;
  totalUsersCount: number;
  totalGroupsCount: number;
  totalMessageCount: number;
  isShowViewAll: boolean;
  isBack: boolean;
  isFetchMoreMessages: boolean;
  selectedChat: any;
  selectedChatTitle: string;
  lastUserContinuationId: any;
  lastGroupTitleContinuationId: any;
  lastGroupChatMemberContinuationId: any;
  lastMessageContinuationId: any;
  lastMessageIdList: (number | undefined)[];
}

const partitionByPriority = R.partition(
  (chat: Chat) =>
    chat.unreadPriorityMessages.length > 0 &&
    [StatMessagePriority, UrgentMessagePriority].includes(chat.lastMessage?.priorityType ?? ''),
);

class ChatList extends React.PureComponent<Props, State> {
  public state = {
    hasMore: true,
    currentTab: USERS,
    searchText: '',
    tabValue: 0,
    viewAllType: '',
    activeChat: null,
    searchUserData: [],
    searchAllUserData: [],
    searchGroupData: [],
    searchAllGroupData: [],
    searchMessageData: [],
    searchSubMessageData: [],
    isLoadSearching: false,
    totalUsersCount: 0,
    totalGroupsCount: 0,
    totalMessageCount: 0,
    isShowViewAll: false,
    isBack: false,
    isFetchMoreMessages: false,
    selectedChat: null,
    selectedChatTitle: '',
    lastUserContinuationId: null,
    lastGroupTitleContinuationId: null,
    lastGroupChatMemberContinuationId: null,
    lastMessageContinuationId: null,
    lastMessageIdList: [],
  };

  private scrollRef: React.RefObject<HTMLDivElement> = React.createRef();
  private keysHeldDown = {};

  private primaryKey = 'g';
  private secondaryKey = 'Shift';
  private tertiaryKey = 'f';

  public constructor(props) {
    super(props);
    this.fetchMore = throttle(this.fetchMore, 550, {trailing: false});
  }

  public componentDidMount() {
    const isArchive = window.location.href.includes(ARCHIVED);
    AnalyticsManager.recordPageVisited(isArchive ? PAGE_VIEWS.archivedChatList : PAGE_VIEWS.chatList);

    const {handleMessageDelivered} = ChatViewModel();

    document.addEventListener('keydown', this.enterKeyListener);
    document.addEventListener('keyup', this.exitKeyListener);

    if (!this.isSaturated() && this.props.chats.length >= 25) {
      this.fetchMore();
    }

    const {chats} = this.sortedChats();
    const {authInfo, flags} = this.props;
    const user = authInfo?.user;

    if (flags.optimizeWebApp) {
      const newLastMessageIds = chats
        .filter((chat) => chat.lastMessage?.readBy.every((receipt) => receipt.user.id !== user.id))
        .map((chat) => chat.lastMessage?.id);

      this.setState(
        (prevState) => ({
          lastMessageIdList: [...prevState.lastMessageIdList, ...newLastMessageIds],
        }),
        () => {
          if (this.state.lastMessageIdList.length >= 0) {
            handleMessageDelivered(this.state.lastMessageIdList);
          }
        },
      );
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.enterKeyListener);
    document.removeEventListener('keyup', this.exitKeyListener);
  }

  public componentDidUpdate() {
    if (this.props.isSearch) {
      this.setState({
        searchText: '',
        currentTab: USERS,
        tabValue: 0,
      });
    }

    if (!this.props.searchThroughChat) {
      this.handleSearchClear();
      this.setState({
        currentTab: USERS,
        tabValue: 0,
      });
    }
  }

  public render() {
    const {authInfo, isFetchMoreLoading, searchThroughChat, handleJumpToMessage, isJumpToMessageEnable} = this.props;
    const {
      hasMore,
      searchText,
      tabValue,
      viewAllType,
      activeChat,
      isLoadSearching,
      searchUserData,
      searchAllUserData,
      searchSubMessageData,
      searchGroupData,
      searchAllGroupData,
      searchMessageData,
      totalUsersCount,
      totalGroupsCount,
      totalMessageCount,
      isShowViewAll,
      isBack,
      isFetchMoreMessages,
      selectedChat,
      selectedChatTitle,
    } = this.state;
    const {priorityChats, chats} = this.sortedChats();
    const isArchive = window.location.href.includes(ARCHIVED);

    if (isArchive && priorityChats.length === 0 && chats.length === 0) {
      return (
        <EmptyArchiveMessageContainer>
          <h2>No Archived Chats Yet</h2>
          <span>Hover on chat list and click on the arrow to archive chats</span>
        </EmptyArchiveMessageContainer>
      );
    }

    return (
      <ChatListContainer id="chatListScrollContainer" ref={this.scrollRef}>
        {isArchive ? (
          <Helmet defer={false}>
            <title>Archived Chats - Hypercare</title>
          </Helmet>
        ) : (
          <CustomHelmet chats={chats} priorityChats={priorityChats} />
        )}
        {searchThroughChat && (
          <StyledFormContainer>
            <StyledInput
              id={'chatListSearchField'}
              value={searchText}
              type="text"
              placeholder={'Search chat titles, messages, users'}
              startAdornment={<StyledSearchIcon />}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onChange={(e) => this.handleChatSearch(e)}
              endAdornment={
                <SearchBardEndAdornment>
                  {searchText && (
                    <CloseIconButton onClick={() => this.handleSearchClear()}>
                      <CloseIcon color="#4A4A4A" width="15" height="15" />
                    </CloseIconButton>
                  )}
                </SearchBardEndAdornment>
              }
            />
          </StyledFormContainer>
        )}
        {searchThroughChat && searchText ? (
          searchUserData || searchGroupData || searchMessageData ? (
            <InfiniteScroll
              hasMore={hasMore}
              initialLoad={false}
              loadMore={this.loadMoreSearchMessages}
              useWindow={false}
              threshold={50}
            >
              {isShowViewAll ? (
                <SearchUsersList
                  chats={viewAllType === USERS ? searchAllUserData : searchAllGroupData}
                  title={viewAllType}
                  activeChat={activeChat}
                  createChat={this.createChat}
                  handleViewAllBack={this.handleViewAllBack}
                  handleSetActiveChat={this.handleSetActiveChat}
                />
              ) : (
                <SearchTabItem
                  searchUserData={searchUserData}
                  searchGroupData={searchGroupData}
                  searchMessageData={searchMessageData}
                  searchSubMessageData={searchSubMessageData}
                  fetchMessageList={this.fetchMessageList}
                  handleTabSwitch={this.handleTabSwitch}
                  handleShowMatchedMessage={this.handleShowMatchedMessage}
                  isSearching={isLoadSearching}
                  handleJumpToMessage={handleJumpToMessage}
                  handleViewAll={this.handleViewAll}
                  totalUsersCount={totalUsersCount}
                  totalGroupsCount={totalGroupsCount}
                  totalMessageCount={totalMessageCount}
                  isShowViewAll={isShowViewAll}
                  activeChat={activeChat}
                  isBack={isBack}
                  setIsBack={this.setIsBack}
                  tabValue={tabValue}
                  handleChange={this.handleChange}
                  handleChangeIndex={this.handleChangeIndex}
                  selectedChat={selectedChat}
                  setSelectedChat={this.setSelectedChat}
                  selectedChatTitle={selectedChatTitle}
                  setSelectedChatTitle={this.setSelectedChatTitle}
                  handleSetActiveChat={this.handleSetActiveChat}
                  createChat={this.createChat}
                  isJumpToMessageEnable={isJumpToMessageEnable}
                />
              )}

              {isFetchMoreMessages && (
                <FetchMoreLoadingWrapper>
                  <SmallLoader />
                </FetchMoreLoadingWrapper>
              )}
            </InfiniteScroll>
          ) : (
            <SearchListContainer searchText={searchText} />
          )
        ) : (
          <InfiniteScroll
            hasMore={hasMore}
            initialLoad={false}
            loadMore={this.loadMore}
            useWindow={false}
            threshold={80}
          >
            {priorityChats.length > 0 && (
              <>
                <PrioritiesText>PRIORITY</PrioritiesText>
                {priorityChats.map((chat, i) => {
                  return <ChatItem authInfo={authInfo} key={`prior-${i}` + chat.id} chat={chat} />;
                })}
              </>
            )}
            <PrioritiesText>GENERAL</PrioritiesText>
            {chats.map((chat, i) => (
              <ChatItem authInfo={authInfo} key={`${i}-` + chat.id} chat={chat} />
            ))}
            {isFetchMoreLoading && (
              <FetchMoreLoadingWrapper>
                <SmallLoader />
              </FetchMoreLoadingWrapper>
            )}
          </InfiniteScroll>
        )}
      </ChatListContainer>
    );
  }

  private exitKeyListener = (e: KeyboardEvent) => {
    const currentKeyPressed = e.key?.toLocaleLowerCase();

    if (currentKeyPressed === this.primaryKey || currentKeyPressed === this.secondaryKey || e.key === this.tertiaryKey)
      this.clearKeysHeldDown();
  };

  private enterKeyListener = (e: KeyboardEvent) => {
    const searchInputElement = document.getElementById('chatListSearchField') as HTMLInputElement;
    const currentActiveElement = document.activeElement;

    if (currentActiveElement instanceof HTMLTextAreaElement || currentActiveElement instanceof HTMLInputElement)
      return null;

    if (e.key === this.primaryKey) this.keysHeldDown[e.key] = true;
    if (e.key === this.secondaryKey && this.keysHeldDown[this.primaryKey]) this.keysHeldDown[e.key] = true;

    if (
      e.key.toLocaleLowerCase() === this.tertiaryKey &&
      this.keysHeldDown[this.primaryKey] &&
      this.keysHeldDown[this.secondaryKey]
    )
      this.keysHeldDown[e.key.toLocaleLowerCase()] = true;

    if (
      this.keysHeldDown[this.primaryKey] &&
      this.keysHeldDown[this.secondaryKey] &&
      this.keysHeldDown[this.tertiaryKey]
    ) {
      e.preventDefault();
      searchInputElement.focus();
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.hotkeyActivated,
        params: {
          keys: openChatListSearchFieldKeys,
          action: ActionEvents.OPEN_CHAT_LIST_SEARCH_FIELD,
        },
      });
    }
  };

  private clearKeysHeldDown = () => {
    this.keysHeldDown = {};
  };

  private setSelectedChatTitle = (title) => {
    this.setState({selectedChatTitle: title});
  };

  private setSelectedChat = (chat) => {
    this.setState({selectedChat: chat});
  };

  private handleChange = (event, newValue) => {
    if (newValue) {
      this.handleTabSwitch(MESSAGES);
    } else {
      this.handleTabSwitch(USERS);
      this.props.handleJumpToMessage(null);
    }

    AnalyticsManager.applyAnalytics({
      eventName: newValue ? EVENTS.searchResultsMessagesTabPressed : EVENTS.searchResultsUserOrGroupTabPressed,
    });

    this.setState({
      tabValue: newValue,
      selectedChat: null,
      selectedChatTitle: '',
      isBack: false,
    });
  };

  private handleChangeIndex = (index) => {
    this.setState({tabValue: index});
  };

  private setIsBack = (action) => {
    this.setState({
      isBack: action,
    });
  };

  private handleSetActiveChat = (chatId) => {
    this.setState({activeChat: chatId});
  };

  private handleViewAllBack = (index) => {
    this.setState({tabValue: index, isShowViewAll: false});
  };

  private handleSearchClear = () => {
    this.setState({searchText: ''});
    this.setSelectedChat(null);
    this.setSelectedChatTitle('');
    this.setIsBack(false);
    this.props.handleJumpToMessage(null);
  };

  private handleViewAll = async (type) => {
    if (type === USERS) {
      this.setState({
        viewAllType: USERS,
        isShowViewAll: true,
      });
    }
    if (type === GROUPS) {
      this.setState({
        viewAllType: GROUPS,
        isShowViewAll: true,
      });
    }
  };

  private createChat = async (member: User) => {
    try {
      const newChatId = await createNewChat([member]);
      if (!newChatId) throw new Error('failed');

      window.routerHistory.push(`/${MESSENGER}/${newChatId}`);
    } catch (e) {
      console.error(e);
      toast.error('Failed to start new chat, please check your internet connection and try again');
    }
  };

  private handleChatSearch = (e) => {
    this.props.handleIsSearch(false);
    this.props.handleJumpToMessage(null);
    const searchValue = e.target.value;
    if (searchValue.length === 0) {
      this.setState({
        currentTab: this.state.tabValue === 0 ? USERS : MESSAGES,
      });
      this.props.handleJumpToMessage(null);
    }
    this.setState(
      {
        isShowViewAll: false,
        searchAllUserData: [],
        searchAllGroupData: [],
        searchUserData: [],
        searchGroupData: [],
        searchMessageData: [],
        searchText: searchValue,
        isBack: false,
        selectedChat: null,
        selectedChatTitle: '',
      },
      () => {
        if (this.state.searchText.length > 1) {
          this.onSearchChat();
        }
      },
    );
  };

  private onSearchChat = debounce(async () => {
    if (this.state.currentTab === USERS) {
      this.fetchUserGroupMessages();
    } else {
      this.fetchByMessages();
    }
  }, DEBOUNCE_TIME);

  private fetchUserGroupMessages = async () => {
    const {searchText} = this.state;
    const searchChatText = await formatSearchPayload(searchText);
    if (searchChatText.length > 1) {
      await this.fetchSearchMessages();
      await handleSearchMixpanel(searchChatText);
    }
  };

  private fetchByMessages = async () => {
    const {searchText} = this.state;
    const searchChatText = await formatSearchPayload(searchText);
    if (searchChatText.length > 1) {
      await this.fetchMessageList();
      await handleSearchMixpanel(searchChatText);
    }
  };

  private getSearchChatUsers = async (searchChatText: string, isViewAll) => {
    const {data: userMessages, loading: userLoading} = await this.fetchSearchRecords(
      searchChatText,
      GetSearchChatUsersQuery,
      USERS,
      isViewAll,
    );
    const {users: searchMessages, totalResultsCount, continuationId} = userMessages?.searchQuery?.searchUsers;

    this.setState({
      searchUserData: searchMessages,
      searchAllUserData: searchMessages,
      totalUsersCount: totalResultsCount,
      lastUserContinuationId: continuationId,
    });

    return userLoading;
  };

  private getSearchGroups = async (searchChatText: string, isViewAll) => {
    const {data: groupMessages, loading: groupLoading} = await this.fetchSearchRecords(
      searchChatText,
      GetSearchChatTitleQuery,
      GROUPS,
      isViewAll,
    );

    const {data: groupMessageMembers, loading: groupMembersLoading} = await this.fetchSearchRecords(
      searchChatText,
      GetSearchChatMember,
      GROUPS,
      isViewAll,
    );

    const searchChatTitles = groupMessages?.searchQuery?.searchChatTitles?.chats;
    const searchChatMembers = groupMessageMembers?.searchQuery?.searchChatMembers?.chats;
    const groupList = [...searchChatTitles, ...searchChatMembers];
    const uniqueIds = new Set();
    const unique = groupList.filter((element) => {
      const isDuplicate = uniqueIds.has(element.chat.id);
      uniqueIds.add(element.chat.id);
      if (!isDuplicate) {
        return true;
      }

      return false;
    });
    const groupUsersAllList = unique;
    const groupUsersList = groupUsersAllList.slice(0, LOAD_MORE_LIMIT);
    this.setState({
      searchAllGroupData: groupUsersList,
      searchGroupData: groupUsersList,
      totalGroupsCount: groupUsersAllList.length,
      lastGroupTitleContinuationId: groupMessages?.searchQuery?.searchChatTitles?.continuationId,
      lastGroupChatMemberContinuationId: groupMessageMembers?.searchQuery?.searchChatMembers?.continuationId,
    });

    return !groupLoading && !groupMembersLoading ? false : true;
  };

  private fetchSearchMessages = async () => {
    try {
      const {searchText} = this.state;
      const searchChatText = await formatSearchPayload(searchText);
      if (searchChatText.length > 1) {
        this.setState({isLoadSearching: true});
        const userLoading = await this.getSearchChatUsers(searchChatText, false);
        const groupLoading = await this.getSearchGroups(searchChatText, false);

        if (!userLoading && !groupLoading) {
          this.setState({isLoadSearching: false});
        }
      }
    } catch (e) {
      console.error(e);
      this.handleCatchError(e);
    }
  };

  private fetchMessageList = async () => {
    try {
      const {searchText} = this.state;
      const searchChatText = await formatSearchPayload(searchText);
      if (searchChatText.length > 1) {
        this.setState({isLoadSearching: true});
        const {data, loading} = await this.fetchSearchRecords(searchChatText, GetSearchMessageQuery, MESSAGES, false);

        if (!loading) this.setState({isLoadSearching: false});

        const {
          chats: searchChatMessage,
          totalResultsCount: totalMessages,
          continuationId,
        } = data?.searchQuery?.searchChats;

        this.setState({
          searchMessageData: searchChatMessage,
          totalMessageCount: totalMessages,
          lastMessageContinuationId: continuationId,
        });
      }
    } catch (e) {
      console.error(e);
      this.handleCatchError(e);
    }
  };

  private handleTabSwitch = (tab: string) => {
    this.setState(
      {
        currentTab: tab,
        isLoadSearching: tab === USERS ? true : false,
      },
      async () => {
        if (tab === USERS) {
          await this.fetchSearchMessages();
        }
        if (tab === MESSAGES) {
          await this.fetchMessageList();
        }
      },
    );
  };

  private handleShowMatchedMessage = (chats) => {
    try {
      this.setState({
        searchSubMessageData: chats,
      });
    } catch (e) {
      console.error(e);
      this.handleCatchError(e);
    }
  };

  private handleCatchError = (e) => {
    let errorMsg = 'Failed to search chat, please check your internet connection and try again';
    if (e.networkError?.result?.errors[0]) {
      let errorCodeName = e.networkError.result.errors[0].code;
      if (errorCodeName === EMPTY_SEARCH_QUERY) {
        errorMsg = e.networkError.result.errors[0].message;
      }
    }
    toast.error(errorMsg);
  };

  private handleKeyPress = (e: React.KeyboardEvent<any>) => {
    if (e.key === 'Enter' || e.keyCode === 13) this.onSearchChat();
  };

  private isSaturated = () => {
    if (this.scrollRef && this.scrollRef.current) {
      return this.scrollRef.current.scrollHeight > this.scrollRef.current.clientHeight;
    }
    return false;
  };

  private sortedChats = (): {priorityChats: Chat[]; chats: Chat[]} => {
    const {chats, priorityMessagesRead} = this.props;
    const cleanChats = chats.map((chat) => cleanUnreadPriorityMessages(chat, priorityMessagesRead[chat.id]));
    const [p, np] = partitionByPriority(cleanChats);

    return {
      priorityChats: p.sort(sortChatsByDate),
      chats: np.sort(sortChatsByDate),
    };
  };

  private fetchMore = () => {
    const {fetchMore, chats} = this.props;
    const lastChat = chats[chats.length - 1];
    const isArchive = window.location.href.includes(ARCHIVED);

    fetchMore({
      query: isArchive ? GetArchiveChatsQuery : GetChatsQuery,
      variables: {
        continuationId: lastChat && lastChat.id,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const chatsLens = R.lensPath([`${isArchive ? 'archived_chats' : 'chats'}`, 'chats']);
        const oldChats = R.view(chatsLens, previousResult);
        const newChats = R.view(chatsLens, fetchMoreResult);

        if (newChats && newChats.length === 0) {
          this.setState({
            hasMore: false,
          });
          return previousResult;
        }

        const resultChats = [...oldChats, ...newChats];

        const result = R.set(chatsLens, resultChats, previousResult);

        return result;
      },
    });
  };

  private loadMore = () => {
    this.fetchMore();
  };

  private loadMoreSearchMessages = async () => {
    const {currentTab, isBack, isShowViewAll, viewAllType} = this.state;
    if (currentTab === MESSAGES && isBack) {
      await this.fetchMoreIndividualMessages();
    }
    if (currentTab === MESSAGES && !isBack) {
      await this.fetchMoreMessages();
    }
    if (isShowViewAll && viewAllType === USERS && currentTab === USERS) {
      await this.fetchMoreUsersMessages();
    }
    if (isShowViewAll && viewAllType === GROUPS && currentTab === USERS) {
      await this.fetchMoreGrouplMessages();
    }
  };

  private filterByMessageId = (allMoreMessages, searchMessages) => {
    let updatedMessage = [];
    updatedMessage = allMoreMessages.filter((moreMessage) => {
      return !searchMessages.find((searchMessage) => {
        return searchMessage.message.id === moreMessage.message.id;
      });
    });
    return updatedMessage;
  };

  private filterByChatId = (allMoreMessages, searchMessages) => {
    let updatedMessage = [];
    updatedMessage = allMoreMessages.filter((moreMessage) => {
      return !searchMessages.find((searchMessage) => {
        return searchMessage.chat.id === moreMessage.chat.id;
      });
    });
    return updatedMessage;
  };

  private fetchMoreIndividualMessages = async () => {
    const {searchSubMessageData, searchText} = this.state;
    const searchChatText = await formatSearchPayload(searchText);
    const searchMessages = searchSubMessageData['searchMessages']['messages'];
    const chatId = searchSubMessageData['chat']['id'];
    const messageContinuationId = searchSubMessageData['searchMessages']['continuationId'];
    if (messageContinuationId) {
      this.setState({
        isFetchMoreMessages: true,
      });
      const {data} = await client.query({
        query: GetSearchMessageQuery,
        variables: {
          text: searchChatText,
          messageContinuationId: messageContinuationId,
        },
        fetchPolicy: 'no-cache',
      });

      const searchChatMessage = data?.searchQuery?.searchChats?.chats;
      const modifyList = searchChatMessage.find((member) => member.chat.id === chatId);
      const allMoreMessages = modifyList['searchMessages']['messages'];
      const moreMessages = await this.filterByMessageId(allMoreMessages, searchMessages);
      modifyList.searchMessages.messages = [...searchMessages, ...moreMessages];
      this.setState({
        searchSubMessageData: modifyList,
        isFetchMoreMessages: false,
      });
    }
  };

  private fetchMoreMessages = async () => {
    const {lastMessageContinuationId, searchMessageData, searchText} = this.state;
    const searchChatText = await formatSearchPayload(searchText);
    if (lastMessageContinuationId) {
      this.setState({
        isFetchMoreMessages: true,
      });
      const {data} = await client.query({
        query: GetSearchMessageQuery,
        variables: {
          text: searchChatText,
          chatContinuationId: lastMessageContinuationId,
        },
        fetchPolicy: 'no-cache',
      });
      const {chats: searchChatMessage, continuationId} = data?.searchQuery?.searchChats;
      const updatedMessageList = [...searchMessageData, ...searchChatMessage];
      const uniqueIds = new Set();
      const updatedMessages = updatedMessageList.filter((element) => {
        const isDuplicate = uniqueIds.has(element.chat.id);
        uniqueIds.add(element.chat.id);
        if (!isDuplicate) {
          return true;
        }

        return false;
      });
      this.setState({
        searchMessageData: updatedMessages,
        lastMessageContinuationId: continuationId,
        isFetchMoreMessages: false,
      });
    }
  };

  private fetchMoreUsersMessages = async () => {
    const {lastUserContinuationId, searchUserData, searchText} = this.state;
    const searchChatText = await formatSearchPayload(searchText);
    if (lastUserContinuationId) {
      this.setState({
        isFetchMoreMessages: true,
      });
      const {data: userMessages} = await client.query({
        query: GetSearchChatUsersQuery,
        variables: {
          text: searchChatText,
          continuationId: lastUserContinuationId,
        },
        fetchPolicy: 'no-cache',
      });
      const {users: searchMessages, continuationId} = userMessages?.searchQuery?.searchUsers;
      const updatedUserList = [...searchUserData, ...searchMessages];
      const uniqueIds = new Set();
      const updatedMessages = updatedUserList.filter((element) => {
        const isDuplicate = uniqueIds.has(element.user.id);
        uniqueIds.add(element.user.id);
        if (!isDuplicate) {
          return true;
        }

        return false;
      });

      this.setState({
        searchAllUserData: updatedMessages,
        lastUserContinuationId: continuationId,
        isFetchMoreMessages: false,
      });
    }
  };

  private fetchMoreGrouplMessages = async () => {
    const {lastGroupTitleContinuationId, lastGroupChatMemberContinuationId, searchAllGroupData, searchText} =
      this.state;
    const searchChatText = await formatSearchPayload(searchText);
    let chatTitleMessages = [];
    let chatTitleContinuationId = null;
    let chatMemberMessages = [];
    let chatMemberContinuationId = null;
    if (lastGroupTitleContinuationId) {
      this.setState({
        isFetchMoreMessages: true,
      });
      const {data: groupMessages} = await client.query({
        query: GetSearchChatTitleQuery,
        variables: {
          text: searchChatText,
          chatContinuationId: lastGroupTitleContinuationId,
        },
      });
      chatTitleMessages = groupMessages?.searchQuery?.searchChatTitles?.chats;
      chatTitleContinuationId = groupMessages?.searchQuery?.searchChatTitles?.continuationId;
      const moreMessages = this.filterByChatId(chatTitleMessages, searchAllGroupData);

      this.setState({
        searchAllGroupData: [...searchAllGroupData, ...moreMessages],
        lastGroupTitleContinuationId: chatTitleContinuationId,
        isFetchMoreMessages: false,
      });
    }
    if (lastGroupChatMemberContinuationId) {
      const {data: groupMessageMembers} = await client.query({
        query: GetSearchChatMember,
        variables: {
          text: searchChatText,
          chatContinuationId: lastGroupChatMemberContinuationId,
        },
      });
      chatMemberMessages = groupMessageMembers?.searchQuery?.searchChatMembers?.chats;
      chatMemberContinuationId = groupMessageMembers?.searchQuery?.searchChatMembers?.continuationId;

      const moreMessages = this.filterByChatId(chatMemberMessages, searchAllGroupData);

      this.setState({
        searchAllGroupData: [...searchAllGroupData, ...moreMessages],
        lastGroupChatMemberContinuationId: chatMemberContinuationId,
        isFetchMoreMessages: false,
      });
    }
  };

  private fetchSearchRecords = async (searchChatText, messageQuery, type: string | null = null, isViewAll) => {
    let variables = {
      text: searchChatText,
    };
    if (type && type !== MESSAGES && !isViewAll) variables['limit'] = LOAD_MORE_LIMIT;
    if (type && type === MESSAGES && !this.state.isBack) variables['chatLimit'] = LOAD_MORE_LIMIT;

    const response = await client.query({
      query: messageQuery,
      variables,
      fetchPolicy: 'no-cache',
    });

    return response;
  };
}

const mapStateToProps = (state: RootState) => ({
  priorityMessagesRead: state.messages.priorityMessagesRead,
});

const ChatListwithLDConsumer = withLDConsumer()(ChatList);
const ConnectedChatList = connect(mapStateToProps)(ChatListwithLDConsumer);

export default (props) => (
  <AuthContext.Consumer>{({authInfo}) => <ConnectedChatList {...props} authInfo={authInfo} />}</AuthContext.Consumer>
);
