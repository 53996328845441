import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import {StyledModal, Header, CloseBtn} from 'src/styles/styled-components/ModalStyleComponents';
import {useFormik} from 'formik';
import {AddressType, UserAddress} from 'src/types';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
import {profileFormContactInfoValidationSchema} from 'src/utils/validations';
import ArrowLeft from 'src/svgs/ArrowLeft';
import CircularProgress from '@mui/material/CircularProgress';
import {
  AddAddressStepZero,
  AddAddressStepOne,
  AddAddressStepTwo,
} from 'src/pages/LocatingPage/locating/profile-section/add-address-steps';
import ProfileAddAddressStepFormController from 'src/pages/LocatingPage/locating/profile-section/ProfileAddAddressStepFormController';
import LocatingPageRepository from 'src/pages/LocatingPage/LocatingPageRepository';
import {IsFeatureFlagEnabled} from '../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../utils/FeatureFlags';
import {Button, MobileStepper} from '@mui/material';
import {isSTAFeatureFlagEnabled} from '../../../../utils/sta/staUtils';

const ModalFormWrapper = styled.div`
  .MuiFormLabel-root {
    text-transform: capitalize;
  }
`;

const BackArrowHolder = styled.span`
  cursor: pointer;
  padding-right: 0.5em;
`;

const StyledMobileStepper = styled(MobileStepper)`
  margin-top: 1em;
  background-color: transparent !important;
  display: flex !important;
  .MuiMobileStepper-dots {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  setParentAddress: (fieldName: string, value: UserAddress) => void;
  index?: number;
  addresses: UserAddress[];
  onCurrentAddressesChanged: (addresses: UserAddress[]) => void;
  setDefaultType?: 'email' | 'phoneNumber';
}

const ProfileAddAddressStepFormModal = ({
  isOpen,
  closeModal,
  addresses,
  onCurrentAddressesChanged: onCurrentAddressesChanged,
  setDefaultType,
}: Props) => {
  const [isLoading, setLoading] = React.useState(false);
  const [isVerified, setVerified] = React.useState(false);
  const [validationCodeArray, setValidationCodeArray] = React.useState(['', '', '', '', '', '']);
  const [validationErrorMsg, setValidationErrorMsg] = React.useState('');
  const [currentStep, setCurrentStep] = React.useState(0);
  const [newAddedAddress, setNewAddedAddress] = React.useState<UserAddress>();
  const [challengeId, setChallengeId] = React.useState<string>('');
  const profileLabelAddressFeatureFlag = IsFeatureFlagEnabled(FeatureFlagResult.profileAddressLabel);
  const STAFlag = isSTAFeatureFlagEnabled() || false;

  const {updateAddressLabel, updateAddressVisibility} = LocatingPageRepository();

  const formikProps = useFormik({
    initialValues: {
      access: 'private',
      address: '',
      ordinal: 'primary',
      type: 'email',
      label: '',
    } as UserAddress,
    enableReinitialize: true,
    validationSchema: profileFormContactInfoValidationSchema,
    onSubmit: () => {
      if (currentStep === 1) {
        setCurrentStep(2);
      } else {
        closeModal();
      }
    },
  });

  const {values, setFieldValue, handleSubmit} = formikProps;

  React.useEffect(() => {
    const handleUserKeyPress = (event) => {
      const {key, keyCode} = event;
      if (key === 'Enter' || keyCode === 13) {
        const elem = document.getElementById('add-address-next-btn');
        if (elem) elem.focus();
      }
    };
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [values, currentStep, isLoading]);

  useEffect(() => {
    if (setDefaultType) {
      setFieldValue('address', '');
      setFieldValue('type', setDefaultType);
      const elem = document.getElementById('address');
      if (elem) elem.focus();
      return;
    }
  }, []);

  function handleChangeAddressType() {
    if (isLoading) return;
    if (isVerified) setVerified(false);
    setFieldValue('address', '');
    setFieldValue('type', values.type === 'email' ? 'phoneNumber' : 'email');
    const elem = document.getElementById('address');
    if (elem) elem.focus();
  }

  function handleBack() {
    if (isLoading) return;
    setValidationErrorMsg('');
    setValidationCodeArray(['', '', '', '', '', '']);
    setCurrentStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleNext() {
    ProfileAddAddressStepFormController(
      {
        activeStep: currentStep,
        onActiveStepChanged: setCurrentStep,
        setLoading,
        isVerified,
        setVerified,
        validationCodeArray,
        setValidationErrorMsg,
        handleSubmit,
        addresses,
        onCurrentAddressesChanged,
        newAddedAddress,
        onNewAddedAddressChange: setNewAddedAddress,
        updateAddressLabel,
        updateAddressVisibility,
        profileLabelAddressFeatureFlag: !!profileLabelAddressFeatureFlag,
        challengeId,
        setChallengeId,
        STAFlag,
      },
      formikProps,
    );
  }

  return (
    <StyledModal
      isOpen={isOpen}
      ariaHideApp={false}
      shouldCloseOnEsc={!isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      onRequestClose={closeModal}
    >
      <Header>
        {currentStep === 1 && (
          <BackArrowHolder onClick={handleBack}>
            <ArrowLeft />
          </BackArrowHolder>
        )}
        <span>
          {currentStep === 0 && 'Add contact information'}
          {currentStep === 1 && `Verify your ${convertTypeToReadbleText(values.type!)}`}
          {currentStep === 2 && 'Verification Success!'}
        </span>
        <CloseBtn onClick={() => (isLoading ? null : closeModal())} />
      </Header>

      <ModalFormWrapper>
        {currentStep === 0 && (
          <AddAddressStepZero
            isLoading={isLoading}
            formikProps={formikProps}
            handleChangeAddressType={handleChangeAddressType}
          />
        )}

        {currentStep === 1 && (
          <AddAddressStepOne
            formikProps={formikProps}
            isLoading={isLoading}
            setLoading={setLoading}
            validationErrorMsg={validationErrorMsg}
            setValidationErrorMsg={setValidationErrorMsg}
            validationCodeArray={validationCodeArray}
            setValidationCodeArray={setValidationCodeArray}
            setChallengeId={setChallengeId}
          />
        )}

        {currentStep === 2 && (
          <AddAddressStepTwo
            profileLabelAddressFeatureFlag={!!profileLabelAddressFeatureFlag}
            formikProps={formikProps}
            isLoading={isLoading}
          />
        )}
      </ModalFormWrapper>

      <StyledMobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={currentStep}
        nextButton={
          <Button
            variant="contained"
            color="secondary"
            disableTouchRipple
            onClick={handleNext}
            disabled={isLoading}
            id="add-address-next-btn"
          >
            {isLoading ? <CircularProgress size={20} /> : currentStep === 2 ? 'done' : 'next'}
          </Button>
        }
        backButton={null}
      />
    </StyledModal>
  );
};

export default ProfileAddAddressStepFormModal;
