import gql from 'graphql-tag';

export const NodeFragment = gql`
  fragment NodeFragment on Node {
    id
  }
`;

export const GeneralUserFragment = gql`
  fragment GeneralUserFragment on GeneralUser {
    username
    firstName
    lastName
  }
`;

export const OrganizationMemberFragment = gql`
  fragment OrganizationMemberFragment on OrganizationMember {
    ... on Node {
      ...NodeFragment
    }
    ... on GeneralUser {
      ...GeneralUserFragment
    }
    role
  }
  ${NodeFragment}
  ${GeneralUserFragment}
`;

const PublicUserFragment = gql`
  fragment PublicUserFragment on PublicUser {
    ... on Node {
      ...NodeFragment
    }
    ... on GeneralUser {
      ...GeneralUserFragment
    }
  }
  ${NodeFragment}
  ${GeneralUserFragment}
`;

export const OrganizationUserResultFragment = gql`
  fragment OrganizationUserResultFragment on OrganizationUserResult {
    ... on OrganizationMember {
      ...OrganizationMemberFragment
    }
    ... on PublicUser {
      ...PublicUserFragment
    }
    ... on UserNotFoundError {
      message
    }
  }
  ${OrganizationMemberFragment}
  ${PublicUserFragment}
`;

export const AddressDirSyncFragment = gql`
  fragment AddressDirSyncFragment on Address {
    id
    address
    type
    label @include(if: $isProfileAddressLabelFlagEnabled)
    verified
    access
    syncStatus
  }
`;

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    id
    address
    type
    label @include(if: $isProfileAddressLabelFlagEnabled)
    access
  }
`;

export const WorkStatusDirSyncFragment = gql`
  fragment WorkStatusDirSyncFragment on WorkStatus {
    value
    expiryDate
    message
    alternateContact {
      ...PublicUserFragment
    }
    resetFields
  }
  ${PublicUserFragment}
`;
