import gql from 'graphql-tag';
import {userFragmentString, userFragmentStringWithAddresses} from 'src/gql/fragment/GeneralUserFragment';

export const GetSelfProfileWithAddresses = gql` 
  query GetSelfProfile {
    me {
      ${userFragmentStringWithAddresses}
    }
  }
`;

export const GetSelfProfileWorkStatus = gql`
  query GetSelfProfileWorkStatus {
    me {
      workStatus
    }
  }
`;
export default gql`
  query GetSelfProfile {
    me {
      ${userFragmentString}
      statusResetFields
    }
  }
`;
