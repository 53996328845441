import React, {useContext, useState} from 'react';
import {typedUseSelector} from '../../../../redux';
import {useLocation, useParams} from 'react-router-dom';
import {OrganizationViewModel} from '../view-models/OrganizationViewModel';
import {AuthContext} from '../../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {STALogin} from '../../../../types';
import {LoginPasswordView} from '../../../../microfrontend/login/LoginPasswordView';
import {LoginOTPView} from '../../../../microfrontend/login/LoginOTPView';
import {PrimaryButton} from '../../../../styles/styled-components/StyledMaterialComponents';
import {BasicUser, ErrorModalButtons, OrgLoginMethods, OrgViews} from '../../../../types/sta';
import HypercareLogoSVG from '../../../../svgs/HypercareLogoSVG';
import {LoginPageHypercareLogoContainer, PageContainer} from '../../styled/login.styled';
import {ACCOUNT_DISCOVERY_FROM_ACCOUNT_SWITCH, DEBUG} from '../../../../constants/storageKeys';
import {FindUserOrganizationView} from '../login/FindUserOrganizationView';
import {LoginEmailAddressView} from '../../../../microfrontend/login/LoginEmailAddressView';
import {AccountDiscoveryFlowEmailView} from '../accountDiscovery/AccountDiscoveryCoordinator';
import {AccountDiscoveryViewModel} from '../view-models/AccountDiscoveryViewModel';
import {toast} from 'react-toastify';
import {ErrorModal} from '../components/ErrorModal';
import HypercareErrorModalContext from '../../../../contexts/ErrorModalContext';
import {
  CONTACT_ADMIN_FOR_HELP,
  NO_MATCHED_ACCOUNT,
  NO_SUPPORTED_LOGIN_METHODS,
  NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION,
  NO_SUPPORTED_LOGIN_METHODS_TITLE,
  USE_CORPORATE_EMAIL,
  USER_BLOCKED,
} from '../../../../constants/login';
import {INVALID_EMAIL_ERROR, MAX_ADDRESS_VERIFY_ATTEMPS, TOO_MANY_CHALLENGES} from '../../../../constants/networkError';
import {
  ACCOUNT_SWITCH,
  CORPORATE_EMAIL_ERROR_TITLE,
  LOGIN,
  MANUAL_ORG_LOGIN_DESCRIPTION,
  NO_MATCHED_ACCOUNT_TITLE,
  PLEASE_TRY_AGAIN_LATER,
  TOO_MANY_FAILED_ATTEMPTS,
  TOO_MANY_VERIFICATION_REQUESTS,
  USER_BLOCKED_ERROR_DESCRIPTION,
  USER_BLOCKED_ERROR_TITLE,
} from '../../../../constants/strings';
import {localStorageService} from '../../../../services/localStorageService';
import {shouldDisableBackButtonForOTP} from '../../../../utils/sta/staUtils';
import ChangeRegionModal from '../../../../components/ChangeRegion/ChangeRegionModal';
import HypercareAuthRegionContext from '../../../../contexts/HypercareLoginCoordinatorContext';

interface ISwitchAccountsProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  STALogin: STALogin;
  logout: () => void;
  changeCurrentStep?: number;
}

const SwitchAccounts = ({isLoggedIn, auth0props, STALogin, logout}: ISwitchAccountsProps) => {
  const {view} = useParams<{view: OrgViews | OrgLoginMethods}>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const currentSelectedAccount = typedUseSelector((state) => state.currentSelectedAccount);

  const email = queryParams.get('email') || '';
  const challengeIdFromParams = queryParams.get('challengeId') || '';
  const isMethodSwitchable = queryParams.get('isMethodSwitchable') || '';

  const [currentOrg, setCurrentOrg] = useState(currentSelectedAccount.organization ?? {});
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const {submitEmailAddress, submitOTP, submitPassword, redirectUserToSignup, handleOTPLogin} = OrganizationViewModel();
  const {submitOTP: handleSubmitAccountDiscoveryOTP, handleResendOTP} = AccountDiscoveryViewModel(logout);
  const [isLoginMethodSwitchable, setIsLoginMethodSwitchable] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [challengeId, setChallengeId] = useState(challengeIdFromParams ? challengeIdFromParams : '');
  const {errorModalOpenStatus, setErrorModalOpenStatus, errorModalDetails, setErrorModalDetails} =
    useContext(HypercareErrorModalContext);
  const [errorModalButtons, setErrorModalButtons] = useState<ErrorModalButtons[] | null>(null);
  const [currentFlow, setCurrentFlow] = useState<OrgViews>(OrgViews.ACCOUNT_DISCOVERY);
  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);

  const handleSubmitPassword = async (value: string) => {
    setIsSubmittingPassword(true);
    setCurrentFlow(OrgViews.FIND_MY_ORGANIZATION);
    const res = await submitPassword(currentOrg.url || '', email, value);

    if (res?.error) {
      setPasswordError(res.error);
    }

    if (res?.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
    setIsSubmittingPassword(false);
  };

  const handleAccountDiscoveryOTP = async (challengeId, otp, email) => {
    const res = await handleSubmitAccountDiscoveryOTP({challengeId, otp, email});
    if (res?.error) {
      setOTPError(res.error);
    }
  };

  const handleSubmitOTP = async (value: string) => {
    if (window.localStorage.getItem('accountDiscoveryFromAccountSwitch') === 'true') {
      await handleAccountDiscoveryOTP(challengeId, value, currentEmail);
    }
    const res = await submitOTP(challengeId, value, currentOrg.url || '');

    if (res?.error || 'error' in res) {
      if (res.error === MAX_ADDRESS_VERIFY_ATTEMPS) {
        let description = PLEASE_TRY_AGAIN_LATER;
        let buttons = [
          {
            type: 'secondary',
            buttonLabel: 'Contact Hypercare Support ',
            onClickHandler: () => {
              window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
              setErrorModalOpenStatus(false);
            },
            id: 'close-btn',
          },
        ];

        setErrorModalDetails({
          title: TOO_MANY_FAILED_ATTEMPTS,
          description: description,
        });

        setErrorModalButtons(buttons);

        setErrorModalOpenStatus(true);
      }

      setOTPError(res.error?.toString() || 'Error occurred when submitting otp');
    }

    if (res.data) {
      await STALogin(currentOrg, res.data, email || currentEmail);
    }
  };

  const showDebugMenu = () => {
    window.routerHistory.push(`/${DEBUG}`);
  };

  const resendOTPCode = async (email: string) => {
    const res = await handleResendOTP(email);

    if ('challengeId' in res && res?.challengeId) {
      setChallengeId(res.challengeId);
      toast.success(`Successfully resent verification code`);
    }

    if ('error' in res && res?.error) {
      switch (res.error) {
      }
      toast.error('Unable to resend verification code');
    }
  };

  const routeToSignup = async (email: string) => {
    const res = await redirectUserToSignup(email);
    if (res && res?.error) {
      setErrorModalDetails({
        title: TOO_MANY_VERIFICATION_REQUESTS,
        description: PLEASE_TRY_AGAIN_LATER,
      });
      setErrorModalButtons([]);
    } else {
      setErrorModalOpenStatus(false);
    }
  };

  const handleGoBack = () => {
    window.routerHistory.push(`/`);
  };
  const {enterEmail} = AccountDiscoveryViewModel();

  const handleSubmitEmailAddress = async (value: string) => {
    setCurrentEmail(value);
    setIsSubmittingEmail(true);
    setCurrentFlow(OrgViews.ACCOUNT_SELECTION);
    window.localStorage.removeItem(ACCOUNT_DISCOVERY_FROM_ACCOUNT_SWITCH);
    const res = await submitEmailAddress(value.toLowerCase(), currentOrg);

    console.log(res, 'res');

    if (res && 'error' in res) {
      let errorTitle = '';
      let errorDescription = '';
      let buttons: ErrorModalButtons[] | null = null;

      switch (res.error) {
        case USER_BLOCKED:
          errorTitle = USER_BLOCKED_ERROR_TITLE;
          errorDescription = USER_BLOCKED_ERROR_DESCRIPTION;
          break;

        case NO_SUPPORTED_LOGIN_METHODS:
          errorTitle = NO_SUPPORTED_LOGIN_METHODS_TITLE;
          errorDescription = NO_SUPPORTED_LOGIN_METHODS_DESCRIPTION;
          break;

        case USE_CORPORATE_EMAIL:
          errorTitle = CORPORATE_EMAIL_ERROR_TITLE;
          errorDescription = CONTACT_ADMIN_FOR_HELP;
          break;

        case NO_MATCHED_ACCOUNT:
          errorTitle = NO_MATCHED_ACCOUNT_TITLE;
          errorDescription = USER_BLOCKED_ERROR_DESCRIPTION;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Close',
              onClickHandler: () => setErrorModalOpenStatus(false),
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Sign Up',
              onClickHandler: () => routeToSignup(value),
              id: 'signup-btn',
            },
          ];
          break;

        case TOO_MANY_CHALLENGES:
          errorTitle = TOO_MANY_VERIFICATION_REQUESTS;
          errorDescription = PLEASE_TRY_AGAIN_LATER;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support',
              onClickHandler: () => window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer'),
              id: 'close-btn',
            },
          ];
          break;

        default:
          errorTitle = 'There’s no account associated with this email. Would you like to sign up instead?';
          errorDescription = `Contact your admin if this was a mistake.`;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Close',
              onClickHandler: () => setErrorModalOpenStatus(false),
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Sign Up',
              onClickHandler: () => routeToSignup(value),
              id: 'signup-btn',
            },
          ];
          break;
      }

      setErrorModalDetails({
        title: errorTitle,
        description: errorDescription,
      });
      if (buttons && buttons?.length >= 1) {
        setErrorModalButtons(buttons);
      }
      setErrorModalOpenStatus(true);
      setEmailError(errorTitle || 'Error occurred when submitting email address');
    }
    if (res && 'auth0Id' in res) {
      await auth0props.loginWithRedirect({
        connection: res.auth0Id,
        scope: 'openid profile email',
      });
    }

    setIsSubmittingEmail(false);
    if (res && 'screen' in res) {
      switch (res.screen) {
        case OrgLoginMethods.OTP:
          'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
          'challengeId' in res && setChallengeId(res?.challengeId);
          window.routerHistory.push(`/accountSwitch/otp?challengeId=${'challengeId' in res && res.challengeId}`);
          return;
        case OrgLoginMethods.PASSWORD:
          'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
          window.routerHistory.push(`/accountSwitch/password?email=${value.replaceAll('+', '%2b')}`);
          return;

        case OrgLoginMethods.LOGIN:
          'data' in res && STALogin(currentOrg, res.data, currentEmail);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmitEmailAccountDiscovery = async (email: string) => {
    setCurrentEmail(email);
    setCurrentFlow(OrgViews.ACCOUNT_DISCOVERY);
    const res = await enterEmail(email);

    if (res && 'error' in res && res?.error) {
      let errorTitle = '';
      let errorDescription = '';
      let buttons: ErrorModalButtons[] | null = null;

      switch (res.error) {
        case TOO_MANY_CHALLENGES:
          errorTitle = TOO_MANY_VERIFICATION_REQUESTS;
          errorDescription = MANUAL_ORG_LOGIN_DESCRIPTION;
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare support',
              onClickHandler: () => {
                window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                setErrorModalOpenStatus(false);
              },
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Manually login',
              onClickHandler: () => {
                window.routerHistory.push('/accountSwitch/findMyOrganization');
                setErrorModalOpenStatus(false);
              },
              id: 'signup-btn',
            },
          ];
          break;
        default:
          errorTitle = INVALID_EMAIL_ERROR;
          break;
      }

      setErrorModalDetails({
        title: errorTitle,
        description: errorDescription,
      });

      if (buttons && buttons?.length > 1) {
        setErrorModalButtons(buttons);
      }

      if (errorTitle && errorDescription) {
        setErrorModalOpenStatus(true);
      }

      setEmailError(errorTitle ? errorTitle : res.error);
    }
    if (res && 'auth0Id' in res) {
      //loginWithAuth0(res.auth0Id);
    }

    if (res && 'screen' in res && res?.screen === OrgLoginMethods.OTP) {
      'challengeId' in res && setChallengeId(res?.challengeId);
      'isMethodSwitchable' in res && res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
      window.localStorage.setItem('accountDiscoveryFromAccountSwitch', 'true');
      window.routerHistory.push(`/accountSwitch/otp?challengeId=${res.challengeId}`);
      return;
    }
  };

  const handleGoToPreviousStep = () => {
    window.routerHistory.push(`/accountSwitch/findMyOrganization`);
  };

  const handleIsLoginMethodSwitchableClick = async (type: string) => {
    const currentUser = localStorageService.getItem<BasicUser>('currentSelectedUser');

    if (type !== 'password') {
      window.routerHistory.push(
        `/accountSwitch/password?email=${(email || currentEmail).replaceAll('+', '%2b')}&isMethodSwitchable=true`,
      );
    } else {
      const res = await handleOTPLogin(email);

      if ('error' in res) {
        let errorTitle = '';
        let errorDescription = '';
        let buttons: ErrorModalButtons[] | null = null;
        errorTitle = 'Too many verification codes sent';

        if (currentUser && currentUser?.loginMethods?.includes('password')) {
          errorDescription = 'Login with password instead or try again later ';
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support ',
              onClickHandler: () => {
                window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                setErrorModalOpenStatus(false);
              },
              id: 'close-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Login with Password',
              onClickHandler: () => {
                window.routerHistory.push(
                  `/accountSwitch/password?email=${(email || currentEmail).replaceAll('+', '%2b')}`,
                );
                setErrorModalOpenStatus(false);
              },
              id: 'signup-btn',
            },
          ];
        } else {
          errorDescription = 'Please try again later.';
          buttons = [
            {
              type: 'secondary',
              buttonLabel: 'Contact Hypercare Support ',
              onClickHandler: () => {
                window.open('https://hypercare.com/contact', '_blank', 'noopener noreferrer');
                setErrorModalOpenStatus(false);
              },
              id: 'close-btn',
            },
          ];
        }

        setErrorModalDetails({
          title: errorTitle,
          description: errorDescription,
        });
        setErrorModalOpenStatus(true);

        setErrorModalButtons(buttons);
        window.routerHistory.push(`/accountSwitch/password?email=${(email || currentEmail).replaceAll('+', '%2b')}`);
        return;
      }

      if ('challengeId' in res) {
        setChallengeId(res.challengeId);
      }
      window.routerHistory.push(`/accountSwitch/otp?challengeId=${res.challengeId}&isMethodSwitchable=true`);
    }
  };

  const handleForgotPasswordClick = () => {
    const params = new URLSearchParams({
      email: currentSelectedAccount?.user?.email || email,
      orgUrl: currentOrg.url,
      orgName: currentOrg.name,
    });
    window.routerHistory.push(`/resetPassword?${params.toString()}`);
  };

  const renderStep = () => {
    switch (view) {
      case OrgViews.EMAIL:
        return (
          <LoginEmailAddressView
            handleGoBack={handleGoToPreviousStep}
            handleNext={handleSubmitEmailAddress}
            organization={currentOrg}
            error={emailError}
            isLoading={isSubmittingEmail}
            isLoginMethodSwitchable={isLoginMethodSwitchable}
            handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
          />
        );
      case OrgLoginMethods.OTP:
        return (
          <LoginOTPView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitOTP}
            organization={currentSelectedAccount.organization}
            resend={resendOTPCode}
            isResending={false}
            error={OTPError}
            currentSelectedEmail={currentSelectedAccount?.user?.email || currentEmail || ''}
            isLoading={isOTPLoading}
            isLoginMethodSwitchable={isLoginMethodSwitchable || Boolean(isMethodSwitchable)}
            handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
            shouldHideBackButton={shouldDisableBackButtonForOTP(currentFlow)}
          />
        );

      case OrgLoginMethods.PASSWORD:
        return (
          <LoginPasswordView
            handleGoBack={handleGoBack}
            handleNext={handleSubmitPassword}
            organization={currentSelectedAccount.organization}
            error={passwordError}
            isLoading={isSubmittingPassword}
            isLoginMethodSwitchable={isLoginMethodSwitchable || Boolean(isMethodSwitchable)}
            handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
            handleForgotPassword={handleForgotPasswordClick}
          />
        );

      case OrgViews.ACCOUNT_DISCOVERY:
        return (
          <AccountDiscoveryFlowEmailView
            handleOnEmailSubmit={handleSubmitEmailAccountDiscovery}
            showFindMyOrgView={() => {
              setCurrentFlow(OrgViews.FIND_MY_ORGANIZATION);
              window.routerHistory.push('/accountSwitch/findMyOrganization');
            }}
            isLoading={false}
            error={emailError}
          />
        );

      case OrgViews.FIND_MY_ORGANIZATION:
        return (
          <FindUserOrganizationView
            handleGoBack={() => window.routerHistory.push('/')}
            handleShowEmailView={() => window.routerHistory.push('/accountSwitch/email')}
            setCurrentSelectedOrg={setCurrentOrg}
          />
        );
      default:
        return (
          <div>
            Unknown login methods
            <PrimaryButton onClick={() => window.routerHistory.push('/')}>Click to go back</PrimaryButton>
          </div>
        );
    }
  };

  return (
    <PageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>

      {renderStep()}

      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={ACCOUNT_SWITCH}
          setSavedAccountsData={() => null}
        />
      )}

      {errorModalOpenStatus && (
        <ErrorModal
          title={errorModalDetails.title}
          description={errorModalDetails.description}
          isOpen={errorModalOpenStatus}
          setIsOpen={setErrorModalOpenStatus}
          buttons={errorModalButtons}
        />
      )}
    </PageContainer>
  );
};

export const AccountSwitchPage = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, STALogin, logout}) => (
        <SwitchAccounts STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} logout={logout} />
      )}
    </AuthContext.Consumer>
  );
};
