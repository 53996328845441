import {Box, CircularProgress, IconButton, InputAdornment, LinearProgress} from '@mui/material';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import {Formik} from 'formik';
import React, {useState, useEffect, useRef} from 'react';
import {media} from 'src/styles/utils';
import styled from '@emotion/styled';
import NewLoginFormPasswordHeader from '../ components/NewLoginFormPasswordHeader';
import {StyledTextField} from 'src/styles/styled-components/StyledInputField';
import {
  VERIFYING_AVAILABILITY,
  LOGIN_ERROR_CODE_INVALID_USER,
  LOGIN_ERROR_CODE_INVALID_PASSWORD,
  LOGIN_ERROR_CODE_ACCOUNT_LOCKED,
} from 'src/constants/login';
import {DefaultButton} from 'src/styles/styled-components/Button';
import * as yup from 'yup';
import {Link} from 'react-router-dom';
import AnalyticsManager, {EVENTS} from 'src/analytics/AnalyticsManager';

interface LoginFormWrapperProps {
  loading?: string;
}

const LoginFormWrapper = styled.div<LoginFormWrapperProps>`
  background-color: white;
  ${media.sm`
    padding: 1em;
  `}
  border-radius: 8px;
  width: 600px;
  max-height: 800px;
  padding: 44px 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 3px 5px rgba(9, 30, 66, 0.2)) drop-shadow(0px 0px 1px rgba(9, 30, 66, 0.31));
  opacity: ${(props) => (props.loading ? '0.5' : '')};
  min-height: 387px !important;
`;

const ProgressContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 600px;
  & * {
    border-radius: 8px 8px 0px 0px;
  }
`;
const InputsWrapper = styled.div`
  .MuiOutlinedInput-input {
    box-sizing: border-box;
    height: 52px;
    padding: 16px 14px;
  }
  .MuiTextField-root {
    &:first-child {
      margin-bottom: 1em;
    }
  }
  .MuiFormHelperText-root {
    margin-right: 0 !important;
    margin-left: 2px !important;
  }
`;

const StyledSubmitAddressButton = styled(DefaultButton)`
  background-color: #ff3e55;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
`;

const HelperBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;
const StyledLink = styled(Link)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.chatTeal};
  text-decoration: none;
  float: right;
  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: 400;
`;

const StyledAnchor = styled.a`
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 12px;
  text-decoration: none;
  float: right;
  span {
    padding-left: 4px;
    color: ${(props) => props.theme.colors.chatTeal};
  }
  margin-bottom: 28px;

  line-height: 20px;
  font-family: 'Open Sans';
  font-weight: 400;
`;

export const HypercareLoginPasswordView = ({username, viewModel, handleBackButtonClick}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const passwordInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (passwordInput.current) {
      passwordInput.current.focus();
    }
  }, []);

  const onClickContact = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loginContactSupport,
    });
  };

  const onClickForgotPassword = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.loginForgotPwdBtnPressed,
    });
  };

  return (
    <Formik
      initialValues={{password: ''}}
      validationSchema={yup.object().shape({
        password: yup.string().required('Password is required'),
      })}
      onSubmit={async (values, actions) => {
        //completeAddressStep
        const {password} = values;
        const result = await viewModel.handleNextButtonPressed(username, password);
        const credentialErrors = [LOGIN_ERROR_CODE_INVALID_USER, LOGIN_ERROR_CODE_INVALID_PASSWORD];
        if (result?.error) {
          if (credentialErrors.includes(result.error.code)) {
            actions.setFieldError('password', result.error.message);
            setLoginAttempts((prevValue) => prevValue + 1);
          } else if (result.error.code === LOGIN_ERROR_CODE_ACCOUNT_LOCKED) {
            actions.setFieldError('password', result.error.message);
            setIsAccountLocked(true);
          } else {
            actions.setFieldError('password', result.error.message);
          }
        }
      }}
    >
      {({handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
          <LoginFormWrapper loading={isSubmitting ? 'true' : undefined}>
            {isSubmitting && (
              <ProgressContainer>
                <LinearProgress color="secondary" />
              </ProgressContainer>
            )}
            <NewLoginFormPasswordHeader
              isAccountLocked={isAccountLocked}
              loginAttempts={loginAttempts}
              title="Welcome back!"
              description={`${username}`}
              onBackButtonClick={handleBackButtonClick}
            />
            <InputsWrapper>
              <StyledTextField
                data-testid="passwordInput"
                fullWidth
                variant="outlined"
                placeholder="**********"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                name="password"
                inputRef={passwordInput}
                value={values.password}
                helperText={touched.password && errors.password}
                error={touched.password && Boolean(errors.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        onClick={() => setShowPassword((prev) => !prev)}
                        style={{marginRight: -8}}
                        size="large"
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputsWrapper>

            <HelperBox>
              <StyledLink onClick={onClickForgotPassword} to="/resetpassword">
                Forgot your password?
              </StyledLink>
              <StyledAnchor
                onClick={onClickContact}
                href="https://www.hypercare.com/contact"
                rel="noopener noreferrer"
                target="_blank"
              >
                Having another issues?
                <span>Contact us</span>
              </StyledAnchor>
            </HelperBox>

            {
              <StyledSubmitAddressButton
                data-testid="addressSubmitButton"
                type="submit"
                disabled={isSubmitting || !values.password}
              >
                {isSubmitting && (
                  <>
                    <CircularProgress color="inherit" size={18} style={{marginRight: 16}} /> {VERIFYING_AVAILABILITY}
                  </>
                )}
                {!isSubmitting && 'Log in'}
              </StyledSubmitAddressButton>
            }
          </LoginFormWrapper>
        </form>
      )}
    </Formik>
  );
};
