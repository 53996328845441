import {AddressType} from 'src/types';
import {ACCESS} from './hiddenNotes';

export const ADMIN_WEB_APP_PROD_URL = 'https://admin.hypercare.com';
export const ADMIN_WEB_APP_STAGING_URL = 'https://admin.staging.hypercare.com';
export const USER_ITEM_DETAIL_WRAPPER_NO_EMAILL_TOOLTIP = 'No email';
export const REQUESTED = 'Requested';
export const INVITED = 'Invited';
export const DAY = 'day';
export const PENDING_ADMIN_APPROVAL = 'Pending admin approval';
export const PENDING_INVITEE_RESPONSE = 'Pending invitee response';
export const REINVITED_TODAY = 'Reinvited today';
export const REINVITE_SENT = 'Reinvite Sent';
export const SMS_MODAL_CONTENT =
  'Note: Do not send personal health information via pagers or SMS, as they are not compliant with healthcare privacy regulations. ';
export const SMS_ACTION_BUTTON_TEXT = 'Send an SMS';
export const CHANGE_STATUS_MODAL_INVALID_CUSTOM_TIME_ERROR =
  'You cannot choose a reversion time in the past. Please choose a future end time.';
export const STATUS_TEXT_FIELD_LABEL = 'Add custom status message (Enter up to 250 characters)';
export const STATUS_PLACEHOLDER = 'Add a message to let other users know details about your status';
export const HYPERCARE_SUPPORT_LINK = 'https://support.hypercare.com';
export const LOGIN = 'login';
export const SIGNUP = 'signup';
export const FIRST_NAME = 'First Name';
export const FIRST_NAME_PLACEHOLDER = 'Enter first name';
export const LAST_NAME = 'Last Name';
export const LAST_NAME_PLACEHOLDER = 'Enter last name';
export const JOB_TITLE = 'Job Title';
export const JOB_TITLE_PLACEHOLDER = 'Enter title (eg. Physician/Cardiology)';
export const VIEW_PROFILE = 'View Profile';
export const VIEW_ARCHIVED_MESSAGES = 'View Archived Messages';
export const TEMPLATE_MESSAGE_REPORT = 'Template Message Report';
export const INVITE_COLLEAGUES = 'Invite Colleagues';
export const JOIN_ORGANIZATION = 'Join Organization';
export const CHANGE_PASSWORD = 'Change Password';
export const DEBUG_CONSTANT = 'Debug';
export const REPORT = 'Reports';

/**************** Misc ****************/

export const CANCEL = 'Cancel';
export const ABOUT = 'About';
export const REMOVE = 'Remove';
export const CANADA = 'Canada';
export const UNITED_STATES = 'United States';
export const EUROPE = 'Europe';
export const CANADA_ABBREVIATION = 'CA';
export const UNITED_STATES_ABBREVIATION = 'USA';
export const UNITED_STATES_ABBREVIATION_2 = 'US';
export const EUROPE_ABBREVIATION = 'EU';
export const AuthRegionMap = {
  [CANADA_ABBREVIATION]: CANADA,
  [UNITED_STATES_ABBREVIATION]: UNITED_STATES,
  [EUROPE_ABBREVIATION]: EUROPE,
};
export const FIELD_PLACEHOLDER_PHONE = 'Enter a phone number';
export const FIELD_PLACEHOLDER_DATE = 'Select a date';
export const FIELD_PLACEHOLDER_NUMBER = 'Enter a number';
export const FIELD_PLACEHOLDER_URL = 'Enter a url';
export const FIELD_PLACEHOLDER_DROPDOWN = 'Select an option...';
export const FIELD_PLACEHOLDER_EMAIL = 'Enter an email';
export const FIELD_PLACEHOLDER_TEXT = 'Enter text';
export const SITES = 'Sites';
export const FILTER_BY_SITES = 'Filter by sites';

/**************** Modals ****************/
export const CHANGE_REGION_MODAL_TITLE_TEXT = 'Which country is your organization located in?';
export const CHANGE_REGION_MODAL_DESCRIPTION_TEXT =
  'We ask to ensure that your data is stored in the right region and to ensure privacy & security.';
export const LOGIN_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON = (selectedRegion, AuthRegionMap) =>
  `Switch ${!selectedRegion ? '' : `to ${AuthRegionMap[selectedRegion]}`}`;
export const SIGN_UP_PAGE_CHANGE_REGION_MODAL_SWITCH_BUTTON = (selectedRegion, AuthRegionMap) =>
  `Create ${!selectedRegion ? '' : AuthRegionMap[selectedRegion]} account`;
export const LOGIN_BOX_DESCRIPTION_TEXT = (currentRegion, AuthRegionMap) =>
  `If you work for an organization outside of ${
    currentRegion === 'USA' ? `the ${UNITED_STATES}` : AuthRegionMap[currentRegion]
  }, `;
export const LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT = 'click here to switch region.';

/**************** Pages ****************/
export const LOGIN_PAGE_CURRENT_REGION_TITLE = 'Current region:';
export const LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE = 'You have entered an incorrect password multiple times.';
export const LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO =
  '  If your username have 10 unsuccessful attempt in record, you will be locked out of your account and will have to call support to get access.';
export const LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE = 'Your session has been locked for your account security.';
export const LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US = 'Please contact us ';
export const LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT = 'for help.';
export const SIGN_UP_PAGE_VERIFY_EMAIL_ERROR_MESSAGE = 'Failed to verify your code, please try again';
export const SIGN_UP_PAGE_VERIFY_EMAIL_UNKOWN_ERROR_MESSAGE =
  'Unknown error occurred, please check your internet connection and try again';
export const PREFERENCE_ORDER = 'Preferences';
export const TYPE = 'Type';
export const CONTACT = 'Contact';
export const CONTACT_US = 'Contact us';
export const VISIBILITY = 'Visibility';
export const LABEL = 'Label';
export const EMAIL = 'Email';
export const SMS = 'SMS';
export const MOBILE_NUMBER = 'Mobile number';
export const NUMERIC_PAGER = 'Numeric Pager';
export const ALPHANUMERIC_PAGER = 'Alphanumeric Pager';
export const SHOW_MORE = 'Show more';
export const SHOW_LESS = 'Show less';
export const PROFILE_PAGE_ADDRESS_INFORMATION_TITLE = 'ADDITIONAL CONTACT METHODS';
export const PROFILE_PAGE_PREFERENCE_ORDER_TOOLTIP_TEXT =
  'The list shows the most preferred method at the top to the least.';
export const PROFILE_PAGE_ADDRESS_RE_ORDER_TOOLTIP_TEXT = `Use this draggable handle to change the order of contact methods from most preferred at the top to the least.`;
export const PROFILE_ADDRESS_UPDATE_SUCCESS = 'Profile successfully updated!';
export const PROFILE_NEW_CONTACT_METHOD_SUCCESS = 'New contact method successfully added.';
export const PROFILE_ADDRESS_DELETE_LAST_ADDRESS_ERROR =
  'Unable to remove last address, you need at least one alternate contact method in your profile';
export const PROFILE_PAGE_NO_ADDRESS_MESSAGE = 'This user has no contact methods';
export const PROFILE_PAGE_ADD_ANOTHER_ADDRESS_BUTTON_TEXT = 'Add another method';
export const PROFILE_PAGE_EMPTY_NOTES_TITLE = 'Your notes can be found here';
export const PROFILE_PAGE_EMPTY_NOTES_SUBTITLE =
  'Notes can be set to public or private. Your administrators can also add and edit them on your behalf.';
export const PROFILE_PAGE_ADD_ANOTHER_FIELD_TEXT = 'Add another field';
export const PROFILE_PAGE_OTHER_INFORMATION_TITLE = 'OTHER INFORMATION';
export const PROFILE_PAGE_EMPTY_SECTION_SUBTITLE = "No information to display. Press 'Edit profile' to add one.";
export const PROFILE_PAGE_UPDATE_PROFILE_FIELDS_TEXT = 'Update';
export const PROFILE_PAGE_UPDATE_FAILED = 'Update failed';
export const ProfileAddressMap = {
  [AddressType.PHONE_NUMBER]: MOBILE_NUMBER,
  [AddressType.EMAIL]: EMAIL,
  [AddressType.NUMERIC_PAGER]: NUMERIC_PAGER,
  [AddressType.ALPHANUMERIC_PAGER]: ALPHANUMERIC_PAGER,
};
export const ProfileVisibiltyMap = {
  [ACCESS.PRIVATE]: 'Private',
  [ACCESS.PUBLIC]: 'Public',
};
export const ROLE_NOTES = 'Role notes';
export const ADMIN_NOTES = (notesForCurrentRole) =>
  `📒 Notes (${notesForCurrentRole?.notes?.length ? notesForCurrentRole.notes.length : 0})`;
export const NO_ONE_IS_AVAILABLE = 'No one is available';
export const NO_ONE_IS_CURRENTLY_AVAILABLE = 'No one is on call currently';
export const ROTATING_PAGER = 'Rotating Pager';
export const ROTATING_PAGER_TOOLTIP_TEXT = 'This is a rotating pager. Click to page number.';
export const UPCOMING_SHIFT_DESCRIPTION = 'Starting at ';
export const SHIFT_END_DESCRIPTION = 'Ends at ';
export const DIR_SYNC_INFO = 'Some synced information from your source directory cannot be edited in Hypercare';
export const LEARN_MORE = 'Learn more';
export const MANAGED_BY_DIRECTORY_SERVICE = 'MANAGED BY DIRECTORY SERVICE';
export const SYNCED_INFORMATION = 'Synced information from your source directory cannot be edited in Hypercare';
export const SSO_ENABLED_DESC =
  'Your account uses single-sign-on. If you wish to reset your password, please contact your administrator.';
export const CPF_PLACEHOLDER_LABEL_TEXT = 'Select a label...';
export const ORGANIZATION_INVITE_BANNER_TITLE = (numberOfInvites: number) =>
  `You have ${numberOfInvites} pending organization invites — click here to check it out!`;
export const GROUP_CHAT_BUSY_OR_UNAVAILABLE_MEMBERS_BANNER_TITLE = (busyOrUnavailableCount: number) =>
  `${busyOrUnavailableCount} group member${busyOrUnavailableCount === 1 ? '' : 's'} may not receive notifications`;
export const PROFILE_PAGE_EMPTY_LABEL_TOAST_MESSAGE =
  'Please add a label for each contact method before updating profile';
export const ADD_ADDRESS_STEP_3_EMPTY_LABEL_TOAST_MESSAGE =
  'Please add a label for the contact method before finishing';
export const ADD_ADDRESS_MODAL_INSTRUCTIONS =
  'One last thing, please give this email address a label and indicate who can see this information!';
export const SELECT = 'Select...';
export const ADD_LABEL = 'Add label';
export const CONTACTS_PAGE_SEARCH_FIELD_PLACEHOLDER = 'Search contacts by name or job title';
export const USERS_PAGE_SEARCH_FIELD_PLACEHOLDER = 'Search people by name or role title';
export const NO_SEARCH_RESULT_FOUND_TITLE = 'No results found.';
export const NO_SEARCH_RESULT_FOUND_BODY = 'Try another keyword or modify the filters.';
export const ADD_USER_MODAL_EMPTY_SEARCH_TITLE = 'Search by name or job title';
export const NO_USERS_FOUND = 'No Users Found';
export const ADD_USER_MODAL_EMPTY_SEARCH_DESCRIPTION = ' Search results will appear here';
export const CHAT_PANEL_INVITE_BANNER_TEXT = 'Invite colleagues to Hypercare';
export const CLEAR_ALL = 'Clear all';
export const END_OF_USER_LIST_MESSAGE = 'End of list';
export const NETWORK_ERROR_OCCURRED =
  'An Error Occurred, Please Check Your Internet Connection And Try To Refresh The Page.';
export const CONTACT_SUPPORT_IF_PROBLEM_PERSIST =
  'If The Problem Persists, Please Let Us Know By Contacting Hypercare Support.';
export const INVITE_USER_MODAL_FLOW_SEARCH_PLACEHOLDER_TEXT = 'E.g.: Jane Doe, Physician, Dermatology';
export const SHOW_SELECTED = 'Show selected';
export const SELECTED = 'Selected';
export const MESSAGES = 'Messages';
export const ARCHIVED_MESSAGES = 'Archived';
export const NEW_CHAT = 'New Chat';
export const SCHEDULING_PAGE_DEPARTMENT_SELECTOR_INPUT_PLACEHOLDER = 'Select a department...';
export const SCHEDULING_PAGE_USER_SELECTOR_INPUT_PLACEHOLDER = 'Select a user...';
export const SCHEDULING_PAGE_NO_DEPARTMENT_FOUND_ERROR = 'No current department found';
export const LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT = 'Add or create another account';
export const LOGIN_PAGE_REMOVE_ALL_ACCOUNT = 'Remove all accounts on this browser';
export const LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT = 'Manage accounts on this browser';
export const LOGIN_PAGE_WELCOME_TITLE = "Who's using Hypercare?";
export const LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_TITLE = 'Remove all accounts from browser';
export const LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_DESCRIPTION =
  'This will log you out of all active accounts and remove them from device. You’ll need to enter your login credentials again the next time you log into Hypercare.';
export const LOGIN_PAGE_CANT_FIND_ORG_TOOLTIP_TEXT =
  'Your org may have enforced logging in with \n' +
  'Hypercare URL. If you don’t know the URL, \n' +
  'contact your internal or Hypercare support.';
export const LOGIN_PAGE_ORG_URL_TOOLTIP_TEXT =
  'Your Hypercare URL is your org’s unique identifier. \n' +
  'It consists of your org name, followed by the \n' +
  'hypercare.com domain (e.g. acme.hypercare.com).\n' +
  '\n' +
  'If you don’t know the URL, contact your internal or \n' +
  'Hypercare support.';
export const LOGIN_PAGE_ENTER_ORG_URL_TEXT = "Enter your org's Hypercare URL";
export const LOGIN_PAGE_SIGNUP_TEXT = 'Sign up';
export const LOGIN_PAGE_SIGNUP_SUB_TEXT = 'If you want to create a new account or organization';
export const LOGIN_PAGE_VISIT_SUPPORT_CENTER = 'Visit support center';
export const LOGIN_PAGE_CANT_FIND_ORG_TEXT = "Can't find your org?";
export const LOGIN_PAGE_UNKNOWN_HYPERCARE_URL = "Don't know your Hypercare URL?";
export const LOGIN_PAGE_SEARCH_BY_ORG_NAME_TEXT = 'Search org by name';
export const LOGIN_PAGE_FIND_MY_ORG_TITLE = 'Find my organization';
export const LOGIN_PAGE_ENTER_ORG_URL_INPUT_LABEL = 'Org Hypercare URL';
export const LOGIN_PAGE_ENTER_ORG_URL_INPUT_PLACEHOLDER = 'Your-org.hypercare.com';
export const LOGIN_PAGE_EMAIL_ADDRESS_VIEW_TITLE = 'Enter your email address';
export const LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_PLACEHOLDER = 'you@acme-hospital.com';
export const LOGIN_PAGE_EMAIL_ADDRESS_VIEW_SEARCH_BAR_LABEL = 'Email';
export const LOGIN_PAGE_EMAIL_ADDRESS_VIEW_EMPTY_EMAIL = 'Email is required';
export const LOGIN_PAGE_PASSWORD_VIEW_TITLE = 'Enter password';
export const LOGIN_PAGE_PASSWORD_VIEW_DESCRIPTION = (organizationName?: string) =>
  `Enter the password to login to ${organizationName || 'organization'}`;
export const LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_PLACEHOLDER = '*********';
export const LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_LABEL = 'Password';
export const LOGIN_PAGE_PASSWORD_VIEW_EMPTY_PASSWORD = 'Password is required';
export const ON_CALL_PAGE_LAST_REFRESHED_AT_ = 'Page last refreshed';
export const ON_CALL_PAGE_NO_SCHEDULE_FOUND = 'No on-call schedule found';
export const ON_CALL_PAGE_SEARCH_DEPARTMENT_PLACEHOLDER = 'Search by department';
export const SEARCH_ROLE_OR_PERSON_BY_NAME = 'Search role or person by name';
export const ORG_WELCOME_POPUP_DESCRIPTION_TITLE = (orgName: string) => `Welcome to ${orgName} on Hypercare!`;
export const ORG_WELCOME_POPUP_DESCRIPTION_ONE = `A Hypercare URL, your org’s unique identifier, has been created:`;
export const ORG_WELCOME_POPUP_DESCRIPTION_TWO = `Please remember and use this link to help you login faster next time.`;
export const ORG_WELCOME_POPUP_BUTTON_TEXT = `Got it!`;
export const REGION_CODE_PARAM_OVERRIDE = 'regionCode';
export const ADDRESS_BANNER_EMAIL_TITLE = 'Email required: ';
export const ADDRESS_BANNER_EMAIL_DESCRIPTION = 'Add an email to avoid losing access to Hypercare.';
export const ADDRESS_BANNER_EMAIL_BUTTON_TEXT = 'Add email';
export const ADDRESS_BANNER_PHONE_TITLE = 'Recommended: ';
export const ADDRESS_BANNER_PHONE_DESCRIPTION = 'Add a mobile number to receive backup notifications.';
export const ADDRESS_BANNER_PHONE_BUTTON_TEXT = 'Add number';
export const ADDRESS_BANNER_BOTH_ADDRESS_TITLE = 'Action required: ';
export const ADDRESS_BANNER_BOTH_ADDRESS_DESCRIPTION =
  'Add an email and mobile number to avoid losing access to Hypercare.';
export const ADDRESS_BANNER_BOTH_ADDRESS_TEXT = 'Add now';

export const USER_NOT_FOUND_TITLE = 'User not found';
export const USER_NOT_FOUND_BODY_ONE = 'For urgent matters, please contact Switchboard or Locating. ';
export const USER_NOT_FOUND_BODY_TWO = 'If you believe this is a mistake, please reach out to your organization admin.';
export const CHANGE_REGION_CONFIRMATION_DIALOG_TITLE =
  'You will be logged out of all saved accounts upon switching region. Do you wish to continue?';
export const CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_ONE =
  'Switching to another region will log you out of all saved accounts and remove them from your device.';
export const CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_TWO =
  'This is to ensure your data is stored in the right region and maintain privacy & security.';
export const HAVING_SOME_ISSUES = 'Having some issues?';
export const FORGOT_YOUR_PASSWORD = 'Forgot your password?';
export const LOGIN_WITH_PASSWORD = 'Login with password instead';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOADER_DESCRIPTION = 'Logging into linked accounts';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_DESCRIPTION =
  'If it’s you, continue to login or sign up for a new account.';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_TITLE = 'Your email is linked to some accounts in these organizations:';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_SUB_TITLE =
  'Information you have entered will not be saved if you leave this page.';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_LOGIN_BUTTON = 'Login';
export const SIGNUP_PAGE_EXISTING_ACCOUNT_MODAL_CONTINUE_BUTTON = 'Sign up';
export const ACCOUNT_SELECTION_USER_NOT_FOUND_ERROR = 'User not found';
export const ACCOUNT_SELECTION_INACTIVE_ACCOUNT_ERROR = 'Inactive account';
export const ACCOUNT_SELECTION_NO_MATCHED_ACCOUNT_ERROR = 'No matched account';
export const ACCOUNT_SELECTION_ACCOUNT_HAS_NO_ORG_ERROR = 'Account does not belong to an organization';
export const ACCOUNT_SELECTION_NO_SUPPORTED_LOGIN_METHODS_ERROR = 'No supported login methods';
export const SUGGESTED_ORGANIZATION_TITLE = 'Other organization(s) you may join';
export const RESET_PASSWORD_TITLE = 'Reset Password';
export const RESET_PASSWORD_CONTAINER_TITLE = 'Where do you want us to send the reset password link?';
export const RESET_PASSWORD_CONTAINER_DESCRIPTION =
  'Select an email linked to your account and we’ll send you a link to reset your password.';
export const BACK_TO_LOGIN_LINK = 'Back to login';
export const RESET_PASSWORD_CONTAINER_SUBMIT_BUTTON_TEXT = 'Send email';
export const OR_PRESS_ENTER = 'or press Enter';

export const RESET_PASSWORD_SUCCESS_TITLE = 'Check your email';
export const RESET_PASSWORD_SUCCESS_DESCRIPTION = (maskedAddress: string) =>
  `We’ve sent you an email to ${maskedAddress} with a link to reset your password.`;
export const RESET_PASSWORD_SUCCESS_DESCRIPTION_2 = `This link will expire after 1 hour for security reasons`;
export const RESET_PASSWORD_LINK_EXPIRED_TITLE = 'Link expired';
export const RESET_PASSWORD_LINK_EXPIRED_DESCRIPTION =
  'The link to reset your password expired after 1 hour for security reasons. Please request for a new link.';
export const RESET_PASSWORD_LINK_BUTTON_TEXT = 'Get a new link';
export const RESET_PASSWORD_INVALID_LINK_TITLE = 'Invalid link';
export const RESET_PASSWORD_INVALID_LINK_DESCRIPTION =
  'The link has been used and is no longer valid. Please request for a new link.';
export const RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_TITLE = 'Password changed';
export const RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_DESCRIPTION = 'Your password has been successfully reset!';
export const RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCESS_MOBILE_DESCRIPTION =
  'Your password has been successfully reset! Open the Hypercare app to login with the new password.';
export const OK = 'OK';
export const ADDRESS_IN_USE = 'AddressInUse';
export const ACCOUNT_BLOCKED = 'AccountBlocked';
export const PENDING_ADMIN_APPROVAL_ERROR = 'pending_admin_approval';
export const PENDING_USER_ACCEPTANCE = 'pending_user_acceptance';
export const ORGANIZATION_MEMBER = 'OrganizationMember';
export const FULL_ORGANIZATION_MEMBER = 'FullOrganizationMember';
export const INVITE_PENDING_ADMIN_APPROVAL = 'InvitePendingAdminApproval';
export const UNKNOWN_ERROR = 'Unknown error';
export const ADDRESS_IN_USE_TOOLTIP = 'Email is associated with an existing account';
export const ACCOUNT_BLOCKED_TOOLTIP = 'Please ask your admin to invite the user';
export const INVITE_RESUESTS_PROCESSED = 'Invite requests processed';
export const SENT_ADMIN_APPROVAL = 'SENT FOR ADMIN APPROVAL';
export const SENT = 'SENT';
export const FAILED = 'FAILED';
export const USER_INVITE = 'UserInvite';
export const INVITE_REQUESTS_FAILED_SEND_DESCRIPTION =
  'invite requests failed to be sent. Contact admin for more information.';
export const INVITE_REQUESTS_SENT_DESCRIPTION = 'invite requests have been successfully sent.';
export const ADMIN_APPROVAL_SENT_DESCRIPTION =
  'invite requests are sent for admin approval. You will be informed via email once the decision is made.';
export const PENDING_INVITE_ACCEPTANCE = 'PENDING INVITE ACCEPTANCE';
export const PENDING_INVITE_ACCEPTANCE_CONTACT_LIST_LABEL = 'Pending invite acceptance';
export const SHELL_ACCOUNT = 'SHELL ACCOUNT';
export const SHELL_ACCOUNT_CONTACT_LIST_LABEL = 'Shell account';
export const PLEASE_TRY_AGAIN_LATER = 'Please try again later';
export const TOO_MANY_FAILED_ATTEMPTS = 'Too many failed verification attempts';
export const TOO_MANY_VERIFICATION_REQUESTS = 'Too many verification codes sent';
export const USER_BLOCKED_ERROR_TITLE = 'Your email is linked to a removed account';
export const USER_BLOCKED_ERROR_DESCRIPTION = `Contact your admin if this was a mistake.`;

export const CORPORATE_EMAIL_ERROR_TITLE = 'Please use your work email to login to this organization';
export const NO_MATCHED_ACCOUNT_TITLE =
  'There’s no account associated with this email. Would you like to sign up instead?';
export const MANUAL_ORG_LOGIN_DESCRIPTION = 'Manually login to an organization instead or try again later.';
export const ACCOUNT_DOES_NOT_SUPPORT_PASSWORD_LOGIN = 'Your account does not support password login.';
export const ACCOUNT_SWITCH = 'accountSwitch';
