import gql from 'graphql-tag';

export const CHECK_ADDRESS_AVAILABILITY = gql`
  query GetOrganizationByURL($address: String!, $organizationUrl: String!) {
    isAddressAvailable(address: $address, organizationUrl: $organizationUrl) {
      ...QueryIsAddressAvailableResultFragment
    }
  }
  fragment QueryIsAddressAvailableResultFragment on QueryIsAddressAvailableResult {
    __typename
    ... on Error {
      __typename
      message
    }
    ...IsAddressAvailableResultFragment
  }

  fragment IsAddressAvailableResultFragment on IsAddressAvailableResult {
    __typename
    isAddressAvailable
  }
`;
