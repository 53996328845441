import React, {useState} from 'react';
import {Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {Box, Typography} from '@mui/material';
import AnalyticsManager, {EVENTS, PAGE_VIEWS} from 'src/analytics/AnalyticsManager';

import NewNextStepButton from '../components/NextStepButton';
import NewVerificationCodeInput from '../components/NewVerificationCodeInput';
import {TOO_MANY_CHALLENGES} from 'src/constants/networkError';
import SignupPage from 'src/microfrontend/signup/SignupPage';
import styled from '@emotion/styled';
import {muiTheme} from 'src/styles/theme';
import {localStorageService} from 'src/services/localStorageService';
import {OrganizationAccountsCacheDataFromMobile} from 'src/types/sta';
import {ENCODED_DATA_FROM_MOBILE_CLIENTS} from 'src/constants/storageKeys';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';

const VerificationWrapper = styled(Box)`
  margin-left: 4px;
`;

const validationSchema = yup.object().shape<IVerifyEmailStepOutputData>({
  code: yup
    .string()
    .required('Verification code is required')
    .test('empty', 'Verification code is required', (value: string) => !!value && value.replace(/ /g, '').length > 0)
    .test(
      'len',
      'Verification code should be 6 digit',
      (value: string) => !!value && value.replace(/ /g, '').length === 6,
    ),
});

interface IVerifyEmailStepInputData {
  email: string;
}

export interface IVerifyEmailStepOutputData {
  code: string;
}

export const SignupVerifyEmailAddressView = ({
  inputData,
  onBackButtonClick,
  viewModel,
  setChallengeId,
  challengeId,
}) => {
  const [resending, setResending] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = React.useState({title: '', subTitle: ''});

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.signupVerifyAddress);
  }, []);

  const handleResend = async (email) => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signUpResendVerifyCode,
      params: {
        address: inputData.email,
      },
    });

    try {
      setResending(true);
      const requestVerificationResult = await viewModel.resendEmailValidationCode(inputData.email);
      if (requestVerificationResult?.data?.response) {
        setChallengeId(requestVerificationResult?.data?.response.challengeId);
        setResending(false);
        toast.success(`Successfully resent verification code`);
      } else {
        if (requestVerificationResult?.data?.errors && requestVerificationResult?.data?.errors[0]) {
          let errorCode = requestVerificationResult?.data?.errors[0].name;
          if (errorCode === TOO_MANY_CHALLENGES) {
            setResending(false);
            toast.error(requestVerificationResult?.data?.errors[0].message);
          } else {
            setResending(false);
            toast.error('Unknown error occurred, please check your internet connection and try again');
          }
        } else {
          toast.error('Failed to resend verification code, please check your internet connection and try again');
          setResending(false);
        }
      }
    } catch (err) {
      setResending(false);
    }
  };

  const onNextClick = () => {
    AnalyticsManager.applyAnalytics({
      eventName: EVENTS.signupVerifyEmailNextPressed,
      params: {
        address: inputData.email,
      },
    });
  };

  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );

  return (
    <>
      <SignupPage
        currentStep={2}
        isDisabled={false}
        title="Check your email for a code"
        description={`Enter the code sent to ${inputData.email}`}
        onBackButtonClick={onBackButtonClick}
        hideBackButton={!!encodedDataFromMobile}
        content={
          <Formik
            initialValues={{code: ''}}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              validationSchema
                .validate(values)
                .then(async () => {
                  setResending(true);

                  const result = await viewModel.handleNextButtonPressed({
                    challengeId,
                    token: values.code,
                  });

                  if (result?.error?.title) {
                    setError(result.error);
                    setShowErrorModal(true);
                    setResending(false);
                  } else if (result?.error) {
                    setResending(false);
                    actions.setFieldError('code', result.error?.message);
                  }
                })
                .catch((e) => {
                  actions.setFieldError('code', e?.errors[0]);
                  actions.setSubmitting(false);
                  setResending(false);
                });
            }}
          >
            {({handleSubmit, values, dirty, isValid, isSubmitting, setFieldValue, handleBlur, touched, errors}) => (
              <>
                <form onSubmit={handleSubmit}>
                  <VerificationWrapper>
                    <NewVerificationCodeInput
                      length={6}
                      size="sm"
                      value={values.code}
                      disabled={isSubmitting || resending}
                      onChange={(value) => setFieldValue('code', value)}
                      handleResend={handleResend}
                      renderError={
                        touched.code &&
                        Boolean(errors.code) && (
                          <Typography color={muiTheme.colors.errorRed} variant="body2">
                            {errors.code}
                          </Typography>
                        )
                      }
                    />
                  </VerificationWrapper>
                  <NewNextStepButton
                    label={'Next'}
                    onClick={onNextClick}
                    disabled={isSubmitting}
                    loading={isSubmitting || resending}
                    loadingLabel={`${resending ? 'Resending' : 'Verifying'} code...`}
                  />
                </form>
              </>
            )}
          </Formik>
        }
      />
      {showErrorModal ? (
        <AlertModal
          id="close-modal"
          width="sm"
          title={error.title}
          titleFontSize="21px"
          subtitle={error.subTitle}
          isAlertModalVisible={showErrorModal}
          closeAlertModal={() => setShowErrorModal(false)}
          alertModalButtons={[
            {
              type: 'primary',
              buttonLabel: 'Ok',
              onClickHandler: () => setShowErrorModal(false),
              id: 'close-modal',
            },
          ]}
        />
      ) : null}
    </>
  );
};
