import React, {CSSProperties, useState, useCallback} from 'react';
import Select, {createFilter} from 'react-select';
import AnalyticsManager, {PAGE_VIEWS} from 'src/analytics/AnalyticsManager';
import {ValueType} from 'react-select/src/types';
import overriddenComponents, {StyledSelect} from 'src/pages/MessengerPage/auto-select/ReactSelectMaterialUI';
import {User, UserSelectOptionType} from 'src/types';
import {
  NoCircleOptionV2 as Option,
  AutoHeightMenuListV2 as MenuList,
} from 'src/pages/MessengerPage/auto-select/ReactSelectSharedComponents';
import {UserViewModel} from 'src/pages/ContactsPage/viewModels/UserViewModel';
import debounce from 'lodash/debounce';
import getParsedAuthInfo from 'src/utils/localStorageHandler';
import {PaginatedUsersType} from 'src/types/PaginatedTypes';
import {muiTheme} from 'src/styles/theme';

const getOptionLabel = (option) => `${option?.data?.label}`;

const ChangeStatusModalUserDropdownSTA = ({workStatusProxyUser, onClickColleague}) => {
  const [options, setOptions] = useState<PaginatedUsersType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [nextCursor, setNextCursor] = useState<string | null>(null);

  const parsedAuthInfo = getParsedAuthInfo();
  const selfID = parsedAuthInfo && parsedAuthInfo.user.id;

  const {getSearchPaginatedUsersInScope} = UserViewModel();

  React.useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.selectContactsList);
  }, []);

  let proxyUserData;
  if (workStatusProxyUser && workStatusProxyUser.value) {
    proxyUserData = {
      data: {
        value: workStatusProxyUser?.value,
        label: workStatusProxyUser?.label,
      },
    };
  }

  const [selectedColleague, setSelectedColleague] = React.useState<ValueType<UserSelectOptionType>>([proxyUserData]);

  function handleChangeSingle(value: ValueType<UserSelectOptionType>) {
    onClickColleague(value);
    setSelectedColleague(value);
  }

  const loadOptions = useCallback(
    async (searchTextLocal?: string, cursor?: string) => {
      const cursorValue = cursor || nextCursor;
      const searchValue = typeof searchTextLocal !== 'number' ? searchTextLocal : searchText;

      if (isLoading || !hasMore || !cursorValue) {
        return;
      }

      setIsLoading(true);

      try {
        const response = await getSearchPaginatedUsersInScope({
          text: searchValue,
          memberStatus: ['active'],
          pageInfo: {
            cursor: cursorValue,
            direction: 'next',
            pageSize: 30,
          },
        });

        if ('error' in response) {
          setOptions([]);
        } else {
          const newOptions = response.users;
          if (response?.users?.length > 0) {
            const uniqueNewOptions = newOptions.filter((newOption) => {
              return ![...options].some((existingOption) => existingOption.id === newOption.id);
            });
            setOptions([...options, ...uniqueNewOptions]);
            setNextCursor(response.pageInfo.nextCursor);
          } else {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error('Error loading options:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, nextCursor, searchText],
  );

  const getSearchData = async (searchValue: string) => {
    const searchResult = await getSearchPaginatedUsersInScope({
      text: searchValue,
      memberStatus: ['active'],
      pageInfo: {
        cursor: null,
        direction: 'next',
        pageSize: 30,
      },
    });

    if ('error' in searchResult) {
      setOptions([]);
    } else {
      setOptions(searchResult.users);
      setHasMore(searchResult.pageInfo.hasNextPage);
      setNextCursor(searchResult.pageInfo.nextCursor);
    }

    setIsLoading(false);
  };

  const handleCombinedSearch = React.useCallback(
    debounce(async (searchValue: string) => {
      await getSearchData(searchValue);
    }, 250),
    [],
  );

  const handleSearch = async (searchValue: string) => {
    setSearchText(searchValue);

    if (searchValue === '') {
      setOptions([]);
      return;
    }

    if (searchValue.length <= 1) {
      return;
    }

    setIsLoading(true);

    handleCombinedSearch(searchValue);
  };

  const handleInputChange = (inputValue) => {
    if (!inputValue) {
      setOptions([]);
      setNextCursor(null);
    }
    setSearchText(inputValue);
    if (inputValue.length > 1) {
      handleSearch(inputValue);
    }
  };

  const suggestions = options
    ? Array.from(options)
        .filter((colleague) => colleague.id !== selfID)
        .map((colleague) => {
          return {
            value: colleague?.id,
            label: colleague?.firstname + ' ' + colleague?.lastname,
            role: colleague?.role,
            firstName: colleague?.firstname,
            lastName: colleague?.lastname,
            profilePic: colleague?.profilePic,
            username: colleague?.username,
          } as UserSelectOptionType;
        })
    : [];

  const handleMenuScrollToBottom = () => {
    if (hasMore) {
      loadOptions(searchText, nextCursor!);
    }
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: muiTheme.colors.primaryText,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}),
  };

  return (
    <div style={{flexGrow: 1}} onClick={(e) => e.stopPropagation()}>
      <StyledSelect
        styles={selectStyles}
        inputId="react-select-single"
        TextFieldProps={{
          InputLabelProps: {
            htmlFor: 'react-select-single',
            shrink: true,
          },
        }}
        placeholder={'Select alternate contact'}
        options={suggestions.map((s) => ({data: s}))}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        components={{
          ...overriddenComponents,
          Option,
          MenuList,
        }}
        isLoading={isLoading}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onInputChange={handleInputChange}
        isClearable={true}
        getOptionLabel={getOptionLabel}
        value={selectedColleague}
        onChange={handleChangeSingle}
        filterOption={null}
      />
    </div>
  );
};

export default ChangeStatusModalUserDropdownSTA;
