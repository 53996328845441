import ApiHelper from 'src/api';
import {AuthHelper} from 'src/auth';

class SignupPersonalDetailsViewModel {
  repository: any;
  nextButtonPressed;
  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    console.log('values', values);
    const domain = values.email.split('@')[1];
    try {
      if (values?.activateShellAccountFlow && values?.organizationUrl) {
        const getOrgByURL = await ApiHelper.PublicEndpoints.fetchOrganizationByURL(values.organizationUrl);
        this.nextButtonPressed(values, getOrgByURL?.data?.findOrganizationByURL);
      } else {
        const getOrgByURL = values.organizationUrl
          ? await ApiHelper.PublicEndpoints.fetchOrganizationByURL(values.organizationUrl)
          : null;
        const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({domain});
        if ('error' in getOrgByDomain) {
          console.error('Error occurred when fetching organization by domain');
          return null;
        }
        if (!getOrgByDomain?.data?.organizationForDomain && getOrgByURL?.data?.findOrganizationByURL) {
          this.nextButtonPressed(values, getOrgByURL.data.findOrganizationByURL);
        } else if (getOrgByDomain?.data?.organizationForDomain?.name) {
          const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(values.challengeId);
          if (partialAccessTokenResponse.data) {
            const getAccountsForEmail = await ApiHelper.PrivateEndpoints.getAccounts({
              partialAccessToken: partialAccessTokenResponse.data.access_token,
            });
            const match =
              'data' in getAccountsForEmail &&
              getAccountsForEmail.data.accounts.accounts.some(
                (account) => account.organization.id === getOrgByDomain.data.organizationForDomain.id,
              );
            if (match) {
              this.nextButtonPressed(values, {});
            } else {
              const newValues = {
                ...values,
                organizationUrl: getOrgByDomain.data.organizationForDomain.url,
                currentStep: 6,
                orgName: getOrgByDomain.data.organizationForDomain.name,
                orgId: getOrgByDomain.data.organizationForDomain.id,
              };
              this.nextButtonPressed(newValues, getOrgByURL?.data?.findOrganizationByURL);
            }
          }
        } else {
          this.nextButtonPressed(values, {});
        }
      }
    } catch (error: any) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default SignupPersonalDetailsViewModel;
