import gql from 'graphql-tag';
import {Address} from '../../../types/sta';

export type RemoveAllUserSessionsMutationResponse = {
  data: {
    selfMutation: {
      removeAllSessions: {
        id: string;
      };
    };
  };
};

export const REMOVE_ALL_USER_SESSIONS_MUTATION = gql`
  mutation removeAllUserSessionsMutation {
    selfMutation {
      removeAllSessions {
        ... on SessionNotFound {
          __typename
          message
        }
        ... on SessionMutation {
          removeAllSessionsForUser {
            ... on FullOrganizationMember {
              id
            }
          }
        }
      }
    }
  }
`;
