import {useEffect, useState} from 'react';
import ApiHelper from 'src/api';
import {ENCODED_DATA_FROM_MOBILE_CLIENTS, HYPERCARE_REGION, REDIRECT_URI} from '../constants/storageKeys';
import {getParsedAuthRegion} from '../utils/localStorageHandler';
import {
  CANADA_ABBREVIATION,
  EUROPE_ABBREVIATION,
  UNITED_STATES_ABBREVIATION,
  UNITED_STATES_ABBREVIATION_2,
} from '../constants/strings';
import {localStorageService} from '../services/localStorageService';
import {OrganizationAccountsCacheDataFromMobile} from '../types/sta';

export const useSetUserGeographicalLocation = () => {
  const [region, setRegion] = useState('');
  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );

  const decodedQueryParams = decodeURIComponent(window.location.search);
  const queryParams = new URLSearchParams(decodedQueryParams);

  const redirectUriFromQueryParam = queryParams.get(REDIRECT_URI);

  useEffect(() => {
    const getData = async () => {
      if (redirectUriFromQueryParam) {
        setRegion('client');
        return;
      }

      const current = getParsedAuthRegion();
      const result = await ApiHelper.PublicEndpoints.fetchUserGeographicalLocation();

      if (current) {
        setRegion(current);
      }

      if (!window.localStorage.getItem(HYPERCARE_REGION) && result?.data?.country_code) {
        const {country_code} = result.data;
        let newRegion;

        switch (country_code) {
          case CANADA_ABBREVIATION:
            newRegion = CANADA_ABBREVIATION;
            break;
          case UNITED_STATES_ABBREVIATION_2:
            newRegion = UNITED_STATES_ABBREVIATION;
            break;
          case EUROPE_ABBREVIATION:
            newRegion = EUROPE_ABBREVIATION;
            break;
          default:
            newRegion = CANADA_ABBREVIATION;
        }

        setRegion(newRegion);
        window.localStorage.setItem(HYPERCARE_REGION, newRegion);
      }

      if (!result || result.error) {
        setRegion(CANADA_ABBREVIATION);
      }
    };

    getData();
  }, []);

  return {region};
};
