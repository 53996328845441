import React, {useCallback, useState} from 'react';
import {getAllLoggedInAccounts, updateCacheData} from '../utils/sta/staUtils';
import {UserRepository} from '../data/repository/UserRepository';
import {localStorageService} from '../services/localStorageService';
import {ORGANIZATION_ACCOUNTS_DATA} from '../constants/storageKeys';
import {OrganizationAccountsCacheData} from '../types/sta';

export const useUnreadChatCount = () => {
  const [unreadCount, setUnreadCount] = useState<Record<string, number>>({});
  const {fetchUnreadChatCountForUser} = UserRepository();
  const loggedInAccounts = getAllLoggedInAccounts();

  const fetchUnreadCount = useCallback(async () => {
    if (loggedInAccounts.length === 0) return;

    try {
      const promises = loggedInAccounts.map((acc) => fetchUnreadChatCountForUser(acc.accessToken || ''));
      const responses = await Promise.all(promises);

      const unreadCountData = responses.reduce((acc, response) => {
        if ('error' in response) return acc;

        const {id} = response.data.me;
        const {count} = response.data.me.unreadChatCount?.[0] || 0;
        acc[id] = count;
        return acc;
      }, {} as Record<string, number>);

      updateCacheWithUnreadChatCount(unreadCountData);

      setUnreadCount(unreadCountData);
    } catch (error) {
      console.error('Error fetching unread chat counts', error);
    }
  }, [fetchUnreadChatCountForUser]);

  const updateCacheWithUnreadChatCount = (unreadCountData: Record<string, number>) => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    if (!currentCacheData) {
      return;
    }

    const updatedOrganizations = currentCacheData.savedOrganizations.map((org) => ({
      ...org,
      unreadChatCount: unreadCountData?.[org.user.id] || 0,
    }));

    updateCacheData({
      ...currentCacheData,
      savedOrganizations: updatedOrganizations,
    });
  };

  return {unreadCount, fetchUnreadCount};
};
