import React from 'react';
import {useDispatch} from 'react-redux';
import {HYPERCARE_REGION} from 'src/constants/storageKeys';
import {AuthRegion, typedUseSelector} from 'src/types';
import {authRegionData} from './AuthRegionData';
import {actions} from 'src/redux/actions/authRegion';
import {LogoutViewModel} from '../../pages/LoginPage/sta/view-models/LogoutViewModel';

export const ChangeRegionViewModel = () => {
  const dispatch = useDispatch();
  const {logoutFromAllAccounts} = LogoutViewModel();
  const AuthRegionData = authRegionData;

  const currentAuthRegion = typedUseSelector((state) => state.hypercareRegion.currentAuthRegion);

  const submitRegionChange = async (selectedRegion: AuthRegion) => {
    try {
      await logoutFromAllAccounts();
      dispatch(actions.setHypercareRegion(selectedRegion));
      window.localStorage.setItem(HYPERCARE_REGION, selectedRegion);
      return {success: true};
    } catch (err) {
      return {success: false, error: err};
    }
  };

  return {currentAuthRegion, AuthRegionData, submitRegionChange};
};
