import React from 'react';

export default () => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M61.7001 31.398L47.776 7.564C47.5881 7.24044 47.3185 6.97188 46.9942 6.78516C46.6699 6.59845 46.3023 6.50012 45.9281 6.5H18.072C17.6982 6.50047 17.331 6.59896 17.0071 6.78566C16.6831 6.97236 16.4139 7.24073 16.226 7.564L2.30005 31.4C2.10483 31.7344 2.00195 32.1147 2.00195 32.502C2.00195 32.8893 2.10483 33.2696 2.30005 33.604L16.228 57.438C16.4159 57.7613 16.6851 58.0296 17.0091 58.2163C17.333 58.403 17.7002 58.5015 18.074 58.502H45.93C46.3039 58.5015 46.6711 58.403 46.995 58.2163C47.319 58.0296 47.5882 57.7613 47.776 57.438L61.7001 33.6C61.8953 33.2656 61.9981 32.8853 61.9981 32.498C61.9981 32.1107 61.8953 31.7324 61.7001 31.398ZM29.752 16.5H34.252V38.5H29.752V16.5ZM32 50.5C31.4067 50.5 30.8267 50.3241 30.3333 49.9944C29.84 49.6648 29.4555 49.1962 29.2284 48.648C29.0013 48.0999 28.9419 47.4967 29.0577 46.9147C29.1734 46.3328 29.4592 45.7982 29.8787 45.3787C30.2983 44.9591 30.8328 44.6734 31.4148 44.5576C31.9967 44.4419 32.5999 44.5013 33.1481 44.7284C33.6963 44.9554 34.1648 45.3399 34.4945 45.8333C34.8241 46.3266 35 46.9067 35 47.5C35 48.2957 34.684 49.0587 34.1214 49.6213C33.5588 50.1839 32.7957 50.5 32 50.5Z"
      fill="#767676"
    />
  </svg>
);
