import React from 'react';
import AlertModal from '../../../MessengerPage/messenger/messages-layout/message-template/AlertModal';

export const LogoutOrContinueModal = (props: {
  open: boolean;
  onRequestClose: () => void;
  onHandleContinue: () => void;
  onHandleLogoutAndRemove: () => void;
}) => {
  return (
    <AlertModal
      width="sm"
      title={'Using a shared device?'}
      titleFontSize="21px"
      subtitle={'We recommend removing all accounts if this is a shared device.'}
      closeAlertModal={() => props.onRequestClose()}
      isAlertModalVisible={props.open}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: 'No, continue adding',
          onClickHandler: () => props.onHandleContinue(),
          id: 'cancel-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Yes, remove & continue',
          onClickHandler: () => props.onHandleLogoutAndRemove(),
          id: 'remove-btn',
        },
      ]}
    />
  );
};
