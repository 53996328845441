import React from 'react';
import {isEmpty} from 'ramda';
import styled from '@emotion/styled';

import StatDoubleExclamation from 'src/svgs/StatDoubleExclamation';
import UrgentSingleExclamation from 'src/svgs/UrgentSingleExclamation';
import {StatMessagePriority, UrgentMessagePriority} from 'src/constants/messageTypes';
import {MessageTemplate, PriorityType, PriorityTypeValue} from 'src/types';
import {IsFeatureFlagEnabled} from '../../../../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../../../../utils/FeatureFlags';
import {useFlags} from 'launchdarkly-react-client-sdk';

const UrgentMessageHeader = styled.div<{isImageMessage: boolean}>`
  font-weight: 600;
  color: red;
  font-size: 14px;
  ${(props) =>
    props.isImageMessage &&
    `
    margin: 5px 0 5px 0;
  `}
`;

const StatMessageHeader = styled.div<{isImageMessage: boolean}>`
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  ${(props) =>
    props.isImageMessage &&
    `
    margin: 5px 0 5px 0;
  `}
`;

const ConsultMessageHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  & > div {
    margin-top: -5px;
    align-self: center;
    margin-left: 5px;
    color: white;
  }
`;

const PriorityIndicator = ({
  priorityType,
  isImageMessage = false,
  isConsultMessage = false,
  isTemplateMessage = false,
  template,
}: {
  priorityType: string;
  isImageMessage?: boolean;
  isConsultMessage?: boolean;
  isTemplateMessage?: boolean;
  template?: MessageTemplate;
}) => {
  const {urgentMessageLabel, statMessageLabel} = useFlags();

  const renderUrgentMessageLabel = (): JSX.Element | null => {
    if (priorityType === PriorityTypeValue.urgent) {
      const hasLabel = urgentMessageLabel !== '--';
      const hasContent = hasLabel || isConsultMessage || isTemplateMessage;

      if (!hasContent) {
        return <UrgentMessageHeader isImageMessage={isImageMessage}></UrgentMessageHeader>;
      }

      return (
        <UrgentMessageHeader isImageMessage={isImageMessage}>
          {hasLabel && <>{urgentMessageLabel} </>}
          {isConsultMessage && <>CONSULT</>}
          {isTemplateMessage && <>{templateRequestName}</>}
        </UrgentMessageHeader>
      );
    }
    return null;
  };

  const renderStatMessageLabel = (): JSX.Element | null => {
    if (priorityType === PriorityTypeValue.stat) {
      const hasLabel = statMessageLabel !== '--';
      const hasContent = hasLabel || isConsultMessage || isTemplateMessage;

      if (!hasContent) {
        return <StatMessageHeader isImageMessage={isImageMessage}></StatMessageHeader>;
      }

      return (
        <StatMessageHeader isImageMessage={isImageMessage}>
          {hasLabel && <>{statMessageLabel} </>}
          {isConsultMessage && <>CONSULT</>}
          {isTemplateMessage && <>{templateRequestName}</>}
        </StatMessageHeader>
      );
    }
    return null;
  };

  const templateRequestName = `${template?.formTitle} ${
    !isEmpty(template?.requestTitle) ? `- ${template?.requestTitle}` : ''
  }`;

  if (priorityType === PriorityTypeValue.stat) {
    return renderStatMessageLabel();
  } else if (priorityType === PriorityTypeValue.urgent) {
    return renderUrgentMessageLabel();
  } else if (isConsultMessage)
    return (
      <ConsultMessageHeader>
        <img src="/assets/consult-notactive.svg" alt="consult message icon" />
        <div>CONSULT</div>
      </ConsultMessageHeader>
    );
  else if (isTemplateMessage)
    return (
      <ConsultMessageHeader>
        <img src="/assets/consult-notactive.svg" alt="template message icon" />
        <div>{templateRequestName}</div>
      </ConsultMessageHeader>
    );
  else return null;
};

export default PriorityIndicator;
