import React, {useEffect} from 'react';
import {AuthContext} from 'src/auth';
import FirebaseNotificationController from 'src/notifications/FirebaseNotificationManager';
import {isFirebaseRemoteConfigSupported} from 'src/firebase/firebaseSetup';
import getCurrentOrganizationId from 'src/utils/organizationHelper/getCurrentOrganizationId';
import client from 'src/apollo';
import GetSelfOrganizations from 'src/gql/query/GetUserOrganizations';
import store from 'src/redux';
import {actions} from 'src/redux/actions/organization';
import getCachedOrganizationById from 'src/utils/organizationHelper/getCachedOrganizationById';
import {updateScopeToken} from 'src/utils/localStorageHandler';
import {CreateBroadcastChannel} from './CreateBroadcastChannel';
import {getCurrentLoggedInAccount, getOrganizationFromCache} from 'src/utils/sta/staUtils';
import {OrganizationAccountsCacheData} from 'src/types/sta';
import {localStorageService} from 'src/services/localStorageService';
import {ORGANIZATION_ACCOUNTS_DATA} from 'src/constants/storageKeys';
import {toast} from 'react-toastify';

const FCMHandler = ({isLoggedIn, STALogin}) => {
  if ('BroadcastChannel' in window) {
    CreateBroadcastChannel();
  } else {
    console.warn('BroadcastChannel not supported in this environment.');
  }
  useEffect(() => {
    const handleNotificationRegistration = async () => {
      const isFirebaseSupported = await isFirebaseRemoteConfigSupported();

      if (isLoggedIn && isFirebaseSupported) {
        FirebaseNotificationController.requestNotificationPermission();
        FirebaseNotificationController.recieveForegroundMessages();
      }
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    }
    handleNotificationRegistration();
    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
      }
    };
  }, [isLoggedIn]);

  async function handleServiceWorkerMessage(event: MessageEvent) {
    let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem(ORGANIZATION_ACCOUNTS_DATA) ?? {
      savedOrganizations: [],
      selectedAccountUserId: '',
    };
    const currentLoggedInUser = getCurrentLoggedInAccount();
    if (event.data.payload && getOrganizationFromCache(event.data.payload?.accountId)?.accessToken) {
      const {chatId, accountId} = event.data.payload;
      if (currentLoggedInUser?.user.id !== accountId) {
        const notificationAccount = getOrganizationFromCache(accountId);
        const notificationOrganization = notificationAccount?.organization;
        const notificationEmail = notificationAccount?.user.email;
        await STALogin(notificationOrganization, notificationAccount, notificationEmail);
        window.location.href = `/messenger/${chatId}`;
      } else {
        window.routerHistory.push(`/messenger/${chatId}`);
      }
    } else {
      toast.error('The account is signed out, please log in again.');
    }
  }

  return (
    <React.Fragment>
      <audio id="priority-notification-player" autoPlay muted>
        <source type="audio/wav" id="priority-notification-src" src="/sounds/priority-notification.wav" />
      </audio>
      <audio id="regular-notification-player" autoPlay muted>
        <source type="audio/wav" id="regular-notification-src" src="/sounds/regular-notification.mp3" />
      </audio>
      <audio id="urgent-notification-player" autoPlay muted>
        <source type="audio/wav" id="urgent-notification-src" src="/sounds/priority-urgent.mp3" />
      </audio>
    </React.Fragment>
  );
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, STALogin}) => <FCMHandler STALogin={STALogin} isLoggedIn={isLoggedIn} />}
  </AuthContext.Consumer>
);
