import React from 'react';
import {HCHeadingFive, HCHeadingFour, HCHeadingThree, HCLabelOne} from '../../../../components/HypercareComponents';
import {muiTheme} from '../../../../styles/theme';
import {PrimaryButton, SecondaryButton} from '../../../../styles/styled-components/StyledMaterialComponents';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import {StyledModal} from '../../../../styles/styled-components/ModalStyleComponents';
import ReactModal from 'react-modal';

const ChangeRegionModalDivider = styled(Divider)`
  margin-top: 16px !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
`;

const ChangeRegionButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  gap: 8px;
`;

const ChangeRegionModalBodyTextContainer = styled.div`
  margin: 12px 0 14px 0;
`;

export const LogoutAndContinueModal = styled(StyledModal)`
  z-index: 99;
  overflow-y: unset !important;
  border-radius: 6px;
  .ReactModal__Content {
    width: 620px;
    min-width: 500px;
    max-width: 650px;
    overflow: hidden !important;
  }
` as React.ComponentType<ReactModal.Props>;

const StyledButton = styled(SecondaryButton)`
  border: none;
  :hover {
    border: none;
    background-color: #f6f6f9;
  }
`;
export const StatusSuggestionModalSta = ({
  open,
  onRequestClose,
  onHandleUpdateStatus,
  onHandleLogout,
  onHandleSwitchAccount,
  description,
}: {
  open: boolean;
  onRequestClose: () => void;
  onHandleUpdateStatus: () => void;
  onHandleLogout: () => void;
  onHandleSwitchAccount: () => void;
  description: any;
}) => {
  return (
    <LogoutAndContinueModal
      isOpen={open}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
    >
      <HCHeadingThree id="alert-dialog-title">Your status is set to Unavailable</HCHeadingThree>

      <ChangeRegionModalBodyTextContainer>
        <HCLabelOne fontSize={'14px'} fontWeight={400}>
          {description}
        </HCLabelOne>
      </ChangeRegionModalBodyTextContainer>

      <ChangeRegionModalDivider />

      <ChangeRegionButtonContainer>
        <div>
          <StyledButton onClick={onHandleLogout}>
            <HCHeadingFive color={muiTheme.colors.black}>Log out</HCHeadingFive>
          </StyledButton>
        </div>
        <div style={{display: 'flex', gap: '8px'}}>
          <SecondaryButton backgroundColor={muiTheme.colors.errorRed} onClick={onHandleSwitchAccount}>
            <HCHeadingFive color={muiTheme.colors.greyishBrown}>Switch account </HCHeadingFive>
          </SecondaryButton>
          <PrimaryButton backgroundColor={muiTheme.colors.errorRed} onClick={onHandleUpdateStatus}>
            <HCHeadingFive color={muiTheme.colors.white}>Update status</HCHeadingFive>
          </PrimaryButton>
        </div>
      </ChangeRegionButtonContainer>
    </LogoutAndContinueModal>
  );
};
